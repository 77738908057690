import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { API_URL } from "../../../config";
import { useDispatch } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
import { logoutonexpire } from "../../../store/actions/AuthActions";
function ImportStudent() {
  const base_url = `${API_URL}/`;
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [course, setCourse] = useState([]);
  const courses = useSelector((state) => state.courses.courses);
  const [courseId, setCourseId] = useState(null);
  const [masterCourseId, setMasterCourseId] = useState(null);
  const [specId, setSpecId] = useState(null);
  const [passoutYearForm, setPassoutYearForm] = useState("");
  const [students, setStudents] = useState([]);
  const [specializationData, setSpecializationData] = useState([]);
  const currentYear = new Date().getFullYear();
  const [courseName, setCourseName] = useState(null);
  const [specializationName, setSpecializationName] = useState(null);
  const [responseForm, setResponseForm] = useState("");
  const [File, setFile] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
  }, [dispatch]);

  // Fetching response data
  const [responseCourse, setResponseCourse] = useState(null);
  const [responseSpecialization, setResponseSpecialization] = useState(null);
  const [responsePassoutYear, setResponsePassoutYear] = useState(null);
  const [responseFile, setResponseFile] = useState(null);
  useEffect(() => {
    // console.log("courseId", courseId);

    if (courseId != null) {
      const payload = { college_id: collegeId, course_id: courseId };
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          console.log("err======", err);
        });
    }
  }, [courseId]);

  useEffect(() => {
    // console.log(masterCourseId);
    if (masterCourseId !== null && masterCourseId !== "Select Course") {
      const coursemaster = courses?.find(
        (courses) => courses?.course_master_id == masterCourseId
      );
      setCourseId(coursemaster.college_course_id);
      setCourseName(coursemaster ? coursemaster.name : "");
    }
  }, [masterCourseId, course]);
  useEffect(() => {
    if (specId != null) {
      const specialization = specializationData?.find(
        (specialization) => specialization.specilization_master_id == specId
      );
      setSpecializationName(specialization?.name);
    }
  }, [specId]);

  useEffect(() => {
    const payload = { college_id: collegeId };

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;
    axios
      .get(`${API_URL}/api/freshment_portal_apis/GenericAPI/get_courses_list`, {
        params: payload,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        setCourse(response.data.data);
        // console.log(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      });
  }, []);

  // useEffect(() => {
  //   console.log(File);
  // }, [File]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      Papa.parse(file, {
        header: true, // This assumes the first row is a header row
        complete: (result) => {
          setStudents(result.data); // Save parsed data to students array
        },
        skipEmptyLines: true,
      });
    }
  };

  const validateCSV = (data) => {
    if (!data || data.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please Select A File",
        confirmButtonText: "OK",
      });
      return false; // No data
    }

    const requiredColumns = ["full_name", "email", "phone_number"]; // Example required columns
    const headers = Object.keys(data[0]);

    // Check if required columns are present
    for (let column of requiredColumns) {
      if (!headers.includes(column)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          html: `Missing required column: ${column} ${"<br>"} You Can Download A Sample Of CSV File from The Link.`,
          confirmButtonText: "OK",
        });
        // alert(`Missing required column: ${column}`);
        return false;
      }
    }

    // Additional row validation (e.g., check for empty fields)
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      if (!row.full_name || !row.email || !row.phone_number) {
        Swal.fire({
          icon: "error",
          title: "Error",
          html: `Row ${
            i + 1
          } is missing required fields.${"<br>"} You Can Download A Sample Of CSV File from The Link.`,
          confirmButtonText: "OK",
        });
        return false;
      }
    }

    return true;
  };

  // Form submit handler
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const payload = {
      college_id: collegeId,
      passout_year: passoutYearForm,
      course_id: masterCourseId,
      specialization_id: specId,
      students: students,
    };

    if (
      payload.passout_year === "" ||
      payload.passout_year === "Select Year" ||
      payload.specialization_id === "Select Specialization" ||
      payload.course_id === null ||
      payload.course_id === "Select Course" ||
      payload.specialization_id === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please Select All The Fields",
        confirmButtonText: "OK",
      });
      // setStudents([]);
      return;
    }

    const isValid = validateCSV(students);
    if (!isValid) {
      return;
    }

    setResponseCourse(courseName);
    setResponseSpecialization(specializationName);
    setResponsePassoutYear(passoutYearForm);
    setResponseFile(File?.name);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/InviteStudents/add_invite_students`,
        JSON.stringify(payload),
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // console.log("response", response.data);
        if (response.data.total_failed || response.data.total_imported) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Imported Successfully",
            confirmButtonText: "OK",
          }).then(() => {
            setResponseForm(response.data);
            setMasterCourseId("Select Course");
            setSpecId("Select Specialization");
            setPassoutYearForm("Select Year");
            setFile(null);
            setStudents([]);
            payload.students = [];
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            confirmButtonText: "OK",
          });
          setResponseForm("");
          setMasterCourseId("Select Course");
          setSpecId("Select Specialization");
          setPassoutYearForm("Select Year");
          setFile(null);
          setStudents([]);
          payload.students = [];
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title fs-20">Import Students</h4>
        </div>
        <div className="card-body">
          <Form>
            <Form.Group>
              <Form.Label>Select Course</Form.Label>
              <Form.Control
                as="select"
                value={masterCourseId}
                onChange={(e) => {
                  if (e.target.value === "Select Course") {
                    setSpecId(null);
                    setMasterCourseId(null);
                    setSpecializationData([]);
                  } else {
                    setMasterCourseId(e.target.value);
                  }
                }}
                id="courseSelect"
              >
                <option value="Select Course">Select Course</option>
                {course?.map((courses) => (
                  <option
                    key={courses.course_master_id}
                    value={courses.course_master_id}
                  >
                    {courses.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Specialization</Form.Label>
              <Form.Control
                onChange={(e) => {
                  if (e.target.value === "Select Specialization") {
                    setSpecId(0);
                  } else if (e.target.value === "All") {
                    setSpecId(0);
                    setSpecializationName("All");
                  } else {
                    setSpecId(e.target.value);
                  }
                }}
                value={specId}
                as="select"
                id="branchSelect"
              >
                <option value="Select Specialization">
                  Select Specialization
                </option>
                {specializationData && specializationData.length > 0 ? (
                  specializationData?.map((specialize) => (
                    <option
                      key={specialize.specilization_master_id}
                      value={specialize.specilization_master_id}
                    >
                      {specialize.name}
                    </option>
                  ))
                ) : (
                  <option key={0} value={0}>
                    All
                  </option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Passout Year</Form.Label>
              <Form.Control
                onChange={(e) => setPassoutYearForm(e.target.value)}
                value={passoutYearForm}
                as="select"
                id="passoutYearSelect"
              >
                <option key={0} value={"Select Year"}>
                  Select Year
                </option>
                {/* <option value={currentYear - 1}>{currentYear - 1}</option> */}
                <option key={1} value={currentYear}>
                  {currentYear}
                </option>
                <option key={2} value={currentYear + 1}>
                  {currentYear + 1}
                </option>
                <option key={3} value={currentYear + 2}>
                  {currentYear + 2}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label htmlFor="file-upload" className="form-label">
                Select CSV file ({" "}
                <a
                  className="text-primary mt-3"
                  href={`${base_url}uploads/upload_student_sample_format.csv`}
                  download
                >
                  Download Sample CSV Format
                </a>{" "}
                ) :
              </Form.Label>
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                className="form-file-input d-none"
              />
              <p>
                <label htmlFor="file-upload" className="btn btn-primary mt-2">
                  Upload File
                </label>
                {File && <span className="ms-2">File Name : {File?.name}</span>}
              </p>
            </Form.Group>
          </Form>
        </div>
        <div className="card-footer">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={handleSubmitForm}
          >
            Import
          </button>
        </div>
      </div>
      {responseForm && responseForm != "" ? (
        <div
          className="card shadow-sm p-1 rounded-lg"
          style={{
            // backgroundColor: "#e4d8c1",
            borderBottom: "4px solid #4CAF50",
          }}
        >
          <div className="card-header text-white ">
            <h4 className="card-title fs-20 font-bold">
              Import successful from {responseFile} for {responseCourse} -{" "}
              {responseSpecialization} - {responsePassoutYear}
            </h4>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="text-center me-4">
                <p
                  className="font-weight-bold d-flex m-0 align-items-center"
                  style={{ fontSize: "16px", gap: 5 }}
                >
                  Total Imported:
                  <span
                    className="text-success font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    {responseForm.total_imported}{" "}
                    <i className="fas fa-check-circle"></i>
                  </span>
                </p>
              </div>
              <div className="text-center">
                <p
                  className="font-weight-bold m-0 d-flex align-items-center"
                  style={{ fontSize: "16px", gap: 5 }}
                >
                  Total Failed Records:
                  <span
                    className="text-danger font-weight-bold"
                    style={{ fontSize: "20px" }}
                  >
                    {responseForm.total_failed}{" "}
                    <i className="fas fa-times-circle"></i>
                  </span>
                </p>
              </div>
            </div>

            {responseForm.failed_records?.length > 0 && (
              <div>
                <h5 className="mt-3 mb-3 font-weight-bold text-secondary">
                  Failed Records :
                </h5>
                <table className="table table-bordered text-start">
                  <thead className="bg-white">
                    <tr>
                      <th className="p-1 fs-16">Name</th>
                      <th className="p-1 fs-16">Email</th>
                      <th className="p-1 fs-16">Phone Number</th>
                      <th className="p-1 fs-16">Error Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseForm.failed_records?.map((record, index) => (
                      <tr key={index}>
                        <td className="p-2">{record.full_name}</td>
                        <td className="p-2">{record.email}</td>
                        <td className="p-2">{record.phone_number}</td>
                        <td className="p-2 text-danger text-start">
                          {record.error_message}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ImportStudent;
