import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../table/FilteringTable/filtering.css";
import PageTitle from "../../layouts/PageTitle";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
import { fetchCourses } from "../../../store/actions/CourseAction";
import { logout } from "../../../store/actions/AuthActions";
// import { useSelector } from "react-redux";
const JobApplication = () => {
  const courses = useSelector((state) => state.courses.courses); // Access the courses data
  const collegeId = useSelector((state) => state.college_id.college_id);
  // console.log(courses);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const payload = { college_id: 1 }; // Add any necessary payload parameters
  //   dispatch(fetchCourses(payload));
  // }, [dispatch]);
  const [studentList, setStudentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [studentsPerPage] = useState(10); // Number of students to display per page
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [passoutYear, setPassoutYear] = useState("Passout Year");
  // const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const payload = { college_id: 1, offset: 0 };

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [masterCourseId, setMasterCourseId] = useState(null);
  const [specId, setSpecId] = useState(null);
  const [passoutYearForm, setPassoutYearForm] = useState("");
  const [specializationData, setSpecializationData] = useState([]);
  const [interestedPlacement, setInterestedPlacement] = useState(1);
  const [interestedHigherStudy, setInterestedHigherStudy] = useState(1);
  const [studentId, setStudentId] = useState(null);
  const currentYear = new Date().getFullYear();
  const [courseList, setCourseList] = useState("Choose Course");
  const [courseIdList, setCourseIdList] = useState(null);
  const [specialization, setSpecialization] = useState("Choose Specialization");
  const [specializationId, setSpecializationId] = useState(null);
  const [coursefilterId, setCourseIdfilter] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const payload = { college_id: collegeId };

  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
  }, [dispatch]);

  const fetchStudentList = async () => {
    setLoading(true); // Set loading to true before fetching data

    const params = {
      ...payload,
      // search: search,
      offset: currentPage * studentsPerPage,
      limit: studentsPerPage,
    };

    // console.log(params);

    if (passoutYear !== "Passout Year") {
      params.filter_by_passout_year = passoutYear;
    }

    if (courseIdList !== null) {
      params.filter_by_course = parseInt(courseIdList);
    }

    if (specializationId !== null) {
      params.filter_by_specialization = parseInt(specializationId);
    }

    if (search !== "") {
      params.search = search;
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    try {
      const response = await axios.get(
        `${API_URL}/api/freshment_portal_apis/Jobs/get_job_analysis`,
        {
          params,
          headers: {
            Authorization: authToken,
          },
        }
      );
      const { data, totalRecords } = response.data;
      setStudentData(data);
      setTotalRecords(totalRecords);
      // console.log(data);
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout());
      }
      console.error("Error fetching student list:", err);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const totalPages = Math.ceil(totalRecords / studentsPerPage);
  const indexOfLastStudent = (currentPage + 1) * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearch = () => {
    fetchStudentList(); // Fetch based on search input when button clicked
  };

  useEffect(() => {
    fetchStudentList(); // Combine all dependencies into a single useEffect
  }, [currentPage, specializationId, passoutYear, courseIdList]);

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(
      0,
      currentPage - Math.floor(studentsPerPage / 2)
    );
    const endPage = Math.min(totalPages - 1, startPage + studentsPerPage - 1);

    if (startPage > 0)
      pages.push(
        <span key="start-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Link
          key={i}
          to="#"
          className={`paginate_button ${i === currentPage ? "bg-white" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Link>
      );
    }

    if (endPage < totalPages - 1)
      pages.push(
        <span key="end-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    return pages;
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    // console.log("courseId", courseId);
    // console.log("coursefilterId", coursefilterId);
    if (courseId != null) {
      const payload = { college_id: collegeId, course_id: courseId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Headers":
          //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          //   },
          // }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          console.log("err======", err);
        });
    } else if (coursefilterId != null) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      const payload = { college_id: collegeId, course_id: coursefilterId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Headers":
          //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          //   },
          // }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          console.log("err======", err);
        });
    }
  }, [courseId, coursefilterId]);

  const handleSubmit = () => {
    // e.preventDefault();
    // Prepare payload for the API
    const payload = {
      student_id: studentId,
      full_name: fullName,
      email: email,
      phone_number: phoneNumber,
      course_id: masterCourseId,
      passout_year: passoutYearForm,
      college_connect_id: collegeId,
      specialization_id: specId,
      interested_in_placement: interestedPlacement,
      interested_in_higher_education: interestedHigherStudy,
    };
    // console.log(payload);

    const params = new URLSearchParams();
    if (isEditing) {
      params.append("student_id", payload.student_id);
    }
    params.append("college_connect_id", payload.college_connect_id);
    params.append("full_name", payload.full_name);
    params.append("email", payload.email);
    params.append("phone_number", payload.phone_number);
    params.append("course_id", payload.course_id);
    params.append("passout_year", payload.passout_year);
    params.append("specialization_id", payload.specialization_id);
    params.append("interested_in_placement", payload.interested_in_placement);
    params.append(
      "interested_in_higher_education",
      payload.interested_in_higher_education
    );
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    // console.log(params);
    if (isEditing) {
      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/students/update_student_details`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire(
              "Success",
              "Student Details Updated successfully!",
              "success"
            );
            setIsEditing(false);
            setModalShow(false);
            // Optionally refresh the student list here
            fetchStudentList();
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setCourseId(null);
            setMasterCourseId(null);
            setSpecId(null);
            setPassoutYearForm("");
            setInterestedPlacement(1);
            setInterestedHigherStudy(1);
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire("Error", "Failed to add student.", "error");
        });
    } else {
      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/Students/add_student_details`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire("Success", "Student added successfully!", "success");
            setModalShow(false);
            // Optionally refresh the student list here
            fetchStudentList();
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setCourseId(null);
            setMasterCourseId(null);
            setSpecId(null);
            setPassoutYearForm("");
            setInterestedPlacement(1);
            setInterestedHigherStudy(1);
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire("Error", "Failed to add student.", "error");
        });
    }
  };

  useEffect(() => {
    if (masterCourseId !== null) {
      const coursemaster = courses?.find(
        (courses) => courses?.course_master_id == masterCourseId
      );
      setCourseId(coursemaster.college_course_id);
    }
  }, [masterCourseId, courses]);

  return (
    <>
      <h4 className="fs-22 mb-3">Students Activity</h4>

      <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
        <div className="col-xl-2 col-xxl-2 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0 style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {courseList}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className="text-primary"
                onClick={() => {
                  setCourseIdList(null);
                  setCourseIdfilter(null);
                  setCourseList("Choose Course");
                  setMasterCourseId(null);
                  setSpecializationId(null);
                  setSpecialization("Choose Specialization");
                  setCurrentPage(0);
                  setSpecializationData([]);
                }}
              >
                Choose Course
              </Dropdown.Item>
              {courses.map((course) => (
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setCourseIdList(course.filter_id);
                    setCourseIdfilter(course.college_course_id);
                    setCourseList(course.name);
                    setSpecializationId(null);
                    setSpecialization("Choose Specialization");
                  }}
                >
                  {course.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-3 col-xxl-3 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0 style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {specialization}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className="text-primary"
                onClick={() => {
                  setSpecializationId(null);
                  setCurrentPage(0);
                  setSpecialization("Choose Specialization");
                }}
              >
                Choose Specialization
              </Dropdown.Item>

              {specializationData && specializationData?.length > 0 ? (
                specializationData?.map((specialization) => (
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => {
                      setSpecializationId(
                        specialization.specilization_master_id
                      );
                      setCurrentPage(0);
                      setSpecialization(specialization.name);
                    }}
                  >
                    {specialization.name}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setSpecializationId(0);
                    setCurrentPage(0);
                    setSpecialization("All");
                  }}
                >
                  All
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-2 col-xxl-2 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0  style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {passoutYear}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className=" text-primary"
                onClick={() => {
                  setCurrentPage(0);
                  setPassoutYear("Passout Year");
                }}
              >
                Passout Year
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => {
                  setCurrentPage(0);
                  setPassoutYear(currentYear - 1);
                }}
              >
                {currentYear - 1}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => {
                  setCurrentPage(0);
                  setPassoutYear(currentYear);
                }}
              >
                {currentYear}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => {
                  setCurrentPage(0);
                  setPassoutYear(currentYear + 1);
                }}
              >
                {currentYear + 1}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => {
                  setCurrentPage(0);
                  setPassoutYear(currentYear + 2);
                }}
              >
                {currentYear + 2}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-5 col-xxl-5 col-lg-12 col-12 d-md-flex job-title-search pe-0">
          <div className="input-group search-area">
            <input
              type="text"
              className="form-control h-auto"
              placeholder="Search student name/email..."
              value={search}
              onChange={(e) => {
                setCurrentPage(0);
                setSearch(e.target.value);
              }}
            />
            <span className="input-group-text">
              <Button
                onClick={handleSearch}
                className="btn btn-primary btn-rounded"
              >
                Search <i className="flaticon-381-search-2 ms-2"></i>
              </Button>
            </span>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : studentList && studentList.length > 0 ? (
        <div
          className="table-responsive dataTables_wrapper"
          id="application-data"
        >
          <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
            <thead>
              <tr role="row">
                <th className="sorting_asc">Student Name</th>
                <th className="sorting_asc">Course ( Specialization )</th>
                <th className="sorting_asc">Passout year</th>
                <th className="sorting_asc">Total Jobs Visited</th>
                <th className="sorting_asc">Profile Shared</th>
                <th className="sorting_asc text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.map((student) => (
                <tr role="row" key={student.user_id} className="odd">
                  <td>{student.student_name}</td>
                  <td>
                    {student.course_name}
                    {student.specialization_name
                      ? ` (${student.specialization_name})`
                      : ""}
                  </td>
                  <td>{student.passout_year}</td>
                  <td>{student.job_visited}</td>
                  <td>{student.profile_shared}</td>
                  <td
                    className="action-btn d-flex align-items-center justify-content-center wspace-no"
                    style={{ gap: 5 }}
                  >
                    <Link
                      className="btn btn-primary btn-sm btn-rounded"
                      to={`/application-details/student/${student.onboarding_student_id}`}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {indexOfFirstStudent + 1} to{" "}
              {Math.min(indexOfLastStudent, totalRecords)} of {totalRecords}{" "}
              entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <Link
                to="#"
                className={`paginate_button previous ${
                  currentPage === 0 ? "disabled" : ""
                }`}
                onClick={() =>
                  currentPage > 0 && handlePageChange(currentPage - 1)
                }
              >
                Previous
              </Link>
              <span>
                {renderPageNumbers()}
                {/* {[...Array(totalPages)].map((_, i) => (
                  <Link
                    key={i}
                    to="#"
                    className={`paginate_button ${
                      i === currentPage ? "bg-white" : ""
                    }`}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </Link>
                ))} */}
              </span>
              <Link
                to="#"
                className={`paginate_button next ${
                  currentPage + 1 >= totalPages ? "disabled" : ""
                }`}
                onClick={() =>
                  currentPage + 1 < totalPages &&
                  handlePageChange(currentPage + 1)
                }
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div>No Students Found</div>
      )}
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Edit Student Details" : "Add New Student"}
          </Modal.Title>

          <Button
            onClick={() => setModalShow(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                disabled={isEditing && email != "" ? true : false}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhoneNumber" className="mt-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Course</Form.Label>
              <Form.Control
                as="select"
                value={masterCourseId}
                onChange={(e) => {
                  if (e.target.value == "Select Course") {
                    setMasterCourseId(0);
                    setSpecId(0);
                    setSpecializationData([]);
                  } else {
                    setMasterCourseId(e.target.value);
                  }
                }}
                id="courseSelect"
              >
                <option value="Select Course">Select Course</option>
                {courses?.map((courses) => (
                  <option value={courses.course_master_id}>
                    {courses.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Specialization</Form.Label>
              <Form.Control
                onChange={(e) => {
                  if (e.target.value == "Select Specialization") {
                    setSpecId(0);
                  } else {
                    setSpecId(e.target.value);
                  }
                }}
                value={specId}
                as="select"
                id="branchSelect"
              >
                <option value="Select Specialization">
                  Select Specialization
                </option>
                {specializationData?.map((specialize) => (
                  <option value={specialize.specilization_master_id}>
                    {specialize.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Select Passout Year</Form.Label>
              <Form.Control
                onChange={(e) => setPassoutYearForm(e.target.value)}
                value={passoutYearForm}
                as="select"
                id="passoutYearSelect"
              >
                <option>Select Year</option>
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear + 1}>{currentYear + 1}</option>
                <option value={currentYear + 2}>{currentYear + 2}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="IntrestedPlacement" className="mt-3">
              <Form.Label>Intrested In College Placement</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value="1"
                  name="interestedPlacement"
                  onChange={() => setInterestedPlacement(1)}
                  checked={interestedPlacement === 1}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="0"
                  name="interestedPlacement"
                  onChange={() => setInterestedPlacement(0)}
                  checked={interestedPlacement === 0}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="intrestedHigherStudies" className="mt-3">
              <Form.Label>Intrested In Higher Studies</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value="1"
                  name="interestedHigherStudy"
                  onChange={() => setInterestedHigherStudy(1)}
                  checked={interestedHigherStudy === 1}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="0"
                  name="interestedHigherStudy"
                  onChange={() => setInterestedHigherStudy(0)}
                  checked={interestedHigherStudy === 0}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)} variant="danger light">
            Close
          </Button>
          <Button onClick={() => handleSubmit()} variant="primary">
            {isEditing ? "Edit Student" : "Add Student"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobApplication;
