import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Dropdown, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
//Image
import profile from "./../../../images/profile1.jpg";
import pic1 from "./../../../images/profile/pic1.jpg";
import Swal from "sweetalert2";
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import userprofileplaceholder from "../../../images/college_profile_placeholder2.png";
import coverplaceholder from "../../../images/profile/cover.jpg";

import { logoutonexpire } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
const MyProfile = () => {
  const collegeId = useSelector((state) => state.college_id.college_id);

  const [collegeData, setCollegeObj] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const dispatch = useDispatch();
  //Loading
  const [Loading, setLoading] = useState(false);
  //update profile
  const [aboutme, setAboutme] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [Address, setAddress] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(""); // For previewing the image
  const [selectionType, setSelectionType] = useState(null);
  //update or add tpo
  const [editTpo, setEditTpo] = useState(false);
  const [tpoId, setTpoId] = useState("");
  const [tpoModal, setTpoModal] = useState(false);
  const [tpoName, setTpoName] = useState("");
  const [tpoEmail, setTpoEmail] = useState("");
  const [tponumber, setTpoNumber] = useState("");
  //social media update
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [facebookId, setFacebookId] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [instagramId, setInstagramId] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [linkedInId, setLinkedInId] = useState("");
  const [linkedInURL, setLinkedInURL] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  //error message
  const [errMessage, setErrMessage] = useState("");
  // membership update
  const [membershipId, setMembershipId] = useState(null);
  const [membershipType, setMembershipType] = useState("");

  // useEffect(() => {
  //   console.log("membershipId", membershipId);
  //   console.log("membership type", membershipType);
  // }, [membershipId, membershipType]);
  const UpdateMembership = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    const payload = {
      college_id: collegeId,
      membership_id: membershipId,
      membership_action: membershipType,
    };
    const params = new URLSearchParams();
    params.append("college_id", collegeId);
    params.append("membership_id", payload.membership_id);
    params.append("membership_action", payload.membership_action);
    Swal.fire({
      title: "Are You Sure ,You Want to Update Membership?",
      //  showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      //  denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${API_URL}/api/freshment_portal_apis/Dashboard/manage_membership`,
            params,
            {
              headers: {
                Authorization: authToken,
              },
            }
          )
          .then((response) => {
            if (response.data.success == true) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.message,
              });
            }
            setMembershipType(null);
          })

          .catch((err) => {
            if (err?.response?.status === 401) {
              dispatch(logoutonexpire());
            }
            console.log("err======", err);
          });
      } else {
        setMembershipType(null);
      }
    });
  };
  //get college profile
  // useEffect(() => {
  //   const collegeProfile = localStorage.getItem("collegeProfile");
  //   const collegeObj = JSON.parse(collegeProfile);
  //   setCollegeObj(collegeObj);
  //   setLoading(false);
  //   return;
  // }, []);
  const fetchcollegeProfile = async () => {
    if (selectedFile || previewImage || aboutme || phoneNumber || fullName) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    // const userDetails = localStorage.getItem("userDetails");
    // const collegeProfile = JSON.parse(userDetails);
    // const payload1 = { college_id: 1 };
    const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Parse the JSON string
    const payload1 = { college_id: collegeId };
    const authToken = userDetails?.data.auth_token; // Access the token
    // const authToken =
    //   "4b72fa1e728397871b64072a42ffd6fc8f2efc628d592b97a4d801fecf9bd7ea";
    // console.log(authToken);
    await axios
      .get(`${API_URL}/api/freshment_portal_apis/College/get_college_profile`, {
        params: payload1,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        // console.log(response);
        setCollegeObj(response.data.data);
        localStorage.setItem(
          "collegeProfile",
          JSON.stringify(response.data.data)
        );
        setErrMessage(response.data.message);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchcollegeProfile();
  }, []);

  const Editing = (data) => {
    setIsEditing(true);
    setAboutme(data.about_me);
    setPhoneNumber(data.college_contact_no);
    setFullName(data.college_name);
    setAddress(data.college_address);
    setUpdateModal(true);
  };

  useEffect(() => {
    console.log(aboutme.length);
  }, [aboutme]);

  const handleUpdateSubmit = () => {
    const formData = new FormData();
    formData.append("college_id", collegeId);
    formData.append("about_me", aboutme);
    formData.append("college_contact_no", phoneNumber);
    formData.append("college_name", fullName);
    formData.append("college_address", Address);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/College/update_college_profile`,
        formData,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "Profile Updated!",
          text: "Your profile has been successfully updated.",
        }).then(async (result) => {
          setUpdateModal(false);
          if (result.isConfirmed) {
            await fetchcollegeProfile();
            setAddress("");
            setAboutme("");
            setPhoneNumber("");
            setFullName("");
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "There was an error updating your profile. Please try again.",
        });
      });
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("college_id", collegeId);
    formData.append("image", selectedFile);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data.auth_token;
    const uploadUrl =
      selectionType === "profile"
        ? `${API_URL}/api/freshment_portal_apis/College/upload_profile_image`
        : `${API_URL}/api/freshment_portal_apis/College/upload_cover_image`;

    axios
      .post(uploadUrl, formData, {
        headers: {
          Authorization: authToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log("Image uploaded successfully:", response);
        Swal.fire({
          icon: "success",
          title: "Image Uploaded!",
          text: "Your image has been successfully uploaded.",
        }).then(async (result) => {
          setSelectedFile(null);
          setPreviewImage(null);
          setUpdateProfileModal(false);
          if (result.isConfirmed) {
            await fetchcollegeProfile();
            window.location.reload();
          }
        });
        // Refresh profile data
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.error("Error uploading image:", error);
        Swal.fire({
          icon: "error",
          title: "Upload Failed",
          text: "There was an error uploading your image. Please try again.",
        });
      });
  };

  const isValidURL = (url) => {
    return url.startsWith("http://") || url.startsWith("https://");
  };

  const handleUpdateSocialMediaLinks = () => {
    const invalidURL = [
      facebookURL,
      twitterURL,
      instagramURL,
      linkedInURL,
      youtubeURL,
    ].find((url) => url && url.trim() !== "" && !isValidURL(url));

    if (invalidURL) {
      Swal.fire({
        icon: "error",
        title: "Invalid URL",
        text: `The URL "${invalidURL}" is not valid. Please include "http://" or "https://".`,
      });
      return; // Stop the function if the URL is invalid
    }

    const payload = {
      college_id: collegeId,
      social_media_links: [
        facebookURL && facebookURL.trim() !== ""
          ? {
              social_link_id: facebookId,
              platform: "facebook",
              social_media_url: facebookURL,
            }
          : null,
        twitterURL && twitterURL.trim() !== ""
          ? {
              social_link_id: twitterId,
              platform: "twitter",
              social_media_url: twitterURL,
            }
          : null,
        instagramURL && instagramURL.trim() !== ""
          ? {
              social_link_id: instagramId,
              platform: "instagram",
              social_media_url: instagramURL,
            }
          : null,
        linkedInURL && linkedInURL.trim() !== ""
          ? {
              social_link_id: linkedInId,
              platform: "linkedin",
              social_media_url: linkedInURL,
            }
          : null,
        youtubeURL && youtubeURL.trim() !== ""
          ? {
              social_link_id: youtubeId,
              platform: "youtube",
              social_media_url: youtubeURL,
            }
          : null,
      ].filter((link) => link !== null), // Filter out null entries
    };

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/College/add_update_social_links`,
        payload,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "Social Media Links Updated!",
          text: "Your social media links have been successfully updated.",
        });
        fetchcollegeProfile();
        setSocialMediaModal(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "There was an error updating your social media links. Please try again.",
        });
      });
  };

  const handleTPOaddUpdate = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;
    const formdata = new FormData();

    if (tpoName === "" || tpoEmail === "" || tponumber === "") {
      Swal.fire({
        icon: "error",
        title: "Please fill all the details",
        text: "Please fill all the details",
      });
      return;
    }
    formdata.append("college_id", collegeId);
    formdata.append("tpo_name", tpoName);
    formdata.append("tpo_email", tpoEmail);
    formdata.append("tpo_contact_no", tponumber);
    editTpo && formdata.append("tpo_id", tpoId);

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/College/update_tpo_details`,
        formdata,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "TPO Details Updated!",
          text: "Your TPO details have been successfully updated.",
        });
        setTpoModal(false);
        setEditTpo(false);
        setTpoEmail("");
        setTpoName("");
        setTpoNumber("");
        fetchcollegeProfile();
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "There was an error updating TPO details. Please try again.",
        });
      });
  };

  const socialMediaEditing = (data) => {
    setSocialMediaModal(true);

    // Find and set URLs for each social platform
    const facebook =
      data.find((social) => social.platform === "facebook")?.social_media_url ||
      "";
    const twitter =
      data.find((social) => social.platform === "twitter")?.social_media_url ||
      "";
    const instagram =
      data.find((social) => social.platform === "instagram")
        ?.social_media_url || "";
    const linkedin =
      data.find((social) => social.platform === "linkedin")?.social_media_url ||
      "";
    const youtube =
      data.find((social) => social.platform === "youtube")?.social_media_url ||
      "";

    const facebookId =
      data.find((social) => social.platform === "facebook")?.social_link_id ||
      "";
    const twitterId =
      data.find((social) => social.platform === "twitter")?.social_link_id ||
      "";
    const instagramId =
      data.find((social) => social.platform === "instagram")?.social_link_id ||
      "";
    const linkedinId =
      data.find((social) => social.platform === "linkedin")?.social_link_id ||
      "";
    const youtubeId =
      data.find((social) => social.platform === "youtube")?.social_link_id ||
      "";

    setFacebookURL(facebook);
    setFacebookId(facebookId);
    setTwitterURL(twitter);
    setTwitterId(twitterId);
    setInstagramURL(instagram);
    setInstagramId(instagramId);
    setLinkedInURL(linkedin);
    setLinkedInId(linkedinId);
    setYoutubeURL(youtube);
    setYoutubeId(youtubeId);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file)); // Create a URL for the preview
    }
  };

  const editingTpo = (tpodata) => {
    setEditTpo(true);
    setTpoId(tpodata.tpo_id);
    setTpoName(tpodata.tpo_name);
    setTpoEmail(tpodata.tpo_email);
    setTpoNumber(tpodata.tpo_contact_no);
    setTpoModal(true);
  };

  //membership

  const membership =
    Array.isArray(collegeData?.membership_plan_details) &&
    collegeData?.membership_plan_details.length > 0
      ? collegeData?.membership_plan_details[0]
      : collegeData?.membership_plan_details;

  const {
    membership_id = null,
    start_date = null,
    end_date = null,
    is_active = "0",
    is_trial_active = "0",
    is_trial_completed = "0",
    plan_name = "",
    type = "",
    discounted_price = 0,
    level = "",
    trial_end_date = null,
    trial_start_date = null,
    duration_days,
    is_plan_expired,
  } = membership || {};

  useEffect(() => {
    setMembershipId(membership_id);
  }, [membership]);

  // const another = collegeData.membership_plan_details;
  // const { is_trial_active } = another;
  // console.log(membership);

  // Parse dates
  const endDate = end_date ? new Date(end_date) : new Date(trial_end_date);
  const startDate = start_date
    ? new Date(start_date)
    : new Date(trial_start_date);
  const today = new Date();

  // Ensure startDate is before endDate
  if (startDate > endDate) {
    throw new Error("Start date cannot be after end date.");
  }

  // Calculate total time of the membership or trial
  const totalTime = endDate - startDate;

  // Calculate remaining time
  const remainingTime = endDate > today ? endDate - today : 0;
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  // console.log("remainingDays", remainingDays);
  // console.log("remainingTime", remainingTime);
  // console.log("duration_days", duration_days);
  const duration =
    duration_days != 0 ? duration_days : type == "Monthly" ? 30 : 365;
  // Calculate total days for the progress calculation
  const totalDays = Math.ceil(duration);
  // console.log("totalDays", totalDays);
  // Calculate progress percentage based on the actual duration
  const progressPercentage =
    remainingDays > 0 ? 100 - (remainingDays / totalDays) * 100 : 0;
  // console.log("progress", progressPercentage);
  // Determine membership status
  const isTrialActive = is_trial_active == "1";
  const isTrialCompleted = is_trial_completed == "1";
  const isMembershipActive = is_active == "1";
  const isMembershipExpired = remainingDays <= 0 && is_active == "0";

  const renderBadge = () => (
    <div
      className="justify-content-center"
      style={{ padding: 0, marging: 0, width: "100%" }}
    >
      <div
        className="col-12 d-flex align-items-center justify-content-center"
        style={{ padding: 0, marging: 0, width: "100%" }}
      >
        <div className="row w-100 no-gutters">
          <div
            className="col-4 col-sm-4 col-lg-12 col-xl-12 col-xxl-12 text-center"
            style={{
              border: "1px solid #ffd700",
              padding: "2px 5px",
              textWrap: "wrap",
            }}
          >
            <p className="mb-1 fs-12">Membership</p>
            <p className="mb-0" style={{ fontWeight: "bold" }}>
              {type}
            </p>
          </div>
          <div
            className="col-4 col-sm-4 col-lg-6 col-xl-6 text-center col-xxl-6"
            style={{
              border: "1px solid #ffd700",
              padding: "2px 5px",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              textWrap: "wrap",
            }}
          >
            <p className="mb-1 fs-12">Plan Name</p>
            <p className="mb-0" style={{ fontWeight: "bold" }}>
              {plan_name}
            </p>
          </div>

          <div
            className="col-4 col-sm-4 col-lg-6 col-xl-6 col-xxl-6 text-center"
            style={{
              border: "1px solid #ffd700",
              padding: "2px 5px",
              textWrap: "wrap",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <p className="mb-1 fs-12">Price</p>
            <p className="mb-0" style={{ fontWeight: "bold" }}>
              {discounted_price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const getBorderColor = (level) => {
    switch (level) {
      case "Gold":
        return "#efbf04"; // Gold color
      case "Silver":
        return "#ced4da"; // Silver color
      case "Platinum":
        return "#778ad9"; // Platinum color
      case "Bronze":
        return "#cd7f32"; // Bronze color
      case "Trial":
        return "#72bf78"; // Trial color
      case "Expired":
        return "#ff8a8a"; // Expired color
      default:
        return "#ccc"; // Default color
    }
  };

  const getFontColor = (level) => {
    switch (level) {
      case "Gold":
        return "#fff"; // Gold color
      case "Silver":
        return "#000"; // Silver color
      case "Platinum":
        return "#fff"; // Platinum color
      case "Bronze":
        return "#fff"; // Bronze color
      case "Trial":
        return "#fff"; // Trial color
      case "Expired":
        return "#fff"; // Expired color
      default:
        return "#ccc"; // Default color
    }
  };

  useEffect(() => {
    if (
      (membershipType !== null && membershipType == "renew") ||
      (membershipType !== null && membershipType == "request_new")
    )
      UpdateMembership();
  }, [membershipType]);

  // useEffect(() => {
  //   console.log(collegeData.about_me);
  // }, [collegeData]);

  return (
    <>
      {Loading ? (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : collegeData && Object.keys(collegeData).length > 0 ? (
        <>
          <div className="row">
            <div
              className="col-12"
              style={{ position: "relative", height: "34rem" }}
            >
              <div className="position-relative">
                <img
                  src={
                    collegeData.cover_picture != null &&
                    collegeData.cover_picture != ""
                      ? collegeData.cover_picture
                      : coverplaceholder
                  }
                  alt="College cover"
                  className="w-100 rounded"
                  style={{
                    height: "25rem",
                    objectFit: "cover",
                  }}
                />
                <div
                  onClick={() => {
                    setSelectionType("cover");
                    setUpdateProfileModal(true);
                  }}
                  className="position-absolute"
                  style={{
                    bottom: "0.03rem",
                    right: "0.03rem",
                    background: "white",
                    borderRadius: "50%",
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    padding: "0.5rem",
                    cursor: "pointer",
                    zIndex: 10,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2rem"
                    height="1.2rem"
                    viewBox="0 0 14 14"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m13.5 8.5l-4.71 4.71l-2.13.29l.3-2.13l4.7-4.71zm-9.219 5H1.8a1.3 1.3 0 0 1-1.3-1.3V1.8A1.3 1.3 0 0 1 1.8.5h10.4a1.3 1.3 0 0 1 1.3 1.3v2.95"></path>
                      <path d="M9.014 4.795a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5M.5 7.164a10.3 10.3 0 0 1 6.5.961"></path>
                    </g>
                  </svg>
                </div>
              </div>

              <div
                className="myprofileimgcontainer d-flex align-items-end mt-3"
                style={{
                  position: "absolute",
                  top: "16.25rem",
                  left: "2.5rem",
                  paddingLeft: "2.5rem",
                  right: 0,
                  height: "220px",
                }}
              >
                <div className="myprofileimagediv position-relative mr-2">
                  <img
                    src={
                      collegeData.profile_picture != null &&
                      collegeData.profile_picture != ""
                        ? collegeData.profile_picture
                        : userprofileplaceholder
                    }
                    alt="College profile"
                    className="rounded myprofileimg"
                    style={{
                      height: "13.625rem",
                      width: "13.625rem",
                      objectFit: "cover",
                      border: "0.5rem solid white",
                      marginRight: "2rem",
                    }}
                  />
                  <div
                    onClick={() => {
                      setSelectionType("profile");
                      setUpdateProfileModal(true);
                    }}
                    // className="position-absolute"
                    style={{
                      bottom: "0.25rem",
                      right: "2.125rem",
                      background: "white",
                      borderRadius: "50%",
                      padding: "0.5rem",
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 10,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2rem"
                      height="1.2rem"
                      viewBox="0 0 14 14"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m13.5 8.5l-4.71 4.71l-2.13.29l.3-2.13l4.7-4.71zm-9.219 5H1.8a1.3 1.3 0 0 1-1.3-1.3V1.8A1.3 1.3 0 0 1 1.8.5h10.4a1.3 1.3 0 0 1 1.3 1.3v2.95"></path>
                        <path d="M9.014 4.795a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5M.5 7.164a10.3 10.3 0 0 1 6.5.961"></path>
                      </g>
                    </svg>
                  </div>
                </div>

                <div className="profile-info2" style={{ width: "100%" }}>
                  <div className="d-flex align-items-start justify-content-between">
                    <div>
                      <h3 className="mb-2">{collegeData?.college_name}</h3>
                      <span className="d-block">
                        <i className="fas fa-envelope me-2"></i>
                        {collegeData?.college_email}
                        <i className="fas fa-phone ms-2 me-2"></i>
                        {collegeData?.college_contact_no}
                        <i className="fas fa-map-marker-alt ms-2 me-2"></i>
                        {collegeData?.college_address}
                      </span>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => Editing(collegeData)}
                      className="btn btn-primary btn-sm"
                    >
                      Update Profile
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-3 col-lg-3 mt-5">
              <div
                className="card"
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                  position: "relative",
                  height: "fit-content",
                  // boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Deeper shadow for depth
                  border: `2px solid ${getBorderColor(
                    is_plan_expired == 1
                      ? "Expired"
                      : isMembershipActive && is_plan_expired == 0
                      ? level
                      : isTrialActive && !isTrialCompleted
                      ? "Trial"
                      : "Expired"
                  )}`, // Dynamic border color
                }}
              >
                <div
                  className="card-body text-center"
                  style={{ padding: "30px", backgroundColor: "#fdfdfd" }} // Light background for contrast
                >
                  <div style={{ position: "relative", marginBottom: "30px" }}>
                    <img
                      src={
                        collegeData.profile_picture != null &&
                        collegeData.profile_picture != ""
                          ? collegeData.profile_picture
                          : userprofileplaceholder
                      }
                      alt="Profile"
                      style={{
                        width: "90px",
                        height: "90px",
                        borderRadius: "50%",
                        border: `4px solid  ${getBorderColor(
                          is_plan_expired == 1
                            ? "Expired"
                            : isMembershipActive && is_plan_expired == 0
                            ? level
                            : isTrialActive && !isTrialCompleted
                            ? "Trial"
                            : "Expired"
                        )}`,
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        objectFit: "cover",
                      }}
                    />
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      height={45}
                      width={180}
                      viewBox="0 0 122.88 32.31"
                      xmlSpace="preserve"
                      className="position-absolute "
                      style={{
                        top: "95%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        // enableBackground: "new 0 0 122.88 32.31",
                      }}
                      fill={`${getBorderColor(
                        is_plan_expired == 1
                          ? "Expired"
                          : isMembershipActive && is_plan_expired == 0
                          ? level
                          : isTrialActive && !isTrialCompleted
                          ? "Trial"
                          : "Expired"
                      )}`}
                    >
                      <style type="text/css">
                        {`.st0{fill-rule:evenodd;clip-rule:evenodd;}`}
                      </style>
                      <g>
                        <path
                          className="st0"
                          d="M29.7,21.41L27.12,4.89c25.32-6.26,42.13-6.78,68.66,0l-2.72,16.52C67.41,14.54,52.39,15.41,29.7,21.41 L29.7,21.41z M94.49,22.85l2.26-12.09c9.16,2.02,17.5,4.9,26.13,8.38l-17.61,1.84c3.02,3.4,5.78,7.1,8.28,11.07 c-9.75-3.46-18.44-6.03-25.71-7.39l0.5-3.25L94.49,22.85L94.49,22.85z M28.39,23.11l-2.26-12.09C16.97,13.04,8.62,15.91,0,19.4 l17.61,1.84c-3.02,3.4-5.78,7.1-8.28,11.07c9.75-3.46,18.44-6.03,25.71-7.39l-0.5-3.25L28.39,23.11L28.39,23.11z"
                        />
                      </g>
                    </svg>
                    <svg
                      height="100"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      style={{
                        position: "absolute",
                        top: "99%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        fontSize: "9px",
                        fontWeight: "bold",
                      }}
                    >
                      <path
                        id="gentleCurve"
                        d="M 10,60 Q 100,20 190,60"
                        fill="transparent"
                      />

                      <text
                        fontSize="9px"
                        fontWeight="bold"
                        fill={`${getFontColor(
                          is_plan_expired == 1
                            ? "Expired"
                            : isMembershipActive && is_plan_expired == 0
                            ? level
                            : isTrialActive && !isTrialCompleted
                            ? "Trial"
                            : "Expired"
                        )}`}
                      >
                        <textPath
                          href="#gentleCurve"
                          startOffset="50%"
                          textAnchor="middle"
                        >
                          {isMembershipActive
                            ? `${level} Member`
                            : isTrialActive && !isTrialCompleted
                            ? `Trial Member`
                            : "Trial Expired"}
                        </textPath>
                      </text>
                    </svg>
                  </div>
                  {renderBadge()}

                  {isTrialActive && !isTrialCompleted ? (
                    <>
                      <div style={{ width: "fit-content", margin: "auto" }}>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "10px",

                            marginBottom: "10px",
                            backgroundColor: `${getBorderColor(
                              is_plan_expired == 1
                                ? "Expired"
                                : isMembershipActive && is_plan_expired == 0
                                ? level
                                : isTrialActive && !isTrialCompleted
                                ? "Trial"
                                : "Expired"
                            )}`,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            color: ` ${getFontColor(
                              is_plan_expired
                                ? "Expired"
                                : isMembershipActive && !is_plan_expired
                                ? level
                                : isTrialActive && !isTrialCompleted
                                ? "Trial"
                                : "Expired"
                            )}`,
                            fontWeight: "bold",
                          }}
                        >
                          FREE TRIAL
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#777",
                          marginBottom: "20px",
                        }}
                      >
                        Valid until {endDate.toLocaleDateString()}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#337ab7",
                        }}
                      >
                        <span>{remainingDays} days left</span>
                      </div>{" "}
                      <button
                        type="button"
                        onClick={() => {
                          setMembershipType("request_new");
                        }}
                        className="btn btn-primary mt-3"
                      >
                        Request Membership
                      </button>
                    </>
                  ) : !isTrialActive &&
                    isTrialCompleted &&
                    isMembershipActive &&
                    is_plan_expired == 0 ? (
                    <>
                      {" "}
                      <div style={{ width: "fit-content", margin: "auto" }}>
                        <h4
                          className="mt-3"
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            backgroundColor: `${getBorderColor(
                              is_plan_expired == 1
                                ? "Expired"
                                : isMembershipActive && is_plan_expired == 0
                                ? level
                                : isTrialActive && !isTrialCompleted
                                ? "Trial"
                                : "Expired"
                            )}`,
                            marginBottom: "10px",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            color: ` ${getFontColor(
                              isMembershipActive
                                ? level
                                : isTrialActive && !isTrialCompleted
                                ? "Trial"
                                : "Expired"
                            )}`,
                            fontWeight: "bold",
                          }}
                        >
                          {level} Membership
                        </h4>
                      </div>
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "#777",
                          marginBottom: "20px",
                        }}
                      >
                        Valid until {endDate.toLocaleDateString()}
                      </div>
                      <div
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          color: "#337ab7",
                        }}
                      >
                        <span>{remainingDays} days left</span>
                      </div>
                      {remainingDays <= 30 && (
                        <button
                          type="button"
                          onClick={() => {
                            setMembershipType("renew");
                          }}
                          className="btn btn-warning mt-3"
                        >
                          Renew Membership
                        </button>
                      )}
                    </>
                  ) : is_plan_expired == 1 ? (
                    <>
                      <div style={{ width: "fit-content", margin: "auto" }}>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: getBorderColor("Expired"),
                            padding: "5px 10px",
                            borderRadius: "4px",
                            color: getFontColor("Expired"),
                            fontWeight: "bold",
                          }}
                        >
                          MEMBERSHIP EXPIRED
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "#777",
                          marginBottom: "20px",
                        }}
                      >
                        Expired on {endDate.toLocaleDateString()}
                      </div>
                      <div
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          color: "#337ab7",
                        }}
                      >
                        <span>0 Days left</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          setMembershipType("renew");
                        }}
                        className="btn btn-primary mt-3"
                      >
                        Renew Membership
                      </button>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "fit-content", margin: "auto" }}>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: getBorderColor("Expired"),
                            padding: "5px 10px",
                            borderRadius: "4px",
                            color: getFontColor("Expired"),
                            fontWeight: "bold",
                          }}
                        >
                          FREE TRIAL EXPIRED
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "#777",
                          marginBottom: "20px",
                        }}
                      >
                        Expired on {endDate.toLocaleDateString()}
                      </div>
                      <div
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          color: "#337ab7",
                        }}
                      >
                        <span>0 Days left</span>
                      </div>
                      <button className="btn btn-primary mt-3">
                        Request Membership
                      </button>
                    </>
                  )}

                  {/* <div
                    className="progress mt-3"
                    style={{
                      height: "15px",
                      borderRadius: "7.5px",
                      backgroundColor: "#e9ecef",
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${progressPercentage}%`,
                        borderRadius: "5px",
                        backgroundColor: "#28a745", // Green for progress
                      }}
                      aria-valuenow={progressPercentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {Math.round(progressPercentage)}%
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-xl-9 col-xxl-9 col-lg-9 mt-lg-5 mt-0">
              <div className="row">
                <div className="col-xl-8 col-xxl-8">
                  <div className="card" style={{ height: "fit-content" }}>
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20 font-w600">About Me</h4>
                    </div>
                    <div className="card-body">
                      <div
                        className="fs-18"
                        dangerouslySetInnerHTML={{
                          __html: collegeData?.about_me?.replace(
                            /\\n/g,
                            "<br />"
                          ), // Note the double backslash
                        }}
                      />
                    </div>
                  </div>

                  <div className="card mt-4" style={{ height: "fit-content" }}>
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20 font-w600">TPO Information</h4>
                      <div>
                        <Button
                          onClick={() => {
                            setEditTpo(false);
                            setTpoModal(true);
                          }}
                        >
                          Add TPO
                        </Button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {collegeData?.tpo_details.map((tpo) => (
                          <div key={tpo.tpo_id} className="col-md-12 mb-0">
                            <div className="card border shadow-sm">
                              <div
                                className="card-body d-flex align-items-center"
                                style={{ width: "100%" }}
                              >
                                <span className="me-4">
                                  <i className="fas fa-user-tie fa-2x text-primary"></i>
                                </span>
                                <div>
                                  <h5 className="mb-1">{tpo.tpo_name}</h5>
                                  <p className="mb-1">
                                    <i className="fas fa-envelope me-2"></i>
                                    {tpo.tpo_email}
                                  </p>
                                  <p className="mb-0">
                                    <i className="fas fa-phone-alt me-2"></i>
                                    {tpo.tpo_contact_no}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-self-end justify-content-start ms-auto"
                                  style={{ height: "100%" }}
                                >
                                  <i
                                    onClick={() => editingTpo(tpo)}
                                    className="fas fa-edit fa-md text-primary"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-4 col-xxl-4"
                  style={{ height: "fit-content" }}
                >
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        borderBottom: "none",
                        marginBottom: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <h4 className="fs-20 font-w600">Statistics</h4>
                      <Link className="btn btn-primary btn-sm" to="/statistics">
                        View Statistics
                      </Link>
                    </div>
                    <div
                      style={{
                        padding: "8px",
                      }}
                      className="d-flex justify-content-between align-items-center mt-4"
                    >
                      <div className="d-block text-center">
                        <p
                          className="fs-18 mb-1"
                          style={{ fontWeight: "bold" }}
                        >
                          {collegeData?.total_students}
                        </p>
                        <p>Total Students</p>
                      </div>
                      <div className="d-block text-center">
                        <p
                          className="fs-18 mb-1"
                          style={{ fontWeight: "bold" }}
                        >
                          {collegeData?.total_placed_students}
                        </p>
                        <p>Placed Students</p>
                      </div>
                      <div className="d-block text-center">
                        <p
                          className="fs-18 mb-1"
                          style={{ fontWeight: "bold" }}
                        >
                          {collegeData?.total_unplaced_students}
                        </p>
                        <p>Pending Students</p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header align-items-center border-0 pb-0">
                      <h4 className="fs-20 font-w600 mb-0">
                        Social Media Links
                      </h4>
                      <div
                        className="fs-22"
                        style={{
                          cursor: "pointer",
                          height: "fit-content",
                          width: "fit-content",
                          padding: 0,
                          margin: 0,
                        }}
                        onClick={() =>
                          socialMediaEditing(collegeData?.social_media_links)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.2em"
                          height="1.2em"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="currentColor"
                            d="m29.707 7.293l-3-3a1 1 0 0 0-1.414 0L16 13.586V18h4.414l9.293-9.293a1 1 0 0 0 0-1.414M19.586 16H18v-1.586l5-5L24.586 11zM26 9.586L24.414 8L26 6.414L27.586 8zM10 14a4 4 0 1 1 4-4a4.005 4.005 0 0 1-4 4m0-6a2 2 0 1 0 1.998 2.004A2 2 0 0 0 10 8"
                          ></path>
                          <path
                            fill="currentColor"
                            d="m27.006 14.235l-1.414 1.414L28 18.058L18.058 28L4 13.941V4h9.942l4.406 4.407l1.415-1.415l-4.407-4.406A2 2 0 0 0 13.94 2H4a2 2 0 0 0-2 2v9.941a2 2 0 0 0 .586 1.414l14.058 14.06a2 2 0 0 0 2.828 0l9.943-9.943a2 2 0 0 0 0-2.828Z"
                          ></path>
                        </svg>
                      </div>
                      {/* <i
                        onClick={() =>
                          socialMediaEditing(collegeData?.social_media_links)
                        }
                        className="fas fa-edit scale5"
                        style={{ cursor: "pointer" }}
                      ></i> */}
                    </div>
                    <div className="card-body">
                      <div>
                        {collegeData?.social_media_links.find(
                          (link) => link.platform === "facebook"
                        )?.social_media_url ? (
                          <a
                            href={
                              collegeData?.social_media_links.find(
                                (link) => link.platform === "facebook"
                              )?.social_media_url || "#"
                            }
                            target="_blank"
                            className="btn text-start d-block mb-3 bg-facebook light"
                          >
                            <i className="fab fa-facebook-f scale5 me-4 text-facebook"></i>
                            Facebook
                          </a>
                        ) : null}
                        {collegeData?.social_media_links.find(
                          (link) => link.platform === "linkedin"
                        )?.social_media_url ? (
                          <a
                            href={
                              collegeData?.social_media_links.find(
                                (link) => link.platform === "linkedin"
                              )?.social_media_url || "#"
                            }
                            className="btn text-start d-block mb-3 bg-linkedin light"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin-in scale5 me-4 text-linkedin"></i>
                            Linkedin
                          </a>
                        ) : null}
                        {collegeData?.social_media_links.find(
                          (link) => link.platform === "instagram"
                        )?.social_media_url ? (
                          <a
                            href={
                              collegeData?.social_media_links.find(
                                (link) => link.platform === "instagram"
                              )?.social_media_url || "#"
                            }
                            target="_blank"
                            className="btn text-start d-block mb-3 bg-dribble light"
                          >
                            <i className="fab fa-instagram scale5 me-4 text-dribble"></i>
                            Instagram
                          </a>
                        ) : null}
                        {collegeData?.social_media_links.find(
                          (link) => link.platform === "youtube"
                        )?.social_media_url ? (
                          <a
                            href={
                              collegeData?.social_media_links.find(
                                (link) => link.platform === "youtube"
                              )?.social_media_url || "#"
                            }
                            target="_blank"
                            className="btn text-start d-block mb-3 bg-youtube light"
                          >
                            <i className="fab fa-youtube scale5 me-4 text-youtube"></i>
                            Youtube
                          </a>
                        ) : null}
                        {collegeData?.social_media_links.find(
                          (link) => link.platform === "twitter"
                        )?.social_media_url ? (
                          <a
                            href={
                              collegeData?.social_media_links.find(
                                (link) => link.platform === "twitter"
                              )?.social_media_url || "#"
                            }
                            target="_blank"
                            className="btn text-start d-block mb-3 bg-x light"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              viewBox="0 0 24 24"
                              className="me-4"
                            >
                              <path
                                fill="currentColor"
                                d="M8 2H1l8.26 11.015L1.45 22H4.1l6.388-7.349L16 22h7l-8.608-11.478L21.8 2h-2.65l-5.986 6.886zm9 18L5 4h2l12 16z"
                              ></path>
                            </svg>
                            X - Platform
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          {" "}
          <p className="fs-18 text-center">{errMessage}</p>{" "}
        </div>
      )}
      <Modal
        size="lg"
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit College Details</Modal.Title>

          <Button
            onClick={() => setUpdateModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFullName">
              <Form.Label>College Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter College name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>About Me</Form.Label>
              <Form.Control
                as={"textarea"}
                // type="text"
                className="form-control"
                rows={5}
                maxLength={5000}
                style={{
                  height: "114px",
                  resize: "none",
                }}
                placeholder="Enter About Yourself"
                value={aboutme}
                onChange={(e) => setAboutme(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhoneNumber" className="mt-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPhoneNumber" className="mt-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>

            {/*
            <Form.Group controlId="formPassoutYear" className="mt-3">
              <Form.Label>Passout Year</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter passout year"
                value={passoutYear}
                onChange={(e) => setPassoutYearForm(e.target.value)}
                required
              />
            </Form.Group> */}

            {/* <Button variant="primary" onClick={handleSubmit} className="mt-4">
              Add Student
            </Button> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setUpdateModal(false)} variant="danger light">
            Close
          </Button>
          <Button onClick={() => handleUpdateSubmit()} variant="primary">
            Update Profile
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={updateProfileModal}
        onHide={() => setUpdateProfileModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Update {selectionType === "profile" ? "Profile" : "Cover"} Image
          </Modal.Title>
          <Button
            onClick={() => setUpdateProfileModal(false)}
            variant="close"
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="text-center">
              <Form.Label className="d-block mb-3">
                <div
                  style={
                    selectionType === "profile"
                      ? {
                          width: "220px",
                          height: "220px",
                          border: "3px dashed #cccccc",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          cursor: "pointer",
                          borderRadius: "8px",
                          margin: "0 auto",
                          overflow: "hidden",
                          objectFit: "cover",
                        }
                      : {
                          width: "300px",
                          height: "200px",
                          border: "3px dashed #cccccc",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          cursor: "pointer",
                          borderRadius: "8px",
                          margin: "0 auto",
                          overflow: "hidden",
                          objectFit: "cover",
                        }
                  }
                >
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <span className="d-block" style={{ color: "black" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35px"
                        height="35px"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                        >
                          <path
                            strokeDasharray={72}
                            strokeDashoffset={72}
                            d="M3 14v-9h18v14h-18v-5"
                          >
                            <animate
                              fill="freeze"
                              attributeName="stroke-dashoffset"
                              dur="0.6s"
                              values="72;0"
                            ></animate>
                          </path>
                          <path
                            strokeDasharray={24}
                            strokeDashoffset={24}
                            strokeWidth={1}
                            d="M3 16l4 -3l3 2l6 -5l5 4"
                          >
                            <animate
                              fill="freeze"
                              attributeName="stroke-dashoffset"
                              begin="0.6s"
                              dur="0.4s"
                              values="24;0"
                            ></animate>
                          </path>
                        </g>
                        <g fill="currentColor" fillOpacity={0}>
                          <circle cx={7.5} cy={9.5} r={1.5}>
                            <animate
                              fill="freeze"
                              attributeName="fill-opacity"
                              begin="1s"
                              dur="0.2s"
                              values="0;1"
                            ></animate>
                          </circle>
                          <path d="M3 16l4 -3l3 2l6 -5l5 4V19H3Z">
                            <animate
                              fill="freeze"
                              attributeName="fill-opacity"
                              begin="1.3s"
                              dur="0.5s"
                              values="0;1"
                            ></animate>
                          </path>
                        </g>
                      </svg>
                      Click to select an image
                    </span>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{
                      display: "none",
                    }}
                    id="file-input"
                  />
                </div>
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setSelectedFile(null);
              setPreviewImage(null);
              setUpdateProfileModal(false);
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleFileUpload}
            variant="primary"
            // disabled={!selectedFile}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={socialMediaModal}
        onHide={() => setSocialMediaModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Update Social Media Links</Modal.Title>
          <Button
            onClick={() => setSocialMediaModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFacebook" className="mt-3">
              <Form.Label>Facebook URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Facebook URL"
                value={facebookURL}
                onChange={(e) => setFacebookURL(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formTwitter" className="mt-3">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Twitter URL"
                value={twitterURL}
                onChange={(e) => setTwitterURL(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formInstagram" className="mt-3">
              <Form.Label>Instagram URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Instagram URL"
                value={instagramURL}
                onChange={(e) => setInstagramURL(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formLinkedIn" className="mt-3">
              <Form.Label>LinkedIn URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter LinkedIn URL"
                value={linkedInURL}
                onChange={(e) => setLinkedInURL(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formYouTube" className="mt-3">
              <Form.Label>YouTube URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter YouTube URL"
                value={youtubeURL}
                onChange={(e) => setYoutubeURL(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setSocialMediaModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            onClick={() => handleUpdateSocialMediaLinks()}
            variant="primary"
          >
            Update Links
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={tpoModal} onHide={() => setTpoModal(false)} centered>
        <Modal.Header>
          <Modal.Title>{editTpo ? "Update TPO" : "Add TPO"}</Modal.Title>
          <Button
            onClick={() => setTpoModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="tpoName" className="mt-3">
              <Form.Label>TPO Name : </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter TPO Name"
                value={tpoName}
                onChange={(e) => setTpoName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="tpoEmail" className="mt-3">
              <Form.Label>TPO Email : </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter TPO mail"
                value={tpoEmail}
                onChange={(e) => setTpoEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="tponumber" className="mt-3">
              <Form.Label>TPO Contact Number : </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter TPO Contact Number"
                value={tponumber}
                onChange={(e) => setTpoNumber(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setEditTpo(false);
              setTpoEmail("");
              setTpoName("");
              setTpoNumber("");
              setTpoModal(false);
            }}
            variant="danger light"
          >
            Close
          </Button>
          <Button onClick={() => handleTPOaddUpdate()} variant="primary">
            {editTpo ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MyProfile;
