import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// image
import logo from "../../images/freshment.png";
import logo2 from "../../images/TwoFV.png";
import backArrow from "../../images/back_arrow.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_URL } from "../../config";
import { useHistory } from "react-router-dom";
import {
  runLogoutTimer,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import freshmentlogo from "../../images/freshmentLogo1.png";
import { logoutonexpire } from "../../store/actions/AuthActions";
import { fetchCourses } from "../../store/actions/CourseAction";
const TwoFactor = ({ history }) => {
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [Otp, setOtp] = useState("");
  const [trusted, setTrusted] = useState(0);
  const [result] = useState(
    localStorage.getItem("TempUserDetails")
      ? JSON.parse(localStorage.getItem("TempUserDetails"))
      : null
  );
  const dispatch = useDispatch();
  const [ipAddress, setIpAddress] = useState("");
  const [location, setLocation] = useState("");

  // Fetch IP address and location
  useEffect(() => {
    const fetchIpAndLocation = async () => {
      try {
        const { data } = await axios.get("https://ipapi.co/json/"); // Use any IP-geo API
        setIpAddress(data.ip);
        setLocation(`${data.city}, ${data.country_name}`);
      } catch (error) {
        console.error("Error fetching IP or location:", error);
      }
    };
    fetchIpAndLocation();
  }, []);
  const getDeviceInfo = () => {
    if (navigator.userAgentData) {
      return navigator.userAgentData.brands
        .map((brand) => `${brand.brand} ${brand.version}`)
        .join(", ");
    } else {
      return navigator.userAgent; // Fallback to userAgent
    }
  };
  // useEffect(() => {
  //   console.log(trusted);
  // }, [trusted]);

  useEffect(() => {
    if (!ipAddress || !location) return; // Wait until IP and location are fetched

    const deviceInfo = getDeviceInfo();

    const params = new URLSearchParams();
    params.append("college_connect_id", collegeId);
    params.append("ip_address", ipAddress);
    params.append("device_info", deviceInfo);
    params.append("location", location);

    const userDetails = JSON.parse(localStorage.getItem("TempUserDetails"));
    const authToken = userDetails?.data?.auth_token;
    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/college/generate_twofactor_otp`,
        params,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        Swal.close();
        if (response.data.success === true) {
          // Handle success
        } else {
          Swal.fire("Error", response.data.message, "error");
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        Swal.fire(
          "Error",
          "An error occurred while generating the OTP, Please Resend the OTP",
          "error"
        );
      });
  }, [ipAddress, location]);

  const resendOTP = () => {
    const userDetails = JSON.parse(localStorage.getItem("TempUserDetails"));
    const authToken = userDetails?.data?.auth_token;

    const payload = {
      is_trusted: trusted,
    };
    const deviceInfo = getDeviceInfo();
    const params = new URLSearchParams();
    params.append("college_connect_id", collegeId);
    params.append("ip_address", ipAddress);
    params.append("device_info", deviceInfo);
    params.append("location", location);
    params.append("is_trusted", payload.is_trusted);
    // console.log(params);
    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/college/generate_twofactor_otp`,
        params,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log(e);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      is_trusted: trusted,
    };
    const params = new URLSearchParams();
    params.append("otp", Otp);
    params.append("college_id", collegeId);
    params.append("is_trusted", payload.is_trusted);
    const userDetails = JSON.parse(localStorage.getItem("TempUserDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/College/verify_twofactor_auth`,
        params,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        // console.log("res==", res);

        if (res.data.success == true) {
          // console.log(result);
          Swal.fire({
            icon: "success",
            title: "Varification Completed!",
            text: res.data.message,
          }).then(() => {
            saveTokenInLocalStorage(result);
            runLogoutTimer(dispatch, result.expiresIn * 1000, history);
            dispatch(loginConfirmedAction(result));
            history.push("/dashboard");
            localStorage.removeItem("TempUserDetails");
            const payload = {
              college_id: collegeId,
            };
            dispatch(fetchCourses(payload));
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.message,
          });
          // console.log(res.data.message);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      });
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-left mb-3">
                      <Link to="/login">
                        <img src={backArrow} alt="" />
                      </Link>
                    </div>
                    <div className="text-center mb-3">
                      <img
                        style={{ height: "100%", width: "350px" }}
                        src={freshmentlogo}
                        alt=""
                      />
                    </div>
                    <h3 className="text-center mb-4 ">2 Step Verification</h3>
                    <div className="text-center mb-3">
                      <img src={logo2} alt="" />
                    </div>
                    <div>
                      <br />
                    </div>

                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>Enter Your Code </strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setOtp(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <input
                          type="checkbox"
                          id="remember_me"
                          className="form-check-input"
                          onChange={(e) => setTrusted(e.target.checked ? 1 : 0)}
                          // value={trusted}
                          checked={trusted == 0 ? false : true}
                        />
                        <label for="remember_me" className="form-check-label">
                          Remember Me For 30 Days
                        </label>
                      </div>
                      <div>
                        <br />
                        <br />
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          onClick={() => resendOTP()}
                          className="btn btn-primary me-4"
                        >
                          Resend OTP
                        </button>
                        <button type="submit" className="btn btn-primary">
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
