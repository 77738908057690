import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AvailableJobs = ({ jobsdata }) => {
  // console.log(jobsdata);

  const [jobsdataList, setJobsData] = useState([]);

  useEffect(() => {
    const filteredJobs = jobsdata.filter((job) => {
      const jobEndDate = new Date(job.application_end_date);
      const today = new Date();
      const threeDaysAgo = new Date(today.setDate(today.getDate() - 3));

      return jobEndDate >= threeDaysAgo;
    });

    setJobsData(filteredJobs);
  }, [jobsdata]);

  return (
    <div className="d-flex flex-wrap" style={{ gap: "20px" }}>
      {jobsdataList && jobsdataList.length > 0 ? (
        jobsdataList?.map((job) => (
          <div
            key={job.id}
            className="w-100 mb-3 p-3 "
            style={{ boxShadow: "0 0 2px 0px grey" }}
          >
            <div className="d-flex align-items-start">
              <div className=" me-4 h-60px w-60px">
                <div className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center bg-primary">
                  {job.image_url ? (
                    <img src={job.image_url} alt={job.name} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 71 71"
                    >
                      <g transform="translate(-457 -443)">
                        <rect
                          width="71"
                          height="71"
                          rx="12"
                          transform="translate(457 443)"
                          fill="#c5c5c5"
                        />
                        <g transform="translate(457 443)">
                          <rect
                            data-name="placeholder"
                            width="71"
                            height="71"
                            rx="12"
                            fill="#2769ee"
                          />
                          <circle
                            data-name="Ellipse 12"
                            cx="18"
                            cy="18"
                            r="18"
                            transform="translate(15 20)"
                            fill="#fff"
                          />
                          <circle
                            data-name="Ellipse 11"
                            cx="11"
                            cy="11"
                            r="11"
                            transform="translate(36 15)"
                            fill="#ffe70c"
                          />
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="mb-2">
                  <a
                    href={`job-view/${job.id}` || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" fw-400  text-dark  mb-1   cursor-pointer"
                    style={{ fontSize: 18 }}
                  >
                    {job.name}
                  </a>
                  <p style={{ fontSize: 14 }} className="text-muted  mb-0">
                    {job.company_name}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex flex-wrap mt-2">
                    {job.job_tag ? (
                      <div
                        className="badge text-success mr-2 mb-2"
                        style={{
                          backgroundColor: "#eefaf7",
                          fontSize: 14,
                          marginRight: "6px",
                          border: 1,
                          borderRight: "grey",
                        }}
                      >
                        {job.job_tag}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="border-right"
                      style={{
                        height: "28px",
                        borderRight: "1px solid #cccccc",
                        margin: "0 5px",
                      }}
                    ></div>

                    {job.category_name ? (
                      <div
                        style={{
                          backgroundColor: "#e9f6ff",
                          fontSize: 14,
                          marginLeft: "5px",
                        }}
                        className="badge text-primary mb-2"
                      >
                        {job.category_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {" "}
                    <Button
                      as={Link}
                      to={`/job-view/${job.id}`}
                      size="sm"
                      variant="warning"
                      // onClick={() =>
                      //   checkAndSendAnalysis({
                      //     job: data.id,
                      //     college_connect_id: 1,
                      //     analysis: "view",
                      //   })
                      // }
                    >
                      View Job
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No Jobs Available</div>
      )}
    </div>
  );
};

export default AvailableJobs;
