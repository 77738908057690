import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
import { logoutonexpire } from "../../../store/actions/AuthActions";
import userprofileplaceholder from "../../../images/college_profile_placeholder2.png";
/// Image
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import axios from "axios"; // Import axios
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const dispatch = useDispatch();
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [collegeData, setCollegeObj] = useState(null);
  const [collegeName, setCollegeName] = useState(null);

  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const Location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState(true);
  // Toggle dropdown visibility
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Hide dropdown when a link is clicked
  const hideDropdown = () => setShowDropdown(false);

  const fetchCollegeData = async () => {
    const collegeProfile = localStorage.getItem("collegeProfile");

    if (collegeProfile) {
      const parsedCollegeProfile = await JSON.parse(collegeProfile);
      setCollegeObj(parsedCollegeProfile);
      setCollegeName(parsedCollegeProfile?.college_name);
      setLoading(false);
      return;
    } else {
      try {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;
        const payload = { college_id: collegeId };

        const response = await axios.get(
          `${API_URL}/api/freshment_portal_apis/College/get_college_profile`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
        );

        const collegeData = response.data.data;
        localStorage.setItem("collegeProfile", JSON.stringify(collegeData));
        setCollegeObj(collegeData);
        setCollegeName(collegeData?.college_name);
      } catch (error) {
        if (error?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.error("Error fetching college profile:", error);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (collegeId && test) {
      fetchCollegeData();
      setTest(false);
    }
  }, [collegeId]);

  // useEffect(() => {
  //   const collegeProfile = localStorage.getItem("collegeProfile");
  // });
  // const fetchcollegeProfile = async () => {
  //   const collegeProfile = localStorage.getItem("collegeProfile");
  //   if (collegeProfile) {
  //     const collegeObj = JSON.parse(collegeProfile);
  //     setCollegeObj(collegeObj);
  //     return;
  //   } else {
  //     const payload1 = { college_id: collegeId };
  //     try {
  //       const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  //       const authToken = userDetails?.data?.auth_token;
  //       console.log(authToken);

  //       const response = await axios.get(
  //         `${API_URL}/api/freshment_portal_apis/College/get_college_profile`,
  //         {
  //           params: payload1,
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         }
  //       );

  //       console.log(response);
  //       const collegeData = response.data.data;

  //       localStorage.setItem("collegeProfile", JSON.stringify(collegeData));
  //       setCollegeObj(collegeData);
  //     } catch (err) {
  //       console.log("Error fetching profile:", err);
  //     }
  //   }
  // };

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);

    var handleheartBlast = document.querySelector(".heart");

    function heartBlast() {
      handleheartBlast.classList.toggle("heart-blast");
    }

    // handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  /// Path
  let path = Location.pathname;
  path = path.split("/");
  // path = path[path.length - 1];

  /// Active menu arrays
  let deshBoard = [
      "",
      "dashboard-dark",
      "search-jobs",
      "applications",
      "my-profile",
      // "statistics",
      "companies",
      "task",
    ],
    job = [
      "job-list",
      "job-view",
      "job-application",
      "apply-job",
      "new-job",
      "students-activity",
    ],
    students = ["invite-student", "students-list", "user-profile"],
    statistics = [
      "statistics",
      "offer-table",
      "internship-table",
      "internship-offer-details",
      "job-offer-details",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  const splitCollegeName = (collegeName) => {
    if (!collegeName) return { firstWord: "", remainingWords: "" }; // Handle case if no name provided

    const words = collegeName.split(" ");
    const firstWord = words[0]; // First word of the name
    const remainingWords = words.slice(1).join(" "); // Remaining words

    return { firstWord, remainingWords };
  };
  if (loading) {
    return <div>Loading...</div>; // Return loading state
  }

  const { firstWord, remainingWords } = splitCollegeName(collegeName);

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown
          as="div"
          className="header-profile2 dropdown"
          show={showDropdown}
          onToggle={toggleDropdown}
        >
          <Dropdown.Toggle
            variant=""
            as="a"
            className="nav-link i-false c-pointer"
            role="button"
            onClick={toggleDropdown}
          >
            <div className="header-info2 d-flex align-items-center">
              <img
                src={
                  collegeData.profile_picture != null &&
                  collegeData.profile_picture != ""
                    ? collegeData.profile_picture
                    : userprofileplaceholder
                }
                width={20}
                alt=""
                style={{ marginRight: 10, objectFit: "cover" }}
              />
              <div
                className="d-flex align-items-center sidebar-info justify-content-between"
                style={{ width: "100%" }}
              >
                <div>
                  <span
                    className="d-block"
                    style={{
                      width: "130px",
                      lineHeight: "1",
                      fontSize: "16px",
                      wordWrap: "break-word",
                      textWrap: "wrap",
                    }}
                  >
                    {/* {collegeName && collegeName != null
                      ? () => {
                          // const { firstWord, remainingWords } =
                          //   splitCollegeName(collegeData?.college_name);
                          return ( */}
                    <>
                      <span
                        style={{ fontSize: "18px", fontWeight: 400 }}
                        className="mb-1"
                      >
                        {firstWord}
                      </span>{" "}
                      <br />
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: 300,
                        }}
                      >
                        {remainingWords}
                      </span>
                    </>
                    {/* );
                        }
                      : collegeData &&
                        Object.keys(collegeData).length > 0 &&
                        (() => {
                          const { firstWord, remainingWords } =
                            splitCollegeName(collegeData?.college_name);
                          return (
                            <>
                              <span
                                style={{ fontSize: "18px", fontWeight: 400 }}
                                className="mb-1"
                              >
                                {firstWord}
                              </span>{" "}
                              <br />
                              <span
                                style={{
                                  fontSize: "10px",
                                  fontWeight: 300,
                                }}
                              >
                                {remainingWords}
                              </span>
                            </>
                          );
                        })()} */}
                  </span>
                </div>
                <i className="fas fa-chevron-down ms-0"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            align="right"
            className="dropdown-menu dropdown-menu-end"
          >
            <Link
              to="/my-profile"
              className="dropdown-item ai-icon"
              onClick={hideDropdown}
            >
              <svg
                id="icon-user1"
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
              <span className="ms-2">Profile</span>
            </Link>
            <Link
              to={`/page-change-password/${collegeId}`}
              className="dropdown-item ai-icon"
              onClick={hideDropdown}
            >
              <svg
                id="icon-lock"
                xmlns="http://www.w3.org/2000/svg"
                className="text-success"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x={3} y={11} width={18} height={11} rx={2} ry={2} />
                <path d="M7 11V7a5 5 0 0 1 10 0v4" />
              </svg>
              <span className="ms-2">Change Password</span>
            </Link>
            <LogoutPage onClick={hideDropdown} />
          </Dropdown.Menu>
        </Dropdown>

        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "dashboard" ? "mm-active" : ""}`}
                  to="/dashboard"
                >
                  {" "}
                  Dashboard
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "my-profile" ? "mm-active" : ""}`}
                  to="/my-profile"
                >
                  {" "}
                  My Profile
                </Link>
              </li>
              {/* <li>
              <Link
                className={`${path === "statistics" ? "mm-active" : ""}`}
                to="/statistics"
              >
                Statistics
              </Link>
            </li> */}
              {/* <li><Link className={`${path === "companies" ? "mm-active" : ""}`} to="/companies">Companies</Link></li>
            <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li> */}
            </ul>
          </li>
          <li className={`${job.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-093-waving"></i>
              <span className="nav-text">Jobs</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "job-list" ? "mm-active" : ""}`}
                  to="/job-list"
                >
                  Job Lists
                </Link>
              </li>
              {/* <li>
              <Link
                className={`${path === "job-view" ? "mm-active" : ""}`}
                to="/job-view"
              >
                Job View
              </Link>
            </li> */}
              <li>
                <Link
                  className={`${path === "job-application" ? "mm-active" : ""}`}
                  to="/students-activity"
                >
                  Students Activity
                </Link>
              </li>
              {/* <li><Link className={`${path === "apply-job" ? "mm-active" : ""}`} to="/apply-job">Apply Job</Link></li>
        <li><Link className={`${path === "new-job" ? "mm-active" : ""}`} to="/new-job">New Job</Link></li> */}
            </ul>
          </li>

          <li className={`${students.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-039-goal"></i>
              <span className="nav-text">Students</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "students-list" ? "mm-active" : ""}`}
                  to="/students-list"
                >
                  Students List
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "invite-student" ? "mm-active" : ""}`}
                  to="/invite-student"
                >
                  Invite Student
                </Link>
              </li>
              {/* <li>
                <Link
                  className={`${path === "user-profile" ? "mm-active" : ""}`}
                  to="/user-profile"
                >
                  User Profile
                </Link>
              </li> */}
            </ul>
          </li>

          <li className={`${statistics.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-014-checkmark"></i>
              <span className="nav-text">Placement</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "offer-table" ? "mm-active" : ""}`}
                  to="/offer-table"
                >
                  Offers List
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "internship-table" ? "mm-active" : ""
                  }`}
                  to="/internship-table"
                >
                  Internship List
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "statistics" ? "mm-active" : ""}`}
                  to="/statistics"
                >
                  Statistics
                </Link>
              </li>
            </ul>
          </li>
        </MM>
        {/* <div className="plus-box">
        <p className="fs-14 font-w600 mb-2">
          Let Jobick Managed
          <br />
          Your Resume Easily
          <br />
        </p>
        <p>Lorem ipsum dolor sit amet</p>
      </div> */}
        <div className="copyright">
          <p>
            <strong>Freshment</strong> © 2024 Freshment. All rights reserved.
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by Freshment
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
