// actions/courseActions.js
import axios from "axios";
import { API_URL } from "../../config";
import { logoutonexpire } from "./AuthActions";
export const fetchCourses = (payload) => async (dispatch) => {
  const userDetails =
    JSON.parse(localStorage.getItem("userDetails")) ||
    JSON.parse(localStorage.getItem("TempUserDetails"));
  const authToken = userDetails?.data?.auth_token;
  try {
    const response = await axios.get(
      `${API_URL}/api/freshment_portal_apis/Dashboard/get_dashboard`,
      {
        params: payload,
        headers: {
          Authorization: authToken,
        },
      }
    );
    const courses = response?.data?.data?.courses;
    const category = response?.data?.data?.job_categories;
    // console.log("Fetched job categories:", category); // Verify data

    dispatch(setCourses(courses));
    dispatch(setJobCategory(category));
    localStorage.setItem("courses", JSON.stringify(courses));
    localStorage.setItem("job_category", JSON.stringify(category)); // Correct spelling
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(logoutonexpire());
    }
    console.error("Error fetching courses:", error);
  }
};

export const setCourses = (courses) => ({
  type: "SET_COURSES",
  payload: courses,
});

export const setJobCategory = (category) => ({
  type: "SET_JOB_CATEGORY",
  payload: category,
});
