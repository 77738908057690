import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import "../table/FilteringTable/filtering.css";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
const InternshipTable = () => {
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [internships, setInternships] = useState([]); // To store the fetched data
  const [currentPage, setCurrentPage] = useState(0); // To track the current page
  const [itemsPerPage] = useState(10); // Items per page
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [modalShow, setModalShow] = useState(false);
  const [packageValue, setPackageValue] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [position, setPosition] = useState("");
  const [acceptance, setAcceptance] = useState(1);
  const [dateOffered, setDateOffered] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [jobid, setjobId] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const [isEditing, setEditing] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  // const [studentId, setStudentId] = useState(""); // New state for student_id
  const dispatch = useDispatch();
  //search job and student
  const [searchJobQuery, setSearchJobQuery] = useState("");
  const [searchStudentQuery, setSearchStudentQuery] = useState("");
  const [searchjobslist, setSearchjobslist] = useState([]);
  const [searchstudentlist, setSearchstudentlist] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [educationdetails, setEducationDetails] = useState([]);
  const [isotherJobRole, setisOtherJobRole] = useState(false);
  const [otherJobRole, setOtherJobRole] = useState(null);
  const [position_id, setPositionId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  // const [studentId, setStudentId] = useState(null);

  // Fetch all data from API
  const fetchInternships = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${API_URL}/api/freshment_portal_apis/GenericAPI/get_internship_offer_list`,
        {
          params: {
            college_id: collegeId,
            offset: currentPage * itemsPerPage,
            limit: itemsPerPage,
          },
          headers: {
            Authorization: authToken,
          },
        }
      );
      const { data, totalRecords } = response.data;
      setInternships(data);
      setTotalRecords(totalRecords);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logout());
      }
      console.error("Error fetching internships:", error);
      setInternships([]); // Set empty array on error to prevent issues
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchInternships();
  // }, []);

  const handleEdit = async (id, stud_id) => {
    const payload = { offer_id: id };
    // console.log(id, payload);
    const studId = { student_id: stud_id };
    setOfferId(id);
    // Reset states before loading new data
    setSelectedCompany(null); // Reset selected company
    setSearchQuery(""); // Reset search query or other necessary states
    setSearchJobQuery("");
    setSearchStudentQuery("");
    // Set modal visibility and editing state
    setModalShow(true);
    setEditing(true);
    // console.log(studId);
    try {
      // Send a request to the API to get job offer details
      const params = new URLSearchParams();
      params.append("offer_id", payload.offer_id);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      const response = await axios.post(
        `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_internship_offer_detail`,
        params,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      const offer = response.data.data;

      // Update state with offer details
      // setSearchQuery(offer.company_name);
      setPackageValue(offer.package);
      setCompanyId(offer.company_id);
      setPosition(offer.position);
      setPositionId(offer.job_role_id);
      setAcceptance(offer.acceptance);
      setDateOffered(offer.date_offered);
      setJoiningDate(offer.joining_date);
      setStudentEmail(offer.email);
      setCompanyName(offer.company_name);
      setCompanyAddress(offer.company_address || ""); // Use empty string if company_address is null
      setCompanyEmail(offer.company_email);
      setjobId(offer.job_id);
      // console.log(offer.full_name, studId.student_id);

      // Fetch company options based on company name
      const fetchCompaniesData = async (
        company_id,
        company_name,
        company_address,
        company_email
      ) => {
        const options = [
          { value: null, label: "Enter company name" },
          {
            value: company_id,
            label: company_name,
            company_address: company_address,
            company_email: company_email,
            company_id: company_id,
          },
        ];

        setCompanyOptions(options);

        // Set selected company if it matches the offer's company ID
        const selectoption = options.find(
          (option) => option.company_id === company_id
        );

        // Ensure that selected company is only set once options are available
        setSelectedCompany(selectoption); // Ensure null if no match
      };

      const fetchJobsData = async (position, position_id) => {
        // Initialize options as an array
        const options = [
          {
            value: null,
            label: "Add Other Job Role",
          },
          {
            value: position_id,
            label: position,
          },
        ];

        // Set company options
        setJobOptions(options);
        // console.log(options, position_id);

        const selectOption = options.find(
          (option) => option.value === position_id
        );
        // console.log(selectOption);

        setSelectedJob(selectOption); // Ensure null if no match
      };

      const fetStudentsData = async (Student, email, id) => {
        const options = [
          {
            value: null,
            label: "Search Student",
          },
          {
            value: email,
            label: Student,
            student_id: id,
            // education_details: student.education_details,
          },
        ];

        setStudentOptions(options);
        const selectoption = options.find((option) => option.student_id === id);
        setSelectedStudent(selectoption); // Ensure null if no match
      };
      await fetStudentsData(offer.full_name, offer.email, studId.student_id);
      await fetchJobsData(offer.position, offer.job_role_id);
      await fetchCompaniesData(
        offer.company_id,
        offer.company_name,
        offer.company_address,
        offer.company_email
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logout());
      }
      console.error("Error fetching job offer details:", error);
    }
  };

  // Handle pagination click
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleAddOffer = async () => {
    const offerData = {
      package: packageValue,
      company_id: companyId,
      position_id,
      acceptance,
      date_offered: dateOffered,
      joining_date: joiningDate,
      student_id: studentId,
      other_company_name: companyName,
      other_company_email: companyEmail,
      other_company_address: companyAddress,
      job_id: jobid,
      offer_id: offerId,
      other_position: otherJobRole,
    };

    if (offerData.date_offered == null || offerData.date_offered == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter date offered.",
      });
      return;
    }

    // if (
    //   offerData.position == null ||
    //   offerData.position == undefined ||
    //   offerData.position == "" ||
    //   typeof offerData.position == "number"
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Please enter valid position.",
    //   });
    //   return;
    // }

    const params = new URLSearchParams();
    if (isEditing) {
      // params.append("college_id", 1);
      params.append("package", offerData.package);
      params.append("job_id", offerData.job_id);
      if (offerData.other_position != null && offerData.position_id == null) {
        params.append("other_position", offerData.other_position);
      } else {
        params.append("job_role_id", offerData.position_id);
      }
      params.append("acceptance", offerData.acceptance);
      params.append("date_offered", offerData.date_offered);
      params.append("joining_date", offerData.joining_date);
      params.append("offer_id", offerData.offer_id);
      // params.append("student_email", offerData.student_email);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      // console.log(params);
      await axios
        .post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/update_internship_offer`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Internship Updated!",
              text: "The job placement Internship has been Updated successfully.",
            });
            resetFormFields();
            setModalShow(false);
            setEditing(false);
            fetchInternships();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                response.data.message ||
                "There was an error Updating the Internship. Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "There was an error Updating the Internship. Please try again.",
          });
        });
    } else {
      params.append("college_id", collegeId);
      params.append("package", offerData.package);
      if (offerData.position_id == null) {
        params.append("other_position", offerData.other_position);
      } else {
        params.append("job_role_id", offerData.position_id);
      }
      params.append("acceptance", offerData.acceptance);
      params.append("date_offered", offerData.date_offered);
      params.append("joining_date", offerData.joining_date);
      params.append("job_id", offerData.job_id);

      params.append("student_id", offerData.student_id);
      if (
        offerData.company_id == null ||
        offerData.company_id == undefined ||
        offerData.company_id == ""
      ) {
        params.append("other_company_name", offerData.other_company_name);
        params.append("other_company_email", offerData.other_company_email);
        params.append("other_company_address", offerData.other_company_address);
      } else {
        params.append("company_id", offerData.company_id);
      }
      // console.log(params);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/save_internship_offer`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Offer Added!",
              text: "The job placement offer has been added successfully.",
            });
            resetFormFields();
            setModalShow(false);
            fetchInternships();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                response.data.message ||
                "There was an error adding the offer. Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "There was an error adding the offer. Please try again.",
          });
        });
    }
  };

  const resetFormFields = () => {
    setPackageValue("");
    setCompanyName("");
    setCompanyAddress("");
    setCompanyEmail("");
    setPosition("");
    setAcceptance(1);
    setDateOffered("");
    setJoiningDate("");
    setStudentEmail("");
    setSelectedCompany(null);
    setCompanyId(null);
    setOfferId(null);
    setjobId(null);
    setSearchQuery("");
    setSearchJobQuery("");
    setSearchStudentQuery("");
    setSelectedJob(null);
    setSelectedStudent(null);
    setisOtherJobRole(false);
    setOtherJobRole(null);
    setPositionId(null);
    setOfferId(null);
    setStudentId(null);
    setStudentEmail("");
    setSelectedJob(null);
    setSelectedCompany(null);
    setCompanyId(null);
    setjobId(null);
    setSelectedStudentId(null);
    setJobOptions([{ value: null, label: "Add Other Job Role" }]);
    setCompanyOptions([{ value: null, label: "Enter company name" }]);
    setStudentOptions([{ value: null, label: "Search Student" }]);
  };

  // useEffect(() => {
  //   console.log(selectedCompany);
  // }, [selectedCompany]);

  useEffect(() => {
    // console.log(searchQuery.length);
    if (searchQuery != "" && searchQuery.length >= 2) {
      const fetchCompanies = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/GenericAPI/get_company_list`,
            {
              params: { search: searchQuery },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const options = data.map((company) => ({
            value: company.company_id,
            label: company.company_name,
            company_address: company.company_address,
            company_email: company.company_email,
          }));
          options.unshift({
            value: null,
            label: "Enter company name",
          });
          setCompanyOptions(options);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          console.error("Error fetching companies:", error);
        }
      };
      fetchCompanies();
    } else if (
      searchQuery == "" ||
      searchQuery == null ||
      searchQuery.length < 2
    ) {
      const options = [
        {
          value: null,
          label: "Enter company name",
        },
      ];
      setCompanyOptions(options);
    }
  }, [searchQuery]);

  // Delete Internship Handler with Confirmation
  const handleDelete = async (props) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = new URLSearchParams();
        params.append("offer_id", props.internship_offer_id);
        // console.log(props.internship_offer_id);
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.post(
            `${API_URL}/api/freshment_portal_apis/PlaceOffer/delete_internship_offer`,
            params, // Pass the encoded params
            {
              headers: {
                Authorization: authToken,
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
            }
          );
          // console.log(response);
          if (response.data.success) {
            Swal.fire(
              "Deleted!",
              "Your internship has been deleted.",
              "success"
            );
            // Refresh the internships list after deletion
            const updatedInternships = internships.filter(
              (internship) =>
                internship?.internship_offer_id !== props.internship_offer_id
            );
            setInternships(updatedInternships);
          } else {
            Swal.fire(
              "Error!",
              response.data.message || "Failed to delete internship.",
              "error"
            );
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          console.error("Error deleting internship:", error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the internship.",
            "error"
          );
        }
      }
    });
  };

  useEffect(() => {
    // console.log(searchJobQuery.length);
    if (searchJobQuery != "" && searchJobQuery.length >= 2) {
      const fetchJobslistDropdown = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/students/search_job_roles`,
            {
              params: { search: searchJobQuery, college_id: collegeId },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const optionsjob = data.map((job) => ({
            value: job.id,
            label: job.job_role_name,
          }));
          optionsjob.unshift({
            value: null,
            label: "Add Other Job Role",
          });
          setJobOptions(optionsjob);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          console.error("Error fetching jobs:", error);
        }
      };
      fetchJobslistDropdown();
    } else if (
      searchJobQuery == "" ||
      searchJobQuery == null ||
      searchJobQuery.length < 2
    ) {
      const optionsjob = [
        {
          value: null,
          label: "Select Job Role",
        },
      ];
      setJobOptions(optionsjob);
    }
  }, [searchJobQuery]);

  useEffect(() => {
    // console.log(searchStudentQuery.length);
    if (searchStudentQuery != "" && searchStudentQuery.length >= 2) {
      const fetchStudentListDropdown = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/students/search_student_list`,
            {
              params: { search: searchStudentQuery, college_id: collegeId },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const optionsStudent = data.map((student) => ({
            value: student.email,
            label: student.full_name,
            student_id: student.onboarding_student_id,
            education_details: student.education_details,
          }));
          optionsStudent.unshift({
            value: null,
            label: "Search Student",
          });
          setStudentOptions(optionsStudent);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          console.error("Error fetching students:", error);
        }
      };
      fetchStudentListDropdown();
    } else if (
      searchStudentQuery == "" ||
      searchStudentQuery == null ||
      searchStudentQuery.length < 2
    ) {
      const optionsStudent = [
        {
          value: null,
          label: "Search Student",
        },
      ];
      setStudentOptions(optionsStudent);
    }
  }, [searchStudentQuery]);

  // Calculate the total number of pages

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const indexOfLastInternship = (currentPage + 1) * itemsPerPage;
  const indexOfFirstInternship = indexOfLastInternship - itemsPerPage;

  useEffect(() => {
    fetchInternships();
  }, [currentPage]);

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(0, currentPage - Math.floor(itemsPerPage / 2));
    const endPage = Math.min(totalPages - 1, startPage + itemsPerPage - 1);

    if (startPage > 0)
      pages.push(
        <span key="start-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Link
          key={i}
          to="#"
          className={`paginate_button ${
            i === currentPage ? "bg-white border" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Link>
      );
    }

    if (endPage < totalPages - 1)
      pages.push(
        <span key="end-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    return pages;
  };
  return (
    <>
      <Fragment>
        <PageTitle
          activeMenu="Internship List"
          motherMenu="Placement"
          pageContent="Internship List"
        />
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Internship List</h4>
              <Link
                onClick={() => {
                  setEditing(false);
                  resetFormFields();
                  setModalShow(true);
                }}
                to="#"
                className="btn btn-primary"
              >
                Add New Internship
              </Link>
            </div>
            <div className="card-body">
              <div
                className="table-responsive dataTables_wrapper"
                id="application-data"
              >
                {/* Loading indicator */}
                {isLoading ? (
                  <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <table
                    className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
                    id="example5"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">Id</th>
                        <th className="sorting_asc">Student Name</th>
                        <th className="sorting_asc">Position</th>
                        <th className="sorting_asc">Package</th>
                        <th className="sorting_asc">Joining Date</th>
                        <th className="sorting_asc">Company Name</th>
                        <th className="sorting_asc text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {internships && internships.length > 0 ? (
                        internships.map((internship, index) => (
                          <tr key={internship.internship_offer_id} role="row">
                            <td>{index + 1 + currentPage * itemsPerPage}</td>{" "}
                            {/* Adjust index for pagination */}
                            <td>{internship.full_name}</td>
                            <td>{internship.position}</td>
                            <td>{internship.package}</td>
                            <td>
                              {new Date(
                                internship.joining_date
                              ).toLocaleDateString()}
                            </td>
                            <td>{internship.company_name}</td>
                            <td
                              className="action-btn d-flex align-items-center wspace-no"
                              style={{ gap: 5 }}
                            >
                              <Button
                                onClick={() =>
                                  handleEdit(
                                    internship.internship_offer_id,
                                    internship.onboarding_student_id
                                  )
                                }
                                size="sm"
                                variant="primary"
                              >
                                Edit
                              </Button>
                              <Button
                                as={Link}
                                to={`/internship-offer-details/${internship.internship_offer_id}`}
                                size="sm"
                                variant="info"
                              >
                                View
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() =>
                                  handleDelete({
                                    internship_offer_id:
                                      internship.internship_offer_id,
                                  })
                                }
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No internships found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}

                {/* Pagination */}

                <div className="d-sm-flex text-center justify-content-between align-items-center">
                  <div className="dataTables_info" id="example5_info">
                    Showing {indexOfFirstInternship + 1} to{" "}
                    {Math.min(indexOfLastInternship, totalRecords)} of{" "}
                    {totalRecords} entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Link
                      className={`paginate_button previous ${
                        currentPage === 0 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (currentPage > 0) handlePageChange(currentPage - 1);
                      }}
                    >
                      Previous
                    </Link>{" "}
                    <span>
                      {renderPageNumbers()}
                      {/* {[...Array(totalPages)].map((_, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={`paginate_button ${
                            i === currentPage ? "bg-white border" : ""
                          }`}
                          onClick={() => handlePageChange(i)}
                        >
                          {i + 1}
                        </Link>
                      ))} */}
                    </span>
                    <Link
                      to="#"
                      className={`paginate_button next ${
                        currentPage === totalPages - 1 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (currentPage < totalPages - 1)
                          handlePageChange(currentPage + 1);
                      }}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Adding New Internship */}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop="static"
          size="lg"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              {isEditing ? "Edit Internship Offer" : "Add Internship Offer"}
            </Modal.Title>
            <Button
              onClick={() => setModalShow(false)}
              variant=""
              className="btn-close"
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formCompanySearch" className="mb-3">
                <Form.Label>Search Company</Form.Label>
                <Select
                  options={companyOptions}
                  isDisabled={isEditing ? true : false}
                  value={selectedCompany}
                  onChange={(option) => {
                    if (
                      option.label === "Enter company name" ||
                      option.value === null ||
                      option.value === undefined ||
                      option.value === ""
                    ) {
                      setSelectedCompany(null);
                      setCompanyName("");
                      setCompanyAddress("");
                      setCompanyEmail("");
                      setCompanyId(null);
                    } else {
                      setSelectedCompany(option);
                      setCompanyName(option.label);
                      setCompanyAddress(option.company_address);
                      setCompanyEmail(option.company_email);
                      setCompanyId(option.value);
                    }
                  }}
                  onInputChange={(inputValue) => setSearchQuery(inputValue)}
                  placeholder="Enter company name"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#cccccc", // Custom border color
                      boxShadow: "none",
                      "&:focus": {
                        borderColor: "#005dba", // Darker border on hover
                      },
                      "&:hover": {
                        borderColor: "#cccccc", // Darker border on hover
                      },
                      padding: "5px",
                      borderRadius: "15px", // Add some padding for better aesthetics
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: "#333",
                      padding: 10,
                      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                      "&:active": {
                        backgroundColor: "#e0e0e0", // Background color when option is clicked
                      },
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#999", // Placeholder color
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensure dropdown appears above other elements
                    }),
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  disabled={selectedCompany != null}
                  // className="disabled:bg-primary disabled:text-white disabled:cursor-not-allowed disabled:"
                />
              </Form.Group>
              <Form.Group controlId="formCompanyEmail" className="mb-3">
                <Form.Label>HR Email</Form.Label>
                <Form.Control
                  type="text"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  disabled={selectedCompany != null}
                />
              </Form.Group>

              <Form.Group controlId="formCompanyAddress" className="mb-3">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  type="text"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  disabled={selectedCompany != null}
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="formjobId" className="mb-3">
                  <Form.Label>Job ID ( optional )</Form.Label>
                  <Form.Control
                    type="number"
                    value={jobid}
                    disabled={isEditing === true ? true : false}
                    onChange={(e) => setjobId(e.target.value)}
                    required
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Group>
                {/* <Form.Group
                  as={Col}
                  controlId="formStudentEmail"
                  className="mb-3"
                >
                  <Form.Label>Student Email</Form.Label>
                  <Form.Control
                    type="email"
                    disabled={isEditing === true ? true : false}
                    value={studentEmail}
                    onChange={(e) => setStudentEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formPosition" className="mb-3">
                  <Form.Label>Select Job Role</Form.Label>
                  <Form.Control
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </Form.Group> */}

                <Form.Group controlId="formStudentSearch" className="mb-3">
                  <Form.Label>Search Student ( Name / Email )</Form.Label>
                  <Select
                    options={studentOptions}
                    value={selectedStudent}
                    isDisabled={isEditing === true ? true : false}
                    onChange={(option) => {
                      // console.log(option);
                      if (
                        option.label === "Search Student" ||
                        option.value === null ||
                        option.value === undefined ||
                        option.value === ""
                      ) {
                        setSelectedStudent(null);
                        setStudentId(null);
                        setStudentEmail("");
                        setEducationDetails("");
                      } else {
                        setSelectedStudent(option);
                        setStudentEmail(option.value);
                        setStudentId(option.student_id);
                        setEducationDetails(option.education_details);
                      }
                    }}
                    onInputChange={(inputValue) =>
                      setSearchStudentQuery(inputValue)
                    }
                    placeholder="Search Student"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: "#cccccc", // Custom border color
                        boxShadow: "none",
                        "&:focus": {
                          borderColor: "#005dba", // Darker border on hover
                        },
                        "&:hover": {
                          borderColor: "#cccccc", // Darker border on hover
                        },
                        padding: "5px",
                        borderRadius: "15px", // Add some padding for better aesthetics
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: "#333",
                        padding: 10,
                        backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                        "&:active": {
                          backgroundColor: "#e0e0e0", // Background color when option is clicked
                        },
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#999", // Placeholder color
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Ensure dropdown appears above other elements
                      }),
                    }}
                  />
                </Form.Group>

                {isEditing == false &&
                  selectedStudent &&
                  selectedStudent !== null && (
                    <div
                      className="card"
                      style={{
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        boxShadow: "none",
                      }}
                    >
                      <div className="card-body" style={{ padding: "20px" }}>
                        <p
                          className="d-flex align-items-center w-100"
                          style={{ gap: 10 }}
                        >
                          <span style={{ fontWeight: "bold", width: "110px" }}>
                            Student Name
                          </span>
                          <span style={{ fontWeight: "bold", width: "10px" }}>
                            :
                          </span>
                          {selectedStudent.label}
                        </p>
                        <p
                          className="d-flex align-items-center w-100"
                          style={{ gap: 10 }}
                        >
                          <span style={{ fontWeight: "bold", width: "110px" }}>
                            Student Email
                          </span>
                          <span style={{ fontWeight: "bold", width: "10px" }}>
                            :
                          </span>
                          {selectedStudent.value}
                        </p>
                        <p
                          className="d-flex mb-0 align-items-center w-100"
                          style={{ gap: 10 }}
                        >
                          <span style={{ fontWeight: "bold", width: "110px" }}>
                            Education
                          </span>
                          <span style={{ fontWeight: "bold", width: "10px" }}>
                            :
                          </span>
                          {selectedStudent.education_details}
                        </p>
                      </div>
                    </div>
                  )}
                {isEditing && selectedStudent && selectedStudent !== null && (
                  <div
                    className="card"
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    }}
                  >
                    <div className="card-body" style={{ padding: "20px" }}>
                      <p
                        className="d-flex align-items-center w-100"
                        style={{ gap: 10 }}
                      >
                        <span style={{ fontWeight: "bold", width: "110px" }}>
                          Student Name
                        </span>
                        <span style={{ fontWeight: "bold", width: "10px" }}>
                          :
                        </span>
                        {selectedStudent.label}
                      </p>
                      <p
                        className="d-flex mb-0 align-items-center w-100"
                        style={{ gap: 10 }}
                      >
                        <span style={{ fontWeight: "bold", width: "110px" }}>
                          Student Email
                        </span>
                        <span style={{ fontWeight: "bold", width: "10px" }}>
                          :
                        </span>
                        {selectedStudent.value}
                      </p>
                    </div>
                  </div>
                )}
                <Form.Group controlId="formJobSearch" className="mb-3">
                  <Form.Label>Select Job Role</Form.Label>
                  <Select
                    options={jobOptions}
                    value={selectedJob}
                    // isDisabled={isEditing === true ? true : false}
                    onChange={(option) => {
                      if (
                        option.label == "Add Other Job Role" ||
                        option.value == "Add Other Job Role"
                      ) {
                        setSelectedJob(option);
                        setPosition(null);
                        setPositionId(null);
                        setisOtherJobRole(true);
                      } else if (
                        option.label === "Select Job Role" ||
                        option.value === null ||
                        option.value === undefined ||
                        option.value === ""
                      ) {
                        setisOtherJobRole(false);
                        setSelectedJob(null);
                      } else {
                        setisOtherJobRole(false);
                        setSelectedJob(option);
                        setPosition(option.label);
                        setPositionId(option.value);
                      }
                    }}
                    onInputChange={(inputValue) =>
                      setSearchJobQuery(inputValue)
                    }
                    placeholder="Select Job Role"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: "#cccccc", // Custom border color
                        boxShadow: "none",
                        "&:focus": {
                          borderColor: "#005dba", // Darker border on hover
                        },
                        "&:hover": {
                          borderColor: "#cccccc", // Darker border on hover
                        },
                        padding: "5px",
                        borderRadius: "15px", // Add some padding for better aesthetics
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: "#333",
                        padding: 10,
                        backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                        "&:active": {
                          backgroundColor: "#e0e0e0", // Background color when option is clicked
                        },
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#999", // Placeholder color
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Ensure dropdown appears above other elements
                      }),
                    }}
                  />
                </Form.Group>
                {isotherJobRole && isotherJobRole !== false && (
                  <Form.Group controlId="formPosition" className="mb-3">
                    <Form.Label>Other Job Role</Form.Label>
                    <Form.Control
                      type="text"
                      value={otherJobRole}
                      onChange={(e) => setOtherJobRole(e.target.value)}
                    />
                  </Form.Group>
                )}

                <Form.Group as={Col} controlId="formPackage" className="mb-3">
                  <Form.Label>Package</Form.Label>
                  <Form.Control
                    type="number"
                    value={packageValue}
                    onChange={(e) => setPackageValue(e.target.value)}
                    required
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formOfferAcceptance" className="mb-3">
                <Form.Label>Offer Acceptance</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Accepted"
                    value="1"
                    name="acceptance"
                    onChange={() => setAcceptance(1)}
                    checked={acceptance == 1}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Rejected"
                    value="0"
                    name="acceptance"
                    onChange={() => setAcceptance(0)}
                    checked={acceptance == 0}
                  />
                </div>
              </Form.Group>

              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="formDateOffered"
                  className="mb-3"
                >
                  <Form.Label>Offer Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={dateOffered}
                    onChange={(e) => setDateOffered(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  controlId="formJoiningDate"
                  className="mb-3"
                >
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={joiningDate}
                    onChange={(e) => setJoiningDate(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={() => setModalShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleAddOffer()}>
              {isEditing ? "Update Internship" : "Save Internship"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    </>
  );
};

export default InternshipTable;
