import axios from "axios";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import Swal from "sweetalert2";
import { API_URL } from "../../config";
import { clearCollegeId } from "./CollegeidActions";
import { setCollegeId } from "./CollegeidActions";
import { useDispatch } from "react-redux";
import { fetchCourses } from "./CourseAction";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const TWO_FACTOR_AUTH_TOGGLE_ACTION = "[2fa action] toggle 2fa";
export const TWO_FACTOR_AUTH_CONFIRMED = "[2fa action] confirmed 2fa";
export const TWO_FACTOR_AUTH_FAILED = "[2fa action] failed 2fa";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  return (dispatch) => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("TempUserDetails");
    localStorage.removeItem("collegeProfile");
    localStorage.removeItem("courses");
    localStorage.removeItem("job_category");

    dispatch(clearCollegeId());
    history?.push("/login");
    dispatch({
      type: LOGOUT_ACTION,
    });
  };
}

export function logoutonexpire(history) {
  return (dispatch) => {
    Swal.fire({
      title: "Auth Session Expired",
      text: "Your authentication session has expired. HTTP 401 Error.",
      icon: "warning",
      buttons: "OK",
    }).then((willLogout) => {
      if (willLogout) {
        dispatch(logout(history));
      } else {
        dispatch(logout(history));
      }
    });
  };
}

// export function loginAction(email, password, history) {
//   return (dispatch) => {
//     fetch(
//       "${API_URL}/api/freshment_portal_apis/College/login",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//         },
//         body: new URLSearchParams({
//           college_email: email,
//           college_password: password,
//           ip_address: "192.168.1.1",
//           device_info: "Mozilla/5.0 (Windows NT 10.0; Win64; x64)",
//           location: "Mumbai, India",
//         }),
//       }
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         console.log(result);
//         if (result.success) {
//           // alert("You r logged in");
//           console.log(result);
//           saveTokenInLocalStorage(result);
//           runLogoutTimer(
//             dispatch,
//             result.expiresIn ? result.expiresIn : 3600 * 1000,
//             history
//           );
//           dispatch(loginConfirmedAction(result));
//           history.push("/dashboard");
//           // this.goToMain();
//         } else {
//           alert("Please check your login information.");
//         }
//       });
//     // login(email, password)
//     // // .then((response) => response.json())
//     //     .then((response) => {
//     //         saveTokenInLocalStorage(response.data);
//     //         runLogoutTimer(
//     //             dispatch,
//     //             response.data.expiresIn * 1000,
//     //             history,
//     //         );
//     //         dispatch(loginConfirmedAction(response.data));
//     //         // console.log(response);
//     // 		history.push('/dashboard');
//     //     })
//     //     .catch((error) => {
//     // 		//console.log(error);
//     //         const errorMessage = formatError(error.response.data);
//     //         dispatch(loginFailedAction(errorMessage));
//     //     });
//   };
// }

export function TwoFactorAuthDerive(
  response,
  history,
  ipAddress,
  deviceInfo,
  location
) {
  const { is_two_factor_required, is_two_factor_enabled, college_id } =
    response.data;
  // console.log(response.data.auth_token);
  const authToken = response.data.auth_token;
  const params = new URLSearchParams();
  params.append("college_connect_id", college_id);
  params.append("ip_address", ipAddress);
  params.append("device_info", deviceInfo);
  params.append("location", location);
  // params.append("is_trusted", 0);
  Swal.fire({
    title: "Processing...",
    text: "Please wait while we process your authentication",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  return (dispatch) => {
    if (is_two_factor_required && is_two_factor_enabled) {
      Swal.close();
      dispatch(setCollegeId(college_id));
      // const payload = {
      //   college_id: college_id,
      // };
      localStorage.setItem("TempUserDetails", JSON.stringify(response));
      history.push("/two-factor-auth");
    } else if (is_two_factor_required == false && is_two_factor_enabled) {
      Swal.close();
      saveTokenInLocalStorage(response);
      dispatch(setCollegeId(college_id));
      const payload = {
        college_id: college_id,
      };
      dispatch(fetchCourses(payload));
      runLogoutTimer(
        dispatch,
        response.expiresIn ? response.expiresIn * 1000 : 3600 * 1000,
        history
      );
      dispatch(loginConfirmedAction(response));
      history.push("/dashboard");
      // window.location.reload();
      localStorage.removeItem("TempUserDetails");
    } else if (
      is_two_factor_required == false &&
      is_two_factor_enabled == false
    ) {
      saveTokenInLocalStorage(response);
      Swal.close();
      const payload = {
        college_id: college_id,
      };
      dispatch(fetchCourses(payload));
      dispatch(setCollegeId(college_id));
      runLogoutTimer(
        dispatch,
        response.expiresIn ? response.expiresIn * 1000 : 3600 * 1000,
        history
      );
      dispatch(loginConfirmedAction(response));
      history.push("/dashboard");
      // window.location.reload();
      localStorage.removeItem("TempUserDetails");
    }
  };
}

// export function twoFactorAuthAction(otp, history) {}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  // console.log(data);
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function twoFactorAuthToggleAction(data) {
  return {
    type: TWO_FACTOR_AUTH_TOGGLE_ACTION,
    payload: data,
  };
}
