import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Nav, Tab } from "react-bootstrap";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { API_URL } from "../../../../../config";
import { useDispatch } from "react-redux";
import { logoutonexpire } from "../../../../../store/actions/AuthActions";
import { useMemo } from "react";
//Images According to category id in card
import pic1 from "./../../../../../images/comapny/icon_engineering.png";
import pic2 from "./../../../../../images/comapny/icon_banking.png";
import pic3 from "./../../../../../images/comapny/icon_gov.png";
import pic4 from "./../../../../../images/comapny/icon_teaching.png";
import pic5 from "./../../../../../images/comapny/icon_technology.png";
import pic6 from "./../../../../../images/comapny/icon_railway.png";
import pic7 from "./../../../../../images/comapny/icon_defence.png";
import pic8 from "./../../../../../images/comapny/icon_gov.png";
import pic9 from "./../../../../../images/comapny/icon_acc.png";
import pic10 from "./../../../../../images/comapny/icon_gpsc.png";
import pic11 from "./../../../../../images/comapny/icon_police.png";
import pic12 from "./../../../../../images/comapny/icon_upsc.png";
import pic13 from "./../../../../../images/comapny/icon_hr.png";
import pic14 from "./../../../../../images/comapny/icon_sales.png";
import pic15 from "./../../../../../images/comapny/icon_marketing.png";
import pic16 from "./../../../../../images/comapny/icon_finance.png";
import pic17 from "./../../../../../images/comapny/icon_operations.png";
import pic18 from "./../../../../../images/comapny/icon_customer_service.png";
import pic19 from "./../../../../../images/comapny/icon_embedded.png";
import pic20 from "./../../../../../images/comapny/icon_ai_ml.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { set } from "date-fns";

const JobCardBlog = [
  { image: pic1, title: "UI Designer", subtitle: "Bubbles Studios" },
  { image: pic2, title: "Programmer", subtitle: "Kleon Studios" },
  { image: pic3, title: "UX Researcher", subtitle: "Bubbles Studios" },
  { image: pic4, title: "Data Scientist", subtitle: "Kleon Studios" },
  { image: pic5, title: "UX Researcher", subtitle: "Bubbles Studios" },
  { image: pic6, title: "Animator", subtitle: "Kleon Studios" },
  { image: pic1, title: "Programmer", subtitle: "Bubbles Studios" },
  { image: pic2, title: "Lead Design Rese..", subtitle: "Kleon Studios" },
  { image: pic3, title: "UI Designer", subtitle: "Bubbles Studios" },
  { image: pic4, title: "UX Researcher", subtitle: "Kleon Studios" },
  { image: pic5, title: "Lead Design", subtitle: "Bubbles Studios" },
  { image: pic6, title: "Data Scientist", subtitle: "Kleon Studios" },
];

const ListCardBlog = [
  { image: pic1, title: "UI Designer", subtitle: "Bubbles Studios" },
  { image: pic2, title: "Programmer", subtitle: "Kleon Studios" },
  { image: pic3, title: "UX Researcher", subtitle: "Bubbles Studios" },
  { image: pic4, title: "Data Scientist", subtitle: "Kleon Studios" },
  { image: pic5, title: "UX Researcher", subtitle: "Bubbles Studios" },
  { image: pic6, title: "Animator", subtitle: "Kleon Studios" },
];

const JobsTabBlog = (props) => {
  const [jobList, setJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [totalRecord, setTotalRecord] = useState(0);
  const [jobsPerPage] = useState(10);
  const [allJobs, setAllJobs] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [job_id, setJobid] = useState(null);
  const [college_connect_id, setCollegeConnectId] = useState(collegeId);
  const [remark, setRemark] = useState("");
  const [Loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   console.log(props);
  // }, [props]);
  const sendReport = (props) => {
    const params = new URLSearchParams();
    params.append("job_id", props.job_id);
    params.append("college_connect_id", props.college_connect_id);
    params.append("remark", props.remark);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    if (props.remark.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a remark",
      });
      return;
    }

    if (props.remark.length < 20) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Remark must be at least 20 characters long.",
      });
      return;
    }
    axios
      .post(`${API_URL}/api/freshment_portal_apis/Jobs/report_job`, params, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((res) => {
        // console.log(res);
        setModalShow(false);
        setRemark("");
        Swal.fire({
          icon: "success",
          title: "Report Sent!",
          text: "The job has been Reported successfully.",
        }).then(() => {
          fetchJobs();
        });
      })
      .catch((err) => {
        // console.log(err);
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err,
        });
      });
  };

  const filterParams = useMemo(() => {
    const params = {
      filter_by_course: false,
      filter_by_job_type: false,
      filter_by_passout_year: false,
      filter_by_category: false,
      search: false,
    };

    if (
      props.course !== "Choose Course" &&
      props.courseId !== null &&
      props.courseId !== ""
    ) {
      params.filter_by_course = parseInt(props.courseId);
    }

    if (
      props.category !== 0 &&
      props.category !== "" &&
      props.category !== null
    ) {
      params.filter_by_category = parseInt(props.category);
    }

    if (props.type !== "Job Type" && props.type !== "") {
      const typeMap = {
        "Campus Invite": 2,
        Internship: 1,
      };
      params.filter_by_job_type = typeMap[props.type];
    }

    if (props.batch !== "Passout Batch" && props.batch !== "") {
      params.filter_by_passout_year = parseInt(props.batch);
    }

    if (props.search !== "") {
      params.search = props.search;
    }
    setCurrentPage(0);
    return params;
  }, [
    props.course,
    props.type,
    props.batch,
    props.courseId,
    props.category,
    props.search,
  ]);

  useEffect(() => {
    fetchJobs();
  }, [filterParams, currentPage]);

  // useEffect(() => {
  //   console.log(filterParams);
  // }, [filterParams]);

  const fetchJobs = async () => {
    setLoading(true);
    // console.log(filterParams);
    const params = {
      offset: currentPage * jobsPerPage,
      limit: jobsPerPage,
    };

    if (filterParams.filter_by_course !== false) {
      params.filter_by_course = filterParams.filter_by_course;
    }
    if (filterParams.filter_by_job_type !== false) {
      params.filter_by_job_type = filterParams.filter_by_job_type;
    }
    if (
      filterParams.filter_by_passout_year !== false &&
      typeof filterParams.filter_by_passout_year === "number"
    ) {
      params.filter_by_passout_year = filterParams.filter_by_passout_year;
    }

    if (filterParams.filter_by_category !== false) {
      params.filter_by_category = filterParams.filter_by_category;
    }

    if (filterParams.search !== false) {
      params.search = filterParams.search;
    }

    // console.log(params);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    await axios
      .get(`${API_URL}/api/freshment_portal_apis/jobs/get_job_list`, {
        params: params,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        const { data, totalRecords } = response.data;
        // console.log(response);
        setJobData(data); // Store all jobs
        // setJobData(response.data.data.slice(0, jobsPerPage)); // Show the first page jobs
        setTotalRecord(totalRecords);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      })
      .finally(() => setLoading(false));
  };

  // Logic for displaying current students
  const totalPages = Math.ceil(totalRecord / jobsPerPage);
  const indexOfLastStudent = (currentPage + 1) * jobsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - jobsPerPage;

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // useEffect(() => {
  //   console.log(jobList);
  // }, [jobList]);

  const [selectblog, setSelectblog] = useState("Newest");

  const categoryImages = {
    1: pic1,
    2: pic2,
    3: pic3,
    4: pic4,
    5: pic5,
    6: pic6,
    7: pic7,
    8: pic8,
    9: pic9,
    10: pic10,
    11: pic11,
    12: pic12,
    13: pic13,
    14: pic14,
    15: pic15,
    16: pic16,
    17: pic17,
    18: pic18,
    19: pic19,
    20: pic20,
  };

  const showImage = (categoryId) => {
    const image = categoryImages[categoryId];
    return image;
  };

  const handleReport = (id, clgid) => {
    if (!id) {
      console.error("Job ID cannot be blank");
      return;
    }
    setModalShow(true);
    setJobid(parseInt(id));
    // setRemark("");
    setCollegeConnectId(clgid ? clgid : collegeId);
  };

  const checkAndSendAnalysis = (props) => {
    // Retrieve data from localStorage instead of sessionStorage
    const localStorageData = JSON.parse(localStorage.getItem("analyse")) || [];

    const existingAnalysis = localStorageData.find(
      (item) =>
        item.job_id === props.job &&
        item.college_id === props.college_connect_id &&
        item.analysis_type === props.analysis
    );

    if (existingAnalysis) {
      // If the job exists and status is 1, don't call the API
      if (existingAnalysis.status === 1) {
        console.log("Analysis already done for this job.");
        return;
      }
    } else {
      // If the job doesn't exist in localStorage, add it with status 1 and call the API
      const newAnalysis = {
        job_id: props.job,
        college_id: props.college_connect_id,
        analysis_type: props.analysis,
        status: 1, // Default status
      };
      localStorageData.push(newAnalysis);
      // Store the updated data in localStorage
      localStorage.setItem("analyse", JSON.stringify(localStorageData));
      callApiToSendAnalysis(props);
    }
  };

  // Extracted function to call the API
  const callApiToSendAnalysis = (props) => {
    const params = new URLSearchParams();
    params.append("job_id", props.job);
    params.append("college_id", props.college_connect_id); // Replace with appropriate college_id
    params.append("analysis_type", props.analysis);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/jobs/add_job_analysis`,
        params,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // console.log("API response:", res);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logoutonexpire());
        }
        console.log("err======", err);
      });
  };

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(0, currentPage - Math.floor(jobsPerPage / 2));
    const endPage = Math.min(totalPages - 1, startPage + jobsPerPage - 1);

    if (startPage > 0)
      pages.push(
        <span key="start-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item  ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    if (endPage < totalPages - 1)
      pages.push(
        <span key="end-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    return pages;
  };

  return (
    <>
      {Loading ? (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : jobList && jobList.length > 0 ? (
        <Tab.Container defaultActiveKey="Boxed">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="mb-4">
              <h5>
                Showing {indexOfFirstStudent + 1} to{" "}
                {Math.min(indexOfLastStudent, totalRecord)} of {totalRecord}{" "}
              </h5>
              <span>Based your preferences</span>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="Boxed">
              <div className="row">
                {jobList &&
                  jobList.length > 0 &&
                  jobList.map((data, index) => (
                    <div
                      className="col-xl-6 col-xxl-6 col-md-12 col-sm-12"
                      key={index}
                    >
                      <div className="card">
                        <div className="jobs2 card-body text-start">
                          <div style={{ height: 230 }}>
                            <div
                              style={{ height: "40%" }}
                              className="d-flex mb-3 text-start align-items-center"
                            >
                              <div
                                style={{ height: 60, width: 60 }}
                                className="me-3 h-60px w-60px"
                              >
                                <img
                                  src={showImage(data.job_category_id)}
                                  alt="Icon"
                                  className="h-60px w-60px"
                                  style={{ height: 60, width: 60 }}
                                />
                              </div>
                              <div className="d-block text-start">
                                <h5
                                  className="fs-20 mb-2"
                                  style={{ fontWeight: 400 }}
                                >
                                  {data.name || "N/A"}
                                </h5>
                              </div>
                            </div>
                            <div style={{ height: "30%" }}>
                              <span className="mb-3  d-block">
                                Post Name :{" "}
                                <span style={{ fontWeight: 500 }}>
                                  {data.post_name || "N/A"}
                                </span>
                              </span>
                              <span className="mb-3 d-block">
                                Comapany :{" "}
                                <span style={{ fontWeight: 500 }}>
                                  {" "}
                                  {data.company_name || "N/A"}
                                </span>
                              </span>
                            </div>
                            <div
                              className="d-flex mb-3 flex-wrap mt-2 justify-content-between"
                              style={{ alignItems: "center", height: "10%" }}
                            >
                              <div className="d-flex flex-wrap">
                                {data.job_tag ? (
                                  <div
                                    className="badge text-success mr-2 d-flex"
                                    style={{
                                      backgroundColor: "#eefaf7",
                                      fontSize: 14,
                                      marginRight: "6px",
                                      border: 1,
                                      borderRight: "grey",
                                      gap: 5,
                                    }}
                                  >
                                    <img
                                      loading="lazy"
                                      src="https://freshmentapp.com/static/media/suitcase.23cf515a907a203377353bfe17e9a4b7.svg"
                                      alt="Logo"
                                      width="20"
                                    />
                                    {data.job_tag}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data.category_name ? (
                                  <div
                                    style={{
                                      border: "1px solid orange ",
                                      fontSize: 14,
                                      marginLeft: "5px",
                                    }}
                                    className="badge text-warning"
                                  >
                                    {data.category_name}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                <span
                                  style={{
                                    backgroundColor: "#f1c40f",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    color: "#fff",
                                  }}
                                  className="font-weight-bold d-block"
                                >
                                  Deadline :{" "}
                                  {moment(data.application_end_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-12"
                              style={{
                                width: "100%",
                                backgroundColor: "#cccccc",
                                height: "1px",
                              }}
                            ></div>
                          </div>
                          <div
                            className="d-flex mb-1 mt-4 justify-content-between"
                            style={{ gap: 10 }}
                          >
                            <div
                              className="d-flex"
                              style={{ gap: 10, alignItems: "center" }}
                            >
                              <span
                                className="d-flex align-items-center"
                                style={{ gap: 5, fontSize: 16 }}
                              >
                                <img
                                  loading="lazy"
                                  src="https://freshmentapp.com/static/media/currencyRupee.2dd2454993ef1f021d1600ac8f1647be.svg"
                                  alt="Logo"
                                  className="mt-[-6px] w-[30px] h-[30px] sm:w-[26px] sm:h-[26px] "
                                />

                                {data?.salary && Number(data.salary) >= 100000
                                  ? `${(Number(data.salary) / 100000).toFixed(
                                      Number(data.salary) % 100000 === 0 ? 0 : 1
                                    )} LPA`
                                  : "Industry Standard"}
                              </span>{" "}
                              <span
                                className="d-flex align-items-center"
                                style={{ fontSize: 16, gap: 5 }}
                              >
                                <i
                                  className="fas fa-map-marker-alt me-2 text-primary"
                                  style={{ fontSize: 23, marginTop: "-2px" }}
                                ></i>
                                {data.job_location}
                              </span>
                            </div>
                            <div className="d-flex" style={{ gap: 5 }}>
                              <Button
                                as={Link}
                                to={`/job-view/${data.id}`}
                                size="sm"
                                variant="warning"
                                onClick={() =>
                                  checkAndSendAnalysis({
                                    job: data.id,
                                    college_connect_id: collegeId,
                                    analysis: "view",
                                  })
                                }
                              >
                                View Job
                              </Button>
                              <Button
                                size="sm"
                                onClick={() =>
                                  handleReport(data.id, data.college_connect_id)
                                }
                                variant="danger"
                              >
                                Report Job
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-end mt-4">
                {" "}
                {/* Add this wrapper for pagination */}
                <nav>
                  <ul className="pagination pagination-circle">
                    {/* Previous Button */}
                    <li
                      className={`page-item page-indicator job-search-page ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          currentPage > 0 && handlePageChange(currentPage - 1)
                        }
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {renderPageNumbers()}
                    {/* Page Numbers */}
                    {/* {[...Array(totalPages)].map((_, i) => (
                      <li
                        key={i}
                        className={`page-item  ${
                          i === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(i)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))} */}

                    {/* Next Button */}
                    <li
                      className={`ms-2 page-item page-indicator job-search-page ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          currentPage + 1 < totalPages &&
                          handlePageChange(currentPage + 1)
                        }
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="List1">
              <div className="row">
                <div className="col-xl-12">
                  {ListCardBlog.map((item, index) => (
                    <div
                      className="d-flex flex-wrap search-row bg-white p-3 mb-3 rounded justify-content-between align-items-center"
                      key={index}
                    >
                      <div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
                        <img src={item.image} alt="" className="me-3" />
                        <div>
                          <h2 className="title">
                            <Link to={"#"} className="text-black">
                              {item.title}
                            </Link>
                          </h2>
                          <span className="text-primary">{item.subtitle}</span>
                        </div>
                      </div>
                      <div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
                        <svg
                          className="me-3 ml-lg-0 ml-sm-auto ms-0 mt-sm-0 mt-3"
                          width="54"
                          height="54"
                          viewBox="0 0 54 54"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="54"
                            height="54"
                            rx="15"
                            fill="#2BC155"
                          ></rect>
                          <g clipPath="url(#clip6)">
                            <path
                              d="M27.0001 19.84C23.5987 19.84 20.6536 22.2024 19.9157 25.5229L17.4992 36.397C17.4815 36.4768 17.4725 36.5583 17.4725 36.64C17.4725 37.2585 17.974 37.76 18.5925 37.76H35.4077C35.4894 37.76 35.5709 37.751 35.6507 37.7333C36.2545 37.5991 36.6352 37.0008 36.501 36.397L34.0846 25.5229C33.3467 22.2024 30.4016 19.84 27.0001 19.84ZM27.0001 17.6C31.4515 17.6 35.3056 20.6916 36.2712 25.037L38.6877 35.9111C39.0902 37.7226 37.9481 39.5174 36.1366 39.92C35.8973 39.9731 35.6529 40 35.4077 40H18.5925C16.7369 40 15.2325 38.4956 15.2325 36.64C15.2325 36.3948 15.2594 36.1504 15.3126 35.9111L17.729 25.037C18.6947 20.6916 22.5488 17.6 27.0001 17.6Z"
                              fill="white"
                            ></path>
                            <path
                              d="M29.2402 24.32C29.8588 24.32 30.3602 24.8214 30.3602 25.44C30.3602 26.0585 29.8588 26.56 29.2402 26.56H26.4402C26.1309 26.56 25.8802 26.8107 25.8802 27.12C25.8802 27.4292 26.1309 27.68 26.4402 27.68H27.5602C29.1066 27.68 30.3602 28.9336 30.3602 30.48C30.3602 32.0264 29.1066 33.28 27.5602 33.28H24.7602C24.1416 33.28 23.6402 32.7785 23.6402 32.16C23.6402 31.5414 24.1416 31.04 24.7602 31.04H27.5602C27.8695 31.04 28.1202 30.7892 28.1202 30.48C28.1202 30.1707 27.8695 29.92 27.5602 29.92H26.4402C24.8938 29.92 23.6402 28.6664 23.6402 27.12C23.6402 25.5736 24.8938 24.32 26.4402 24.32H29.2402Z"
                              fill="white"
                            ></path>
                            <path
                              d="M25.8802 23.2C25.8802 22.5814 26.3817 22.08 27.0002 22.08C27.6188 22.08 28.1203 22.5814 28.1203 23.2V25.44C28.1203 26.0586 27.6188 26.56 27.0002 26.56C26.3817 26.56 25.8802 26.0586 25.8802 25.44V23.2Z"
                              fill="white"
                            ></path>
                            <path
                              d="M28.1203 34.4C28.1203 35.0186 27.6188 35.52 27.0002 35.52C26.3817 35.52 25.8802 35.0186 25.8802 34.4V32.16C25.8802 31.5414 26.3817 31.04 27.0002 31.04C27.6188 31.04 28.1203 31.5414 28.1203 32.16V34.4Z"
                              fill="white"
                            ></path>
                            <path
                              d="M25.8001 18.304C26.0298 18.8784 25.7504 19.5302 25.1761 19.7599C24.6018 19.9896 23.95 19.7103 23.7203 19.136L21.4803 13.536C21.1163 12.626 22.0141 11.7204 22.9272 12.0766C23.7659 12.4037 24.391 12.56 24.7602 12.56C24.8521 12.56 24.9283 12.5404 25.0946 12.4697C25.1387 12.4509 25.1906 12.428 25.3122 12.3742C25.8915 12.1203 26.3491 12 27.0002 12C27.6497 12 28.1146 12.1206 28.6957 12.3721C28.8432 12.4366 28.9021 12.4623 28.9542 12.4838C29.0978 12.5429 29.1669 12.56 29.2402 12.56C29.5878 12.56 30.2185 12.4023 31.0812 12.0735C31.9932 11.7258 32.8825 12.6298 32.5201 13.536L30.2801 19.136C30.0503 19.7103 29.3985 19.9896 28.8242 19.7599C28.2499 19.5302 27.9705 18.8784 28.2003 18.304L29.6096 14.7807C29.4808 14.7936 29.3578 14.8 29.2402 14.8C28.8314 14.8 28.4927 14.7162 28.1013 14.5551C28.0241 14.5232 27.9394 14.4863 27.8064 14.4279C27.4822 14.2877 27.2985 14.24 27.0002 14.24C26.7048 14.24 26.5313 14.2856 26.2114 14.4258C26.1015 14.4745 26.0319 14.5052 25.9706 14.5313C25.5512 14.7095 25.2002 14.8 24.7602 14.8C24.6419 14.8 24.5189 14.7939 24.3911 14.7816L25.8001 18.304Z"
                              fill="white"
                            ></path>
                          </g>
                          <defs>
                            <clipPath>
                              <rect
                                width="28"
                                height="28"
                                fill="white"
                                transform="translate(13 12)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                        <div>
                          <h4 className="sub-title text-black">
                            $ 2,000 - $ 2,500
                          </h4>
                          <span>Monthly Salary</span>
                        </div>
                      </div>
                      <div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
                        <svg
                          className="me-3"
                          width="54"
                          height="54"
                          viewBox="0 0 54 54"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="54"
                            height="54"
                            rx="15"
                            fill="#FBA556"
                          ></rect>
                          <path
                            d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z"
                            fill="white"
                          ></path>
                        </svg>
                        <div>
                          <h4 className="sub-title text-black">
                            Manchester, England
                          </h4>
                          <span>Location</span>
                        </div>
                      </div>
                      <div className="col-xl-2 col-xxl-3 text-xl-right text-lg-left text-sm-right col-lg-12 col-sm-6 mt-xl-0 mt-3">
                        <Link
                          to={"#"}
                          className="btn btn-sm btn-outline-primary rounded me-2"
                        >
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <div>No Jobs Found</div>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Report Job
          </Modal.Title>
          <Button
            onClick={() => setModalShow(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form.Control
            as={"textarea"}
            rows={3}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className=""
            id="exampleFormControlTextarea1"
            placeholder="Write here..."
            style={{ height: "100px", border: "1px solid #cccccc" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)} variant="danger light">
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              sendReport({
                job_id,
                college_connect_id,
                remark,
              })
            }
          >
            Report Job
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default JobsTabBlog;
