import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Image } from "react-bootstrap";
import img from "../../../images/customers/Frame2.jpg";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Modal } from "react-bootstrap";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { logoutonexpire } from "../../../store/actions/AuthActions";
import { useSelector } from "react-redux";
import userprofileplaceholder from "../../../images/user_profile_placeholder.png";
const UserProfile = () => {
  const dispatch = useDispatch();
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [studentList, setStudentData] = useState({});
  const [experience, setExperience] = useState("");
  const { studentId } = useParams();
  const payload = { user_id: studentId };
  const [modalShow, setModalShow] = useState(false);
  const [offerType, setOfferType] = useState(0);
  const [packageValue, setPackageValue] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [position, setPosition] = useState("");
  const [acceptance, setAcceptance] = useState(1);
  const [dateOffered, setDateOffered] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [jobid, setjobId] = useState(null);
  const [errorMsg, setErrorMsg] = useState("No Student Data Found");
  const [Loading, setLoading] = useState(false);
  const [src, setSrc] = useState(studentList.user_image);
  const [studId, setStudId] = useState(null);
  //search job and student
  const [searchJobQuery, setSearchJobQuery] = useState("");
  const [searchStudentQuery, setSearchStudentQuery] = useState("");
  const [searchjobslist, setSearchjobslist] = useState([]);
  const [searchstudentlist, setSearchstudentlist] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  // const [studentId, setStudentId] = useState(null);
  const [educationdetails, setEducationDetails] = useState([]);
  const [isotherJobRole, setisOtherJobRole] = useState(false);
  const [otherJobRole, setOtherJobRole] = useState(null);
  const [position_id, setPositionId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  // const [studentId, setStudentId] = useState(null);

  useEffect(() => {
    setSrc(studentList.user_image);
  }, [studentList]);

  const handleError = () => {
    setSrc(userprofileplaceholder); // Replace with your default image path
  };
  const handleAddOffer = () => {
    const offerData = {
      // offer_type: offerType,
      package: packageValue,
      company_id: companyId,
      position_id,
      acceptance,
      date_offered: dateOffered,
      joining_date: joiningDate,
      student_id: studentId,
      other_company_name: companyName,
      other_company_email: companyEmail,
      other_company_address: companyAddress,
      job_id: jobid,
      other_position: otherJobRole,
    };

    if (offerData.date_offered == null || offerData.date_offered == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter date offered.",
      });
      return;
    }

    // if (
    //   offerData.position == null ||
    //   offerData.position == undefined ||
    //   offerData.position == "" ||
    //   typeof offerData.position == "number"
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Please enter valid position.",
    //   });
    //   return;
    // }

    const params = new URLSearchParams();
    params.append("college_id", collegeId);

    params.append("package", offerData.package);
    // params.append("position", offerData.position);
    if (offerData.other_position != null && offerData.position_id == null) {
      params.append("other_position", offerData.other_position);
    } else {
      params.append("job_role_id", offerData.position_id);
    }
    params.append("acceptance", offerData.acceptance);
    params.append("date_offered", offerData.date_offered);
    params.append("joining_date", offerData.joining_date);
    params.append("student_id", offerData.student_id);
    params.append("job_id", offerData.job_id);

    if (
      offerData.company_id == null ||
      offerData.company_id == undefined ||
      offerData.company_id == ""
    ) {
      params.append("other_company_name", offerData.other_company_name);
      params.append("other_company_email", offerData.other_company_email);
      params.append("other_company_address", offerData.other_company_address);
    } else {
      params.append("company_id", offerData.company_id);
    }

    // console.log(params);
    if (offerType === 0) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/save_job_placement_offer`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Offer Added!",
              text: "The job offer has been added successfully.",
            });
            resetFormFields();
            fetchStudentList();
            setModalShow(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                response.data.message ||
                "There was an error adding the offer. Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "There was an error adding the offer. Please try again.",
          });
          setModalShow(false);
        });
    } else if (offerType === 1) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/save_internship_offer`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Offer Added!",
              text: "The Internship offer has been added successfully.",
            });
            resetFormFields();
            fetchStudentList();
            setModalShow(false);
            // fetchOffers();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                response.data.message ||
                "There was an error adding the offer. Please try again.",
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "There was an error adding the offer. Please try again.",
          });
          setModalShow(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer type.",
      });
      return;
    }
  };

  useEffect(() => {
    // console.log(searchQuery.length);
    if (searchQuery != "" && searchQuery.length >= 2) {
      const fetchCompanies = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/GenericAPI/get_company_list`,
            {
              params: { search: searchQuery },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const options = data.map((company) => ({
            value: company.company_id,
            label: company.company_name,
            company_address: company.company_address,
            company_email: company.company_email,
          }));
          options.unshift({
            value: null,
            label: "Enter company name",
          });
          setCompanyOptions(options);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          console.error("Error fetching companies:", error);
        }
      };
      fetchCompanies();
    } else if (
      searchQuery == "" ||
      searchQuery == null ||
      searchQuery.length < 2
    ) {
      const options = [
        {
          value: null,
          label: "Enter company name",
        },
      ];
      setCompanyOptions(options);
    }
  }, [searchQuery]);

  const resetFormFields = () => {
    setPackageValue("");
    setCompanyName("");
    setCompanyAddress("");
    setCompanyEmail("");
    setPosition("");
    setAcceptance(1);
    setDateOffered("");
    setJoiningDate("");
    setStudentEmail("");
    setSelectedCompany(null);
    setCompanyId(null);
    setjobId(null);
    setSearchQuery("");
    setSearchJobQuery("");
    setSearchStudentQuery("");
    setSelectedJob(null);
    setSelectedStudent(null);
    setisOtherJobRole(false);
    setOtherJobRole(null);
    setPositionId(null);
    // setOfferId(null);
    // setStudentId(null);
    setStudentEmail("");
    setSelectedJob(null);
    setSelectedCompany(null);
    setCompanyId(null);
    setjobId(null);
    setSelectedStudentId(null);
    setJobOptions([{ value: null, label: "Add Other Job Role" }]);
    setCompanyOptions([{ value: null, label: "Enter company name" }]);
    setStudentOptions([{ value: null, label: "Search Student" }]);
  };

  const fetchStudentList = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/freshment_portal_apis/Students/get_user_profile`,
        {
          params: payload,
          headers: {
            Authorization: authToken,
          },
        }
      );

      const studentData = response.data?.data;
      setStudentData(studentData);

      setSelectedStudent({
        value: studentData.email,
        label: studentData.full_name,
      });
      setStudentEmail(studentData.email); // Set email from selected student

      // console.log(response.data);
      if (studentData?.joining_date) {
        setExperience(moment(studentData?.joining_date).fromNow());
      }
      setStudentEmail(studentData?.email);
      setErrorMsg(response.data?.message);
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logoutonexpire());
      }
      // console.log("Error fetching profile:", err);
      setErrorMsg(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(searchJobQuery.length);
    if (searchJobQuery != "" && searchJobQuery.length >= 2) {
      const fetchJobslistDropdown = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/students/search_job_roles`,
            {
              params: { search: searchJobQuery, college_id: collegeId },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const optionsjob = data.map((job) => ({
            value: job.id,
            label: job.job_role_name,
          }));
          optionsjob.unshift({
            value: null,
            label: "Add Other Job Role",
          });
          setJobOptions(optionsjob);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          console.error("Error fetching jobs:", error);
        }
      };
      fetchJobslistDropdown();
    } else if (
      searchJobQuery == "" ||
      searchJobQuery == null ||
      searchJobQuery.length < 2
    ) {
      const optionsjob = [
        {
          value: null,
          label: "Add Other Job Role",
        },
      ];
      setJobOptions(optionsjob);
    }
  }, [searchJobQuery]);

  useEffect(() => {
    // console.log(searchStudentQuery.length);
    if (searchStudentQuery != "" && searchStudentQuery.length >= 2) {
      const fetchStudentListDropdown = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const authToken = userDetails?.data?.auth_token;

        try {
          const response = await axios.get(
            `${API_URL}/api/freshment_portal_apis/students/search_student_list`,
            {
              params: { search: searchStudentQuery, college_id: collegeId },
              headers: {
                Authorization: authToken,
              },
            }
          );
          const data = response.data.data;
          // console.log(data);
          const optionsStudent = data.map((student) => ({
            value: student.email,
            label: student.full_name,
          }));
          optionsStudent.unshift({
            value: null,
            label: "Search Student",
          });
          setStudentOptions(optionsStudent);
        } catch (error) {
          if (error?.response?.status === 401) {
            dispatch(logoutonexpire());
          }
          console.error("Error fetching students:", error);
        }
      };
      fetchStudentListDropdown();
    } else if (
      searchStudentQuery == "" ||
      searchStudentQuery == null ||
      searchStudentQuery.length < 2
    ) {
      const optionsStudent = [
        {
          value: null,
          label: "Search Student",
        },
      ];
      setStudentOptions(optionsStudent);
    }
  }, [searchStudentQuery]);

  useEffect(() => {
    fetchStudentList();
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-4">
          <h4 className="fs-20 font-w600 mb-0 me-auto">User Profile</h4>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              {Loading ? (
                <div className="text-center my-4">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : studentList ? (
                <>
                  <div className="card-header border-0 flex-wrap align-items-start">
                    <div className="col-md-12">
                      <div className="user d-sm-flex align-items-center d-block pe-0 justify-content-between">
                        {/* Profile Photo */}
                        <div className="user d-sm-flex align-items-center d-block pe-0">
                          <Image
                            src={
                              studentList.user_img != null
                                ? src
                                : userprofileplaceholder
                            }
                            onError={handleError}
                            alt="User"
                          />
                          <div className="ms-sm-3  ms-0 me-md-5 md-0">
                            {/* Name, Email, Phone Number */}
                            <h5 className="mb-2 font-w600 fs-18">
                              <Link to={"#"}>
                                {studentList.full_name || "N/A"}
                              </Link>
                            </h5>
                            <div className="listline-wrapper mb-2 fs-16">
                              <span className="item me-15">
                                <i className="far fa-envelope"></i>
                                {studentList.email || "N/A"}
                              </span>
                              <span className="item">
                                <i className="fas fa-phone-alt"></i>
                                {studentList.phone_number ||
                                  "Phone Unavailable"}
                              </span>
                              <span
                                className="item"
                                // Set dynamic text color based on the status
                                style={{
                                  color:
                                    studentList.onboarding_status ===
                                    "Completed"
                                      ? "green"
                                      : studentList.onboarding_status ===
                                        "InProgress"
                                      ? "#ffa755"
                                      : "grey",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="35"
                                  height="35"
                                  // Dynamically set the fill color based on the status
                                  fill={
                                    studentList.onboarding_status ===
                                    "Completed"
                                      ? "green"
                                      : studentList.onboarding_status ===
                                        "InProgress"
                                      ? "#ffa755"
                                      : "grey"
                                  }
                                  viewBox="0 0 30 30"
                                >
                                  <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21.707,12.707l-7.56,7.56 c-0.188,0.188-0.442,0.293-0.707,0.293s-0.52-0.105-0.707-0.293l-3.453-3.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l2.746,2.746l6.853-6.853c0.391-0.391,1.023-0.391,1.414,0S22.098,12.316,21.707,12.707z"></path>
                                </svg>
                                {/* Display the onboarding status */}
                                {studentList.onboarding_status ||
                                  "On Boarding status Unavailable"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ float: "right" }}
                        >
                          {/* Download Resume */}
                          <a
                            href={studentList.resume_url || "#"}
                            download={!!studentList.resume_url} // Using !! to convert to boolean
                            className={`btn btn-md me-2 mb-2 ${
                              studentList.resume_url ? "" : "disabled"
                            }`}
                            style={
                              studentList.resume_url
                                ? { background: "#005dba", color: "white" }
                                : {
                                    background: "grey",
                                    cursor: "not-allowed",
                                    color: "white",
                                  }
                            }
                            aria-disabled={!studentList.resume_url} // This is for accessibility purposes
                          >
                            <i className="fas fa-download me-2"></i>Download
                            Resume
                          </a>

                          {/* Email Button */}
                          <a
                            href={`/application-details/student/${studentList.onboarding_student_id}`}
                            className="btn btn-warning btn-md me-2 mb-2"
                          >
                            <i className="far fa-star me-2"></i>View Activity
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="border-bottom mb-4">
                      <h4 className="fs-20">Description</h4>

                      <div className="row">
                        <div className="col-xl-6 col-md-6">
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">Full Name : </span>
                            <span className="font-w400">
                              {studentList.full_name}
                            </span>
                          </p>
                          {/* Specialisation and Branch */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Specialisation :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.specialization_name || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">Branch : </span>
                            <span className="font-w400">
                              {studentList.course_name || "N/A"}
                            </span>
                          </p>
                          {/* Passout Year */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Passout Year :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.passout_year || "N/A"}
                            </span>
                          </p>
                        </div>
                        <div className="col-xl-6 col-md-6">
                          {/* Total Job Offers */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Total Job Offers :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.total_job_offers || 0}
                            </span>
                          </p>
                          {/* Total Internship Offers */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Total Internship Offers :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.total_intern_offers || 0}
                            </span>
                          </p>
                          {/* Onboarding Status */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Onboarding Status :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.onboarding_status || "N/A"}
                            </span>
                          </p>
                          {/* App Installation Status */}
                          <p className="font-w600 mb- d-flex">
                            <span className="custom-label-2">
                              Freshment App Installed :{" "}
                            </span>
                            <span className="font-w400">
                              {studentList.app_installation_status === "1"
                                ? "Yes"
                                : "No"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        {/* Job Offers */}{" "}
                        <div className="row">
                          <div className="col-9">
                            {studentList.job_offers &&
                            studentList.job_offers.length > 0 ? (
                              <>
                                <h5 className="font-w600 fs-20 mb-3">
                                  Job Offers
                                </h5>
                                <div className="w-100 mb-4">
                                  {studentList.job_offers?.map(
                                    (offer, index) => (
                                      <p className="font-w400 mb-2" key={index}>
                                        <strong>Job Offer {index + 1}:</strong>{" "}
                                        Received Offer from{" "}
                                        <strong>{offer?.company_name}</strong>{" "}
                                        for Position{" "}
                                        <strong>{offer.position}</strong> on{" "}
                                        <strong>
                                          {moment(offer?.date_offered).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </strong>{" "}
                                        - Joining Date{" "}
                                        <strong>
                                          {moment(offer?.joining_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </strong>{" "}
                                        -{" "}
                                        <strong>
                                          {offer.acceptance === "1"
                                            ? "Accepted"
                                            : "Rejected"}
                                        </strong>
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}

                            {/* Internship Offers */}
                            {studentList.intern_offers &&
                            studentList.intern_offers.length > 0 ? (
                              <>
                                <h5 className="font-w600 fs-20 mb-3">
                                  Internship Offers
                                </h5>
                                <div className="w-100 mb-3">
                                  {studentList.intern_offers?.map(
                                    (offer, index) => (
                                      <p className="font-w400 mb-2" key={index}>
                                        <strong>
                                          Internship Offer {index + 1}:
                                        </strong>{" "}
                                        Received Offer from{" "}
                                        <strong>{offer.company_name}</strong>{" "}
                                        for Position{" "}
                                        <strong>{offer.position}</strong> on{" "}
                                        <strong>
                                          {moment(offer.date_offered).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </strong>{" "}
                                        - Joining Date{" "}
                                        <strong>
                                          {moment(offer?.joining_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </strong>{" "}
                                        -{" "}
                                        <strong>
                                          {offer.acceptance == 1
                                            ? "Accepted"
                                            : "Rejected"}
                                        </strong>
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-3 mb-3">
                            <Button
                              onClick={() => setModalShow(true)}
                              className="btn btn-primary"
                              size="sm"
                              style={{ marginLeft: "10px" }}
                            >
                              Add Job / Internship Offer
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="card-body pt-0">
                    <p className="text-center mt-5 fs-18">{errorMsg}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add Job / Internship Offer</Modal.Title>
          <Button
            onClick={() => setModalShow(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formOfferType" className="mb-3">
              <Form.Label>Offer Type</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Job Offer"
                  value="Job Offer"
                  name="offer_type"
                  onChange={() => setOfferType(0)}
                  checked={offerType === 0}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Internship Offer"
                  value="Internship Offer"
                  name="offer_type"
                  onChange={() => setOfferType(1)}
                  checked={offerType === 1}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formCompanySearch" className="mb-3">
              <Form.Label>Search Company</Form.Label>
              <Select
                options={companyOptions}
                value={selectedCompany}
                onChange={(option) => {
                  if (
                    option.label === "Enter company name" ||
                    option.value === null ||
                    option.value === undefined ||
                    option.value === ""
                  ) {
                    setSelectedCompany(null);
                    setCompanyName("");
                    setCompanyAddress("");
                    setCompanyEmail("");
                    setCompanyId(null);
                  } else {
                    setSelectedCompany(option);
                    setCompanyName(option.label);
                    setCompanyAddress(option.company_address);
                    setCompanyEmail(option.company_email);
                    setCompanyId(option.value);
                  }
                }}
                onInputChange={(inputValue) => setSearchQuery(inputValue)}
                placeholder="Enter company name"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: "#cccccc", // Custom border color
                    boxShadow: "none",
                    "&:focus": {
                      borderColor: "#005dba", // Darker border on hover
                    },
                    "&:hover": {
                      borderColor: "#cccccc", // Darker border on hover
                    },
                    padding: "5px",
                    borderRadius: "15px", // Add some padding for better aesthetics
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: "#333",
                    padding: 10,
                    backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                    "&:active": {
                      backgroundColor: "#e0e0e0", // Background color when option is clicked
                    },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#999", // Placeholder color
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999, // Ensure dropdown appears above other elements
                  }),
                }}
              />
            </Form.Group>

            <Form.Group controlId="formCompanyName" className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                disabled={selectedCompany != null}
                // className="disabled:bg-primary disabled:text-white disabled:cursor-not-allowed disabled:"
              />
            </Form.Group>
            <Form.Group controlId="formCompanyEmail" className="mb-3">
              <Form.Label>HR Email</Form.Label>
              <Form.Control
                type="text"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
                disabled={selectedCompany != null}
              />
            </Form.Group>

            <Form.Group controlId="formCompanyAddress" className="mb-3">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                type="text"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                disabled={selectedCompany != null}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formjobId" className="mb-3">
                <Form.Label>Job ID ( optional )</Form.Label>
                <Form.Control
                  type="number"
                  value={jobid}
                  onChange={(e) => setjobId(e.target.value)}
                  required
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Group>

              <Form.Group controlId="formStudentSearch" className="mb-3">
                <Form.Label>Search Student ( Name / Email )</Form.Label>
                <Select
                  options={studentOptions}
                  isDisabled
                  value={selectedStudent}
                  onChange={(option) => {
                    // console.log(option);
                    if (
                      option.label === "Search Student" ||
                      option.value === null ||
                      option.value === undefined ||
                      option.value === ""
                    ) {
                      setSelectedStudent(null);
                      // setStudentId(null);
                    } else {
                      setSelectedStudent(option);
                      setStudentEmail(option.value);
                      // setStudentId(option.value);
                    }
                  }}
                  onInputChange={(inputValue) =>
                    setSearchStudentQuery(inputValue)
                  }
                  placeholder="Search Student"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#cccccc", // Custom border color
                      boxShadow: "none",
                      "&:focus": {
                        borderColor: "#005dba", // Darker border on hover
                      },
                      "&:hover": {
                        borderColor: "#cccccc", // Darker border on hover
                      },
                      padding: "5px",
                      borderRadius: "15px", // Add some padding for better aesthetics
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: "#333",
                      padding: 10,
                      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                      "&:active": {
                        backgroundColor: "#e0e0e0", // Background color when option is clicked
                      },
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#999", // Placeholder color
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensure dropdown appears above other elements
                    }),
                  }}
                />
              </Form.Group>

              {selectedStudent && selectedStudent !== null && (
                <div
                  className="card"
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                  }}
                >
                  <div className="card-body" style={{ padding: "20px" }}>
                    <p
                      className="d-flex align-items-center w-100"
                      style={{ gap: 10 }}
                    >
                      <span style={{ fontWeight: "bold", width: "120px" }}>
                        Student Name :{" "}
                      </span>
                      {selectedStudent.label}
                    </p>
                    <p
                      className="d-flex mb-0 align-items-center w-100"
                      style={{ gap: 10 }}
                    >
                      <span style={{ fontWeight: "bold", width: "120px" }}>
                        Student Email :{" "}
                      </span>
                      {selectedStudent.value}
                    </p>
                  </div>
                </div>
              )}

              <Form.Group controlId="formJobSearch" className="mb-3">
                <Form.Label>Select Job Role</Form.Label>
                <Select
                  options={jobOptions}
                  value={selectedJob}
                  // isDisabled={isEditing === true ? true : false}
                  onChange={(option) => {
                    if (
                      option.label == "Add Other Job Role" ||
                      option.value == "Add Other Job Role"
                    ) {
                      setSelectedJob(option);
                      setPosition(null);
                      setPositionId(null);
                      setisOtherJobRole(true);
                    } else if (
                      option.label === "Select Job Role" ||
                      option.value === null ||
                      option.value === undefined ||
                      option.value === ""
                    ) {
                      setisOtherJobRole(false);
                      setSelectedJob(null);
                    } else {
                      setisOtherJobRole(false);
                      setSelectedJob(option);
                      setPosition(option.label);
                      setPositionId(option.value);
                    }
                  }}
                  onInputChange={(inputValue) => setSearchJobQuery(inputValue)}
                  placeholder="Select Job Role"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#cccccc", // Custom border color
                      boxShadow: "none",
                      "&:focus": {
                        borderColor: "#005dba", // Darker border on hover
                      },
                      "&:hover": {
                        borderColor: "#cccccc", // Darker border on hover
                      },
                      padding: "5px",
                      borderRadius: "15px", // Add some padding for better aesthetics
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: "#333",
                      padding: 10,
                      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Change background color on hover
                      "&:active": {
                        backgroundColor: "#e0e0e0", // Background color when option is clicked
                      },
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#999", // Placeholder color
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensure dropdown appears above other elements
                    }),
                  }}
                />
              </Form.Group>

              {isotherJobRole && isotherJobRole !== false && (
                <Form.Group controlId="formPosition" className="mb-3">
                  <Form.Label>Other Job Role</Form.Label>
                  <Form.Control
                    type="text"
                    value={otherJobRole}
                    onChange={(e) => setOtherJobRole(e.target.value)}
                  />
                </Form.Group>
              )}

              {/* <Form.Group
                as={Col}
                controlId="formStudentEmail"
                className="mb-3"
              >
                <Form.Label>Student Email</Form.Label>
                <Form.Control
                  type="email"
                  value={studentEmail}
                  onChange={(e) => setStudentEmail(e.target.value)}
                  disabled={studentEmail}
                />
              </Form.Group>
              <Form.Group controlId="formPosition" className="mb-3">
                <Form.Label>Select Job Role </Form.Label>
                <Form.Control
                  type="text"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </Form.Group> */}
            </Form.Row>

            <Form.Group as={Col} controlId="formPackage" className="mb-3">
              <Form.Label>Package</Form.Label>
              <Form.Control
                type="number"
                value={packageValue}
                onChange={(e) => setPackageValue(e.target.value)}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Form.Group>

            <Form.Group controlId="formOfferAcceptance" className="mb-3">
              <Form.Label>Offer Acceptance</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Accepted"
                  value="1"
                  name="acceptance"
                  onChange={() => setAcceptance(1)}
                  checked={acceptance == 1}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Rejected"
                  value="0"
                  name="acceptance"
                  onChange={() => setAcceptance(0)}
                  checked={acceptance == 0}
                />
              </div>
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formDateOffered" className="mb-3">
                <Form.Label>Offer Date</Form.Label>
                <Form.Control
                  type="date"
                  value={dateOffered}
                  onChange={(e) => setDateOffered(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formJoiningDate" className="mb-3">
                <Form.Label>Joining Date</Form.Label>
                <Form.Control
                  type="date"
                  value={joiningDate}
                  onChange={(e) => setJoiningDate(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddOffer}>
            Save Offer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfile;
