import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import axios from "axios";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import Swal from "sweetalert2";
import { Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
import { logout } from "../../../store/actions/AuthActions";
// import { useSelector } from "react-redux";
const InviteStudent = () => {
  //Modal box
  const courses = useSelector((state) => state.courses.courses);
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [itemsPerPage] = useState(10);
  const [inviteStudentList, setInviteStudentData] = useState([]);
  const payload1 = { college_id: collegeId, branch_id: 18 };
  const [studentList, setStudentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [studentsPerPage] = useState(10); // Number of students to display per page
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(false); // Loading state
  const [passoutYear, setPassoutYear] = useState("Passout Year");
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [masterCourseId, setMasterCourseId] = useState(null);
  const [specId, setSpecId] = useState(null);
  const [passoutYearForm, setPassoutYearForm] = useState("");
  const [specializationData, setSpecializationData] = useState([]);
  const [interestedPlacement, setInterestedPlacement] = useState(1);
  const [interestedHigherStudy, setInterestedHigherStudy] = useState(1);
  const [studentId, setStudentId] = useState(null);
  const [coursefilterId, setCourseIdfilter] = useState(null);
  const currentYear = new Date().getFullYear();
  const [studentName, setStudentName] = useState("");
  const [selectedStudId, setSelectedStudId] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [courseList, setCourseList] = useState("Choose Course");
  const [courseIdList, setCourseIdList] = useState(null);
  const [specialization, setSpecialization] = useState("Choose Specialization");
  const [specializationId, setSpecializationId] = useState(null);
  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
  }, [dispatch]);

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    if (courseId != null) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      const payload = { college_id: collegeId, course_id: courseId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          console.log("err======", err);
        });
    } else if (coursefilterId != null) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      const payload = { college_id: collegeId, course_id: coursefilterId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          console.log("err======", err);
        });
    }
  }, [courseId, coursefilterId]);

  useEffect(() => {
    if (masterCourseId !== null) {
      const coursemaster = courses?.find(
        (courses) => courses?.course_master_id == masterCourseId
      );
      setCourseId(coursemaster.college_course_id);
    }
  }, [masterCourseId, course]);

  useEffect(() => {
    const payload = { college_id: collegeId };
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(`${API_URL}/api/freshment_portal_apis/GenericAPI/get_courses_list`, {
        params: payload,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        setCourse(response.data.data);
        // console.log(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        console.log("err======", err);
      });
  }, []);

  const handleSubmit = () => {
    // e.preventDefault();
    // Prepare payload for the API
    const payload = {
      student_id: studentId,
      full_name: fullName,
      email: email,
      phone_number: phoneNumber,
      course_id: masterCourseId,
      passout_year: passoutYearForm,
      college_connect_id: collegeId,
      specialization_id: specId,
      interested_in_placement: interestedPlacement,
      interested_in_higher_education: interestedHigherStudy,
    };
    console.log(payload);

    const params = new URLSearchParams();
    if (isEditing) {
      params.append("student_id", payload.student_id);
    }
    params.append("college_connect_id", payload.college_connect_id);
    params.append("full_name", payload.full_name);
    params.append("email", payload.email);
    params.append("phone_number", payload.phone_number);
    params.append("course_id", payload.course_id);
    params.append("passout_year", payload.passout_year);
    if (
      payload.specialization_id !== null &&
      payload.specialization_id !== ""
    ) {
      params.append("specialization_id", payload.specialization_id);
    }
    params.append("interested_in_placement", payload.interested_in_placement);
    params.append(
      "interested_in_higher_education",
      payload.interested_in_higher_education
    );
    // console.log(params);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    console.log(params);
    if (isEditing) {
      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/students/update_student_details`,
          params,
          {
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8",
          //   },
          // }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire(
              "Success",
              "Student Details Updated successfully!",
              "success"
            );
            setIsEditing(false);
            setModalShow(false);
            // Optionally refresh the student list here
            fetchInvitedStudents();
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setCourseId(null);
            setMasterCourseId(null);
            setSpecId(null);
            setPassoutYearForm("");
            setInterestedPlacement(1);
            setInterestedHigherStudy(1);
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire("Error", "Failed to add student.", "error");
        });
    } else {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/Students/add_student_details`,
          params,
          {
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8",
          //   },
          // }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            Swal.fire("Success", "Student added successfully!", "success");
            setModalShow(false);
            // Optionally refresh the student list here
            fetchInvitedStudents();
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setCourseId(null);
            setMasterCourseId(null);
            setSpecId(null);
            setPassoutYearForm("");
            setInterestedPlacement(1);
            setInterestedHigherStudy(1);
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire("Error", "Failed to add student.", "error");
        });
    }
  };

  const handleSearch = () => {
    fetchInvitedStudents();
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (isInitialLoad) {
      fetchInvitedStudents();
      setIsInitialLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!isInitialLoad) {
      fetchInvitedStudents();
    }
  }, [passoutYear, courseIdList, specialization, status, currentPage]);

  const fetchInvitedStudents = async () => {
    const payload1 = {
      college_id: collegeId,
    };

    setLoading(true); // Set loading to true before fetching data
    const params = {
      ...payload1,
      offset: currentPage * studentsPerPage,
      limit: studentsPerPage,
      // search: search,
    };
    if (passoutYear !== "Passout Year") {
      params.filter_by_passout_year = passoutYear;
    }

    if (courseIdList !== null || courseList !== "Choose Course") {
      params.filter_by_course = parseInt(courseIdList);
    }
    if (status !== 0 && status !== null) {
      params.filter_by_status = status;
    }
    if (
      specializationId !== null ||
      specialization !== "Choose Specialization"
    ) {
      params.filter_by_specialization = parseInt(specializationId);
    }
    if (search !== "") {
      params.search = search;
    }

    // console.log(params);
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(
        `${API_URL}/api/freshment_portal_apis/InviteStudents/get_invited_students_list`,
        {
          params,
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        const { data, totalRecords } = response.data;
        setInviteStudentData(data);
        setTotalRecords(totalRecords);
        // console.log(response.data)
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        console.log("err======", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const indexOfLastOffer = Math.min(
    (currentPage + 1) * itemsPerPage,
    totalRecords
  );
  const indexOfFirstOffer = currentPage * itemsPerPage;
  // useEffect(() => {
  //   console.log(inviteStudentList);
  // }, [inviteStudentList]);

  // console.log(collegeId);

  const sendNotifications = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Send Notifications",
      text: "Do you want to send notifications to all students?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, send it!",
      cancelButtonText: "No, cancel!",
    });

    if (isConfirmed) {
      const payload = {
        college_id: collegeId,
      };
      const params = new URLSearchParams();
      // params.append("college_id", payload.college_id);
      params.append("college_id", payload.college_id);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/InviteStudents/send_email_notifications`,
          params,
          {
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8",
          //   },
          // }
        )
        .then((response) => {
          // Show response in SweetAlert2
          Swal.fire({
            title: "Success!",
            text: "Notifications sent successfully!",
            icon: "success",
          });
          // console.log(response);
        })
        .catch((err) => {
          // Show error in SweetAlert2
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire({
            title: "Error!",
            text: "Failed to send notifications.",
            icon: "error",
          });
          console.log("err======", err);
        });
    }
  };

  const handleSendReminder = async () => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: "Send Notifications",
      text: `${`Do you want to send notifications to ${studentName} ? `}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, send it!",
      cancelButtonText: "No, cancel!",
    });

    // If user confirmed, proceed with sending notifications
    if (isConfirmed) {
      const payload = {
        college_id: collegeId,
        student_id: selectedStudId,
      };
      const params = new URLSearchParams();
      params.append("college_id", payload.college_id);
      params.append("student_id", payload.student_id);
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      axios
        .post(
          `${API_URL}/api/freshment_portal_apis/InviteStudents/send_email_reminder`,
          params,
          {
            headers: {
              Authorization: authToken,
              // "Content-Type":
              //   "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          // Show response in SweetAlert2
          // console.log(response);
          if (response.data.success) {
            Swal.fire({
              title: "Success!",
              text: "Notifications sent to Student Successfully!",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to send notifications.",
              icon: "error",
            });
          }
        })
        .catch((err) => {
          // Show error in SweetAlert2
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          Swal.fire({
            title: "Error!",
            text: "Failed to send notifications.",
            icon: "error",
          });
          console.log("err======", err);
        });
    } else {
      setStudentName("");
    }
  };

  useEffect(() => {
    if (studentName !== "") {
      handleSendReminder();
    }
  }, [studentName]);

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(0, currentPage - Math.floor(itemsPerPage / 2));
    const endPage = Math.min(totalPages - 1, startPage + itemsPerPage - 1);

    if (startPage > 0)
      pages.push(
        <span key="start-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Link
          key={i}
          to="#"
          className={`paginate_button ${
            i === currentPage ? "bg-white border" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Link>
      );
    }

    if (endPage < totalPages - 1)
      pages.push(
        <span key="end-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    return pages;
  };

  return (
    <>
      <Fragment>
        <div className="d-flex align-items-center mb-4 flex-wrap">
          <h4 className="fs-22 font-w600 me-auto" style={{ marginLeft: "5px" }}>
            Invite Student List
          </h4>
          <div>
            <Button
              className="me-3"
              variant="danger"
              onClick={() => {
                setIsEditing(false);
                setFullName("");
                setEmail("");
                setPhoneNumber("");
                setCourseId(null);
                setMasterCourseId(null);
                setSpecId(null);
                setSpecializationData([]);
                setPassoutYearForm("");
                setInterestedPlacement(1);
                setInterestedHigherStudy(1);
                setModalShow(true);
              }}
            >
              Add Student
            </Button>
            <Link
              to={"/import-student"}
              className="btn btn-info me-3"
              // onClick={() => setAddCard(true)}
            >
              <i className="fas fa-plus me-2"></i>Import
            </Link>

            <Button variant="primary" onClick={() => sendNotifications()}>
              <i className="fas fa-envelope me-2"></i>
              Resend Invitations
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
          <div className="col-xl-2 col-xxl-2 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0 style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {courseList}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setCourseIdList(null);
                    setCourseIdfilter(null);
                    setCourseList("Choose Course");
                    setSpecializationId(null);
                    setSpecialization("Choose Specialization");
                    setCurrentPage(0);
                    setSpecializationData([]);
                  }}
                >
                  Choose Course
                </Dropdown.Item>
                {courses.map((course) => (
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => {
                      setCourseIdList(course.filter_id);
                      setCourseIdfilter(course.college_course_id);
                      setCourseList(course.name);
                      setSpecializationId(null);
                      setSpecialization("Choose Specialization");
                      setCurrentPage(0);
                    }}
                  >
                    {course.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0 style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {specialization}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setSpecializationId(null);
                    setSpecialization("Choose Specialization");
                    setCurrentPage(0);
                  }}
                >
                  Choose Specialization
                </Dropdown.Item>
                {specializationData && specializationData.length > 0 ? (
                  specializationData?.map((specialization) => (
                    <Dropdown.Item
                      className="text-primary"
                      onClick={() => {
                        setSpecializationId(
                          specialization.specilization_master_id
                        );
                        setSpecialization(specialization.name);
                        setCurrentPage(0);
                      }}
                    >
                      {specialization.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => {
                      setSpecializationId(0);
                      setCurrentPage(0);

                      setSpecialization("All");
                    }}
                  >
                    All
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-2 col-xxl-2 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0  style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {passoutYear}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setPassoutYear("Passout Year");
                  }}
                >
                  Passout Year
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setPassoutYear(currentYear - 1);
                  }}
                >
                  {currentYear - 1}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setPassoutYear(currentYear);
                  }}
                >
                  {currentYear}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setPassoutYear(currentYear + 1);
                  }}
                >
                  {currentYear + 1}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => {
                    setCurrentPage(0);
                    setPassoutYear(currentYear + 2);
                  }}
                >
                  {currentYear + 2}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-5 col-xxl-5 col-lg-12 col-12 d-md-flex job-title-search pe-0">
            <div className="input-group search-area">
              <input
                type="text"
                className="form-control h-auto"
                placeholder="Search student name/email..."
                value={search}
                onChange={(e) => {
                  setCurrentPage(0);
                  setSearch(e.target.value);
                }}
              />
              <span className="input-group-text">
                <Button
                  onClick={handleSearch}
                  className="btn btn-primary btn-rounded"
                >
                  Search <i className="flaticon-381-search-2 ms-2"></i>
                </Button>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 mb-3 justify-content-end align-items-center">
          <span className="me-2">Select Status :</span>
          <div className="btn-group" role="group">
            <button
              className={`btn btn-sm btn-outline-danger ${
                status === 0 && "bg-danger text-white"
              }`}
              onClick={() => {
                setCurrentPage(0);
                setStatus(0);
              }}
            >
              All
            </button>
            <button
              className={`btn btn-sm btn-outline-danger ${
                status === 1 && "bg-danger text-white"
              }`}
              onClick={() => {
                setCurrentPage(0);
                setStatus(1);
              }}
            >
              Pending
            </button>
            <button
              className={`btn btn-sm btn-outline-danger ${
                status === 2 && "bg-danger text-white"
              }`}
              onClick={() => {
                setCurrentPage(0);
                setStatus(2);
              }}
            >
              In Progress
            </button>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {Loading ? (
                <div className="text-center my-4">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : inviteStudentList && inviteStudentList.length > 0 ? (
                <div
                  className="table-responsive dataTables_wrapper"
                  id="application-data"
                >
                  <table
                    className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
                    id="example5"
                  >
                    <thead>
                      {/* <tr role="row">
                        <td className="text-end" colSpan={6}>
                          <span className="me-2">Select Status :</span>
                          <div className="btn-group" role="group">
                            <button
                              className={`btn btn-sm btn-outline-danger ${
                                status === 0 && "bg-danger text-white"
                              }`}
                              onClick={() => {
                                setCurrentPage(0);
                                setStatus(0);
                              }}
                            >
                              All
                            </button>
                            <button
                              className={`btn btn-sm btn-outline-danger ${
                                status === 1 && "bg-danger text-white"
                              }`}
                              onClick={() => {
                                setCurrentPage(0);
                                setStatus(1);
                              }}
                            >
                              Pending
                            </button>
                            <button
                              className={`btn btn-sm btn-outline-danger ${
                                status === 2 && "bg-danger text-white"
                              }`}
                              onClick={() => {
                                setCurrentPage(0);
                                setStatus(2);
                              }}
                            >
                              In Progress
                            </button>
                          </div>
                        </td>
                      </tr> */}

                      <tr role="row">
                        <th className="sorting_asc">Student Name</th>
                        <th className="sorting_asc">Email</th>
                        <th className="sorting_asc">Phone No.</th>
                        <th className="sorting_asc">Course</th>
                        <th className="sorting_asc">Status</th>
                        <th className="sorting_asc text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inviteStudentList &&
                        inviteStudentList.length > 0 &&
                        inviteStudentList.map((stud, index) => (
                          <tr key={stud.id} role="row">
                            {/* <td>{index + 1 + currentPage * itemsPerPage}</td>{" "} */}
                            {/* Adjust index for pagination */}
                            <td>{stud.full_name}</td>
                            <td>{stud.email}</td>
                            <td>{stud.phone_number}</td>
                            <td>
                              {stud.course_name}{" "}
                              {stud.specialization_name
                                ? `(${stud.specialization_name})`
                                : ""}
                            </td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 600,
                                  pointerEvents: "none",
                                }}
                                className={`btn btn-sm text-uppercase  ${
                                  stud.onboarding_status.toLowerCase() ===
                                  "in progress"
                                    ? "btn-outline-success"
                                    : stud.onboarding_status.toLowerCase() ===
                                      "pending"
                                    ? "btn-outline-dark"
                                    : "btn-outline-primary"
                                }`}
                              >
                                {stud.onboarding_status}
                              </span>
                            </td>
                            <td
                              className="action-btn d-flex justify-content-center align-items-center wspace-no"
                              style={{ gap: 5 }}
                            >
                              <Button
                                as={Link}
                                to={`/user-profile/${stud.onboarding_student_id}`}
                                size="sm"
                                variant="warning"
                              >
                                View Profile
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={async () => {
                                  setSelectedStudId(stud.onboarding_student_id);
                                  setStudentName(stud.full_name);
                                }}
                              >
                                Send Reminder
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info" id="example5_info">
                      Showing {indexOfFirstOffer + 1} to{" "}
                      {Math.min(indexOfLastOffer, totalRecords)} of{" "}
                      {totalRecords} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Link
                        className={`paginate_button previous ${
                          currentPage === 0 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage > 0)
                            handlePageChange(currentPage - 1);
                        }}
                      >
                        Previous
                      </Link>{" "}
                      <span>{renderPageNumbers()}</span>
                      <Link
                        to="#"
                        className={`paginate_button next ${
                          currentPage === totalPages - 1 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage < totalPages - 1)
                            handlePageChange(currentPage + 1);
                        }}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                  {/* Pagination */}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  No Invited Students Found
                </div>
              )}{" "}
            </div>
          </div>
        </div>
        <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
          <Modal.Header>
            <Modal.Title>
              {isEditing ? "Edit Student Details" : "Add New Student"}
            </Modal.Title>

            <Button
              onClick={() => setModalShow(false)}
              variant=""
              className="btn-close"
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  disabled={isEditing && email != "" ? true : false}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPhoneNumber" className="mt-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Select Course</Form.Label>
                <Form.Control
                  as="select"
                  value={masterCourseId}
                  onChange={(e) => {
                    if (e.target.value == "Select Course") {
                      setMasterCourseId(null);
                      setSpecId(null);
                      setSpecializationData([]);
                    } else {
                      setMasterCourseId(e.target.value);
                    }
                  }}
                  id="courseSelect"
                >
                  <option
                    // onClick={() => setMasterCourseId(null)}
                    value="Select Course"
                  >
                    Select Course
                  </option>
                  {course?.map((courses) => (
                    <option value={courses.course_master_id}>
                      {courses.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Select Specialization</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    if (e.target.value == "Select Specialization") {
                      setSpecId(null);
                    } else {
                      setSpecId(e.target.value);
                    }
                  }}
                  value={specId}
                  as="select"
                  id="branchSelect"
                >
                  <option value="Select Specialization">
                    Select Specialization
                  </option>
                  {specializationData &&
                    specializationData.length > 0 &&
                    specializationData?.map((specialize) => (
                      <option value={specialize.specilization_master_id}>
                        {specialize.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Select Passout Year</Form.Label>
                <Form.Control
                  onChange={(e) => setPassoutYearForm(e.target.value)}
                  value={passoutYearForm}
                  as="select"
                  id="passoutYearSelect"
                >
                  <option>Select Year</option>
                  <option value={currentYear}>{currentYear}</option>
                  <option value={currentYear + 1}>{currentYear + 1}</option>
                  <option value={currentYear + 2}>{currentYear + 2}</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="IntrestedPlacement" className="mt-3">
                <Form.Label>Intrested In College Placement</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    value="1"
                    name="interestedPlacement"
                    onChange={() => setInterestedPlacement(1)}
                    checked={interestedPlacement === 1}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value="0"
                    name="interestedPlacement"
                    onChange={() => setInterestedPlacement(0)}
                    checked={interestedPlacement === 0}
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="intrestedHigherStudies" className="mt-3">
                <Form.Label>Intrested In Higher Studies</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    value="1"
                    name="interestedHigherStudy"
                    onChange={() => setInterestedHigherStudy(1)}
                    checked={interestedHigherStudy === 1}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    value="0"
                    name="interestedHigherStudy"
                    onChange={() => setInterestedHigherStudy(0)}
                    checked={interestedHigherStudy === 0}
                  />
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)} variant="danger light">
              Close
            </Button>
            <Button onClick={() => handleSubmit()} variant="primary">
              {isEditing ? "Edit Student" : "Add Student"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    </>
  );
};
export default InviteStudent;
