import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Modal, Button, Form } from "react-bootstrap";
import CkEditorBlog from "../Forms/CkEditor/CkEditorBlog";
import { useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { API_URL } from "../../../config";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
import { logout } from "../../../store/actions/AuthActions";
import { setJobCategory } from "../../../store/actions/CourseAction";
const JobView = () => {
  const collegeId = useSelector((state) => state.college_id.college_id);
  const courses = useSelector((state) => state.courses.courses);
  const [modalShow, setModalShow] = useState(false);
  const [jobList, setJobDataList] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [masterCourseId, setMasterCourseId] = useState(null);
  const [specializationId, setspecializationId] = useState(0);
  const [message, setMessage] = useState("");
  const [passoutyear, setPassoutYear] = useState(null);
  const [specialization, setSpecialization] = useState([]);
  const [formType, setFormType] = useState("Push");
  const currentYear = new Date().getFullYear();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [copyMessage, setCopyMessage] = useState(false);
  const [copyMessagePhone, setCopyMessagePhone] = useState(false);
  const [copyMessageEmail, setCopyMessageEmail] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const Location = useLocation();
  // console.log(Location);
  const ref = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
    const storedCategory = localStorage.getItem("job_category");
    if (storedCategory) {
      dispatch(setJobCategory(JSON.parse(storedCategory)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (masterCourseId !== null) {
      const coursemaster = courses?.find(
        (courses) => courses?.course_master_id == masterCourseId
      );
      setCourseId(coursemaster.college_course_id);
    }
  }, [masterCourseId, course]);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
    sendAnalysis({ analysis: "share", job });
  };
  const { job } = useParams();

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setMessage(data); // Update state with CKEditor content
  };

  const shareOnFacebook = () => {
    const link = window.location.pathname;
    const url = `${API_URL}${link}`;
    const message = jobList?.share_text || "";

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}&quote=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  const shareOnWhatsApp = () => {
    const link = window.location.pathname;
    const url = `https://freshmentapp.com/job-description/${job}`;
    const message = jobList?.share_text || "";

    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        message
      )}%20${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  // const shareOnWhatsApp = () => {
  //   const link = window.location.pathname;
  //   const url = `${API_URL}${link}`;
  //   const message = jobList?.share_text || "";

  //   window.open(
  //     `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}%20${encodeURIComponent(url)}`,
  //     "_blank"
  //   );
  // };

  const [accessToken, setAccessToken] = useState("YOUR_ACCESS_TOKEN"); // Set your access token

  const sharePost = async () => {
    const postData = {
      owner: "urn:li:person:YOUR_LINKEDIN_USER_ID", // Your LinkedIn user ID
      text: { text: "Excited to share this amazing content!" }, // Message content
      content: {
        contentEntities: [
          {
            entityLocation: "https://www.example.com", // URL to share
          },
        ],
        title: "Amazing Opportunity",
      },
      distribution: {
        linkedInDistributionTarget: {},
      },
    };

    try {
      const response = await fetch("https://api.linkedin.com/v2/shares", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`, // OAuth 2.0 Access Token
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const data = await response.json();
      // console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const shareOnLinkedIn = () => {
    const link = Location.pathname; // Get the current path
    const url = `${API_URL}/${link}`; // Construct the full URL
    const message = jobList?.share_text; // Get the message to share

    // Use encodeURIComponent to properly format the message and URL
    const encodedUrl = encodeURIComponent(url);
    const encodedMessage = encodeURIComponent(message);

    // Open the LinkedIn share URL with the message
    window.open(
      `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${API_URL}`,
      "_blank"
    );
  };

  const copyLink = () => {
    const link = Location.href; // Get the curre.pathL
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopyMessage(true); // Show copy message
        setTimeout(() => {
          setCopyMessage(false); // Hide copy message after 2 seconds
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // useEffect(() => {
  //   console.log(courseId);
  //   console.log(specializationId);
  //   console.log(passoutyear);
  //   console.log(formType);
  //   console.log(message);
  // }, [courseId, specializationId, passoutyear, formType, message]);

  useEffect(() => {
    const payload = { job_id: job };
    // console.log(job);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(`${API_URL}/api/freshment_portal_apis/Jobs/get_job_details`, {
        params: payload,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        setJobDataList(response.data.data);
        // console.log(response);
        setErrMessage(response.data.message);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        console.log("err======", err);
      });
  }, [job]);

  // useEffect(() => {
  //   const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  //   const authToken = userDetails?.data?.auth_token;

  //   const payload = { college_id: collegeId };
  //   axios
  //     .get(
  //       `${API_URL}/api/freshment_portal_apis/GenericAPI/get_courses_list`,
  //       {
  //         params: payload,
  //         headers: {
  //           Authorization: authToken,
  //         },
  //       }
  //       // {
  //       //   headers: {
  //       //     "Access-Control-Allow-Origin": "*",
  //       //     "Access-Control-Allow-Headers":
  //       //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  //       //     "Content-Type":
  //       //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
  //       //   },
  //       // }
  //     )
  //     .then((response) => {
  //       setCourse(response.data.data);
  //       // console.log(response.data.data);
  //     })
  //     .catch((err) => console.log("err======", err));
  // }, []);

  useEffect(() => {
    if (courseId != null) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      const payload = { college_id: collegeId, course_id: courseId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Headers":
          //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          //   },
          // }
        )
        .then((response) => {
          setSpecialization(response.data.data);
          // console.log(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch(logout());
          }
          console.log("err======", err);
        });
    }
  }, [courseId]);

  const sendAnalysis = (props) => {
    const params = new URLSearchParams();
    params.append("job_id", props.job);
    params.append("college_id", collegeId);
    params.append("analysis_type", props.analysis);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/jobs/add_job_analysis`,
        params,
        {
          headers: {
            Authorization: authToken,

            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        console.log("err======", err);
      });
  };
  // useEffect(() => {
  //   console.log(specializationId);
  // }, [specializationId]);

  const sendNotification = (props) => {
    if (passoutyear == null) {
      Swal.fire("Error", "Please select passout year", "error");
      return;
    }

    const params = new URLSearchParams();
    params.append("notification_type", props.formType);
    params.append("job_id", props.job);
    params.append("college_connect_id", collegeId);
    params.append("notification_message", props.message);
    params.append("passout_year", props.passoutyear);
    params.append("course_id", parseInt(props.masterCourseId));
    params.append("specialization_id", props.specializationId);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/jobs/send_job_notification`,
        params,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          Swal.fire("Success", res.data.message, "success").then(() => {
            setModalShow(false);
            setFormType("Push");
            setMessage("");
            setPassoutYear(null);
            setCourseId(null);
            setspecializationId(0);
          });
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
        // console.log(res);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        console.log("err======", err);
      });
  };

  // const truncateEmail = (email, index) => {
  //   return email.length > index ? email.substring(0, index) + "..." : email;
  // };
  return (
    <>
      {jobList ? (
        <>
          <div className="d-flex align-items-center mb-4 flex-wrap">
            <h4 className="fs-20 font-w600 me-auto">Job View</h4>
            <div>
              {/* {jobList?.details?.contact_email && (
            <a
              href={`mailto:${jobList.details.contact_email}`}
              className="btn btn-secondary btn-sm me-3"
            >
              <i className="fas fa-envelope"></i>
            </a>
          )}

          {jobList?.details?.contact_number && (
            <a
              href={`tel:${jobList.details.contact_number}`}
              className="btn btn-secondary btn-sm me-3"
            >
              <i className="fas fa-phone-alt"></i>
            </a>
          )}

          {/* <Link to={"#"} className="btn btn-secondary btn-sm">
            <i className="fas fa-info"></i>
          </Link> */}
              <div className="d-flex align-items-center">
                {/* {jobList?.details?.apply_online_url ||
            jobList?.details?.advertisement_url ? (
              <a
                href={
                  jobList.details.apply_online_url ||
                  jobList.details.advertisement_url
                }
                className="btn mb-3 btn-md btn-warning me-2"
                onClick={() => {
                  sendAnalysis({ analysis: "apply", job });
                }}
              >
                <i class="far fa-check-circle me-2"></i>
                Apply
              </a>
            ) : null} */}
                {jobList && jobList.show_email_notification_button == true ? (
                  <div
                    as="button"
                    onClick={() => {
                      setFormType("Email");
                      setModalShow(true);
                    }}
                    className="btn btn-secondary btn-md mb-3 me-2"
                  >
                    <i className="fas fa-envelope me-2" area-hidden="true"></i>
                    Send Email To Student
                  </div>
                ) : null}
                {jobList && jobList.show_interested_button == true ? (
                  <Link
                    to={`/application-details/job/${job}`}
                    className="btn btn-info btn-md mb-3 me-2"
                  >
                    <i className="fas fa-list me-2" area-hidden="true"></i>
                    View Intrested
                  </Link>
                ) : null}
                {jobList && jobList.show_push_notification_button == true ? (
                  <div
                    onClick={() => {
                      setFormType("Push");
                      setModalShow(true);
                      sendAnalysis({ analysis: "send_notification", job });
                    }}
                    className="btn btn-primary btn-md me-2 mb-3"
                  >
                    <i className="fas fa-share me-2"></i>
                    Send Notification
                  </div>
                ) : null}
                <div ref={ref}>
                  <div
                    onClick={handleClick}
                    className="btn btn-danger btn-md me-2 mb-3"
                  >
                    <i className="fas fa-share-alt me-2"></i>Share Job
                  </div>

                  <Overlay
                    show={show}
                    target={target}
                    placement="top-start"
                    container={ref.current}
                    rootClose={true}
                    onHide={() => setShow(false)}
                  >
                    <Popover
                      id="popover-basic"
                      className="popover-custom"
                      style={{ marginBottom: "10px", marginLeft: "10px" }}
                    >
                      <Popover.Content className="d-flex align-items-center p-3">
                        <div
                          onClick={copyLink}
                          className="copy-div fs-18 me-3 text-dark cursor-pointer"
                        >
                          <i className="fas fa-copy me-2 fs-18 icon-copy"></i>
                          Copy Link
                          {copyMessage && (
                            <span
                              style={{ zIndex: 10 }}
                              className="copy-message fade-in-out"
                            >
                              Link copied!
                            </span>
                          )}
                        </div>
                        <div
                          onClick={shareOnFacebook}
                          className=" me-3 cursor-pointer"
                        >
                          <i className="fab fa-facebook-f me-2 fs-18 icon-facebook"></i>
                        </div>
                        <div
                          onClick={shareOnWhatsApp}
                          className=" me-3 cursor-pointer"
                        >
                          <i className="fab fa-whatsapp me-2 fs-18 icon-whatsapp"></i>
                        </div>
                        <div
                          onClick={shareOnLinkedIn}
                          className="cursor-pointer"
                        >
                          <i className="fab fa-linkedin me-2 fs-18 icon-linkedin"></i>
                        </div>
                      </Popover.Content>
                    </Popover>
                  </Overlay>

                  <style jsx>{`
                    .popover-custom {
                      border: none;
                      border-radius: 10px;
                      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                      background-color: #fff;
                    }
                    .icon-copy {
                      color: #007bff; /* Blue for copy link */
                    }
                    .icon-facebook {
                      color: #4267b2; /* Facebook blue */
                    }
                    .icon-whatsapp {
                      color: #25d366; /* WhatsApp green */
                    }
                    .icon-linkedin {
                      color: #0077b5; /* LinkedIn blue */
                    }
                    .copy-div:hover {
                      text-decoration: none;
                      opacity: 0.8;
                    }
                    .copy-division {
                      position: relative;
                    }

                    .copy-division2 {
                      position: relative;
                    }

                    .copy-message {
                      position: absolute;
                      top: -30px; /* Adjust position above the icon */
                      left: 10px;
                      background-color: black;
                      color: white;
                      padding: 5px 8px;
                      border-radius: 5px;
                      font-size: 14px;
                      white-space: nowrap; /* Prevent text wrap */
                      opacity: 0; /* Start hidden */
                      transition: opacity 0.5s ease;
                    }
                    .copy-message.fade-in-out {
                      opacity: 1; /* Show the message */
                    }
                  `}</style>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Sidebar with Overview */}
            <div className="col-xl-4 col-xxl-4">
              <div className="card" style={{ height: "fit-content" }}>
                <div className="card-header border-0 pb-0">
                  <h4 className="fs-20 mb-0">Overview</h4>
                </div>
                <div className="card-body pt-4">
                  <div>
                    <div className="mb-4 d-flex">
                      <h5 className="mb-1 fs-14 custom-label">Job Title:</h5>
                      <span>{jobList?.details?.name}</span>
                    </div>
                    <div className="mb-4 d-flex">
                      <h5 className="mb-1 fs-14 custom-label">Experience:</h5>
                      <span>Fresher</span>
                    </div>
                    {jobList?.details?.vacancy && (
                      <div className="mb-4 d-flex">
                        <h5 className="mb-1 fs-14 custom-label">Vacancy:</h5>
                        <span>{jobList?.details?.vacancy || ""}</span>
                      </div>
                    )}
                    <div className="mb-4 d-flex">
                      <h5 className="mb-1 fs-14 custom-label">Job Type:</h5>
                      <span>{jobList?.details?.job_tag}</span>
                    </div>
                    <div className="mb-4 d-flex">
                      <h5 className="mb-1 fs-14 custom-label">Posted Date:</h5>
                      <span>
                        {moment(jobList?.details?.created_at).format(
                          "YYYY-MM-DD"
                        )}
                      </span>
                    </div>
                    <div className="mb-4 d-flex">
                      <h5 className="mb-1 fs-14 custom-label">
                        Last Apply Date:
                      </h5>
                      <span>{jobList?.details?.application_end_date}</span>
                    </div>
                    {jobList?.details?.contact_number && (
                      <div className="mb-4 d-flex align-items-center">
                        <h5 className="mb-1 fs-14 custom-label">
                          Contact No. :
                        </h5>
                        <span className="me-2">
                          {jobList.details.contact_number}
                        </span>
                        <div
                          className="copy-division2 fs-18 me-3 text-dark cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              jobList.details.contact_number
                            );
                            setCopyMessagePhone(true);
                            setTimeout(() => {
                              setCopyMessagePhone(false);
                            }, 2000);
                          }}
                        >
                          <i className="fas fa-copy me-2 fs-18 icon-copy"></i>

                          {copyMessagePhone && (
                            <span
                              style={{ zIndex: "10" }}
                              className="copy-message fade-in-out"
                            >
                              Number copied!
                            </span>
                          )}
                        </div>

                        <a
                          href={`tel:${jobList.details.contact_number}`}
                          onClick={() => {
                            sendAnalysis({ analysis: "contact_hr", job });
                          }}
                          className="bg-primary rounded text-white"
                          style={{ padding: "8px 8px" }}
                        >
                          <i className="fas fa-phone-alt"></i>
                        </a>
                      </div>
                    )}
                    {jobList?.details?.contact_email && (
                      <div className="mb-4 d-block align-items-start">
                        <h5 className="mb-3 fs-14 custom-label">
                          Contact Email:
                        </h5>
                        <div className="row">
                          <span
                            // style={{
                            //   textWrap: "break-word",
                            //   whiteSpace: "normal",
                            // }}
                            className="col-10"
                          >
                            {jobList.details.contact_email}
                          </span>
                          <div className="col-1">
                            <div
                              className="copy-division fs-18 ms-2 text-dark cursor-pointer"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  jobList.details.contact_email
                                );
                                setCopyMessageEmail(true);
                                setTimeout(() => {
                                  setCopyMessageEmail(false);
                                }, 2000);
                              }}
                            >
                              <i className="fas fa-copy me-2 fs-18 icon-copy"></i>

                              {copyMessageEmail && (
                                <span
                                  style={{ zIndex: "10" }}
                                  className="copy-message fade-in-out"
                                >
                                  Email copied!
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="card-footer p-0 border-0">
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      {jobList?.details?.apply_online_url ||
                      jobList?.details?.advertisement_url ? (
                        <a
                          href={
                            jobList.details.apply_online_url ||
                            jobList.details.advertisement_url
                          }
                          target="_blank"
                          className="btn btn-sm btn-warning"
                          onClick={() => {
                            sendAnalysis({ analysis: "apply_now", job });
                          }}
                        >
                          <i className="fa fa-check me-2"></i>Apply Now
                        </a>
                      ) : null}
                      {jobList?.details?.contact_email ? (
                        <a
                          href={`mailto:${jobList.details.contact_email}`}
                          onClick={() => {
                            sendAnalysis({ analysis: "send_email", job });
                          }}
                          className="bg-info text-white btn btn-sm "
                          // style={{ padding: "5px 10px" }}
                          // className="btn btn-secondary btn-sm me-3"
                        >
                          <i className="fas fa-envelope me-2"></i> Send Email
                        </a>
                      ) : null}
                      {/* {jobList?.details?.contact_number && (
                      <a
                        href={`tel:${jobList.details.contact_number}`}
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          sendAnalysis({ analysis: "contact_hr", job });
                        }}
                      >
                        <i class="fas fa-phone-volume me-2"></i>Contact Now
                      </a>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Main content with job details */}
            <div className="col-xl-8 col-xxl-8">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-block">
                    <h4 style={{ fontSize: 22 }} className="d-block mb-3">
                      <div to={"#"}>{jobList?.details?.name}</div>
                    </h4>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <span className="me-3">
                        <div to={"#"} className="fs-14">
                          <i className="fas fa-briefcase me-2 text-warning"></i>
                          {jobList?.details?.category_name}
                        </div>
                      </span>
                      <span className="me-3">
                        <div to={"#"} className="fs-14">
                          <i className="fas fa-map-marker-alt me-2 text-warning"></i>{" "}
                          {jobList?.details?.job_location || "Pan India"}
                        </div>
                      </span>
                      <span className="me-3">
                        <div to={"#"} className="fs-14">
                          <i className="fas fa-rupee-sign me-2 text-warning"></i>
                          {jobList?.details?.salary &&
                          jobList?.details?.salary >= 100000
                            ? `${(
                                Number(jobList.details.salary) / 100000
                              ).toFixed(
                                Number(jobList.details.salary) % 100000 === 0
                                  ? 0
                                  : 1
                              )} LPA`
                            : "Industry Standard"}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                {/* Job Details */}
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h4 className="fs-20 mb-3">Job Description</h4>
                      <div className="row">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobList?.details?.description,
                          }}
                        ></div>
                      </div>
                    </div>
                    {jobList?.details?.job_required_skills && (
                      <div className="col-12">
                        <h4 className="fs-20 mb-3">Required Skills</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: jobList?.details?.job_required_skills,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                  <div className="py-2 border-top pt-4 border-bottom border-1">
                    <h4 className="fs-20 mb-3">Job Details</h4>
                    <div className="row mb-3">
                      <div className="col-xl-6">
                        <div>
                          <p className="font-w500 d-flex mb-3">
                            <h5 className="fs-14 mb-0 custom-label-3">
                              Job Role :
                            </h5>
                            <span className="font-w400">
                              {jobList?.details?.post_name || "N/A"}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-3">
                              Company Name:
                            </h5>
                            <span className="font-w400">
                              {jobList?.details?.company_name || "N/A"}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-3">
                              Salary:
                            </h5>
                            <span className="font-w400">
                              {jobList?.details?.salary &&
                              jobList?.details?.salary >= 100000
                                ? `${(
                                    Number(jobList.details.salary) / 100000
                                  ).toFixed(
                                    Number(jobList.details.salary) % 100000 ===
                                      0
                                      ? 0
                                      : 1
                                  )} LPA`
                                : "Industry Standard"}
                            </span>
                          </p>

                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-3">
                              Total Views:
                            </h5>
                            <span className="font-w400">
                              {jobList?.view_count}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-3">
                              Total Applications:
                            </h5>
                            <span className="font-w400">
                              {jobList?.total_job_applicants}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="ms-4">
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-2">
                              Job Experience:
                            </h5>
                            <span className="font-w400">Fresher</span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-2">
                              Total External Applications:
                            </h5>
                            <span className="font-w400">
                              {jobList?.external_job_applicants}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-2">
                              Interested Candidates:
                            </h5>
                            <span className="font-w400">
                              {jobList?.interested_candidates}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-2">
                              Eligibility:
                            </h5>
                            <span className="font-w400">
                              {jobList?.details?.eligibility || ""}
                            </span>
                          </p>
                          <p className="font-w500 mb-3 d-flex">
                            <h5 className="fs-14 mb-0 custom-label-2">
                              Job Type :
                            </h5>
                            <span className="font-w400">
                              {jobList?.details?.job_tag}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between  border-bottom py-4 flex-wrap">
                    <span>Job ID: {jobList?.details?.id}</span>
                  </div>
                </div>

                {/* Footer Actions */}
                {/* <div className="card-footer border-0">
                <div className="d-flex align-items-center">
                  {jobList?.details?.apply_online_url ||
                  jobList?.details?.advertisement_url ? (
                    <a
                      href={
                        jobList.details.apply_online_url ||
                        jobList.details.advertisement_url
                      }
                      className="btn mb-3 btn-md btn-warning me-2 "
                    >
                      <i class="far fa-check-circle me-2"></i>
                      Apply
                    </a>
                  ) : null}

                  <div
                    as={Button}
                    onClick={() => {
                      setModalShow(true);
                      sendAnalysis({ analysis: "send_notification", job });
                    }}
                    className="btn btn-primary btn-md me-2 mb-3"
                  >
                    <i className="fas fa-envelope me-2"></i>
                    Send Notification
                  </div>
                  <div ref={ref}>
                    <div
                      onClick={handleClick}
                      className="btn btn-danger btn-md me-2 mb-3"
                    >
                      <i className="fas fa-share-alt me-2"></i>Share Job
                    </div>

                    <Overlay
                      show={show}
                      target={target}
                      placement="top-start"
                      container={ref.current}
                      rootClose={true}
                      onHide={() => setShow(false)}
                    >
                      <Popover
                        id="popover-basic"
                        className="popover-custom"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                      >
                        <Popover.Content className="d-flex align-items-center p-3">
                          <div
                            onClick={copyLink}
                            className="copy-div fs-18 me-3 text-dark cursor-pointer"
                          >
                            <i className="fas fa-copy me-2 fs-18 icon-copy"></i>
                            Copy Link
                            {copyMessage && (
                              <span className="copy-message fade-in-out">
                                Link copied!
                              </span>
                            )}
                          </div>
                          <div
                            onClick={shareOnFacebook}
                            className=" me-3 cursor-pointer"
                          >
                            <i className="fab fa-facebook-f me-2 fs-18 icon-facebook"></i>
                          </div>
                          <div
                            onClick={shareOnWhatsApp}
                            className=" me-3 cursor-pointer"
                          >
                            <i className="fab fa-whatsapp me-2 fs-18 icon-whatsapp"></i>
                          </div>
                          <div
                            onClick={shareOnLinkedIn}
                            className="cursor-pointer"
                          >
                            <i className="fab fa-linkedin me-2 fs-18 icon-linkedin"></i>
                          </div>
                        </Popover.Content>
                      </Popover>
                    </Overlay>

                    <style jsx>{`
                      .popover-custom {
                        border: none;
                        border-radius: 10px;
                        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                        background-color: #fff;
                      }
                      .icon-copy {
                        color: #007bff; 
                      }
                      .icon-facebook {
                        color: #4267b2; 
                      }
                      .icon-whatsapp {
                        color: #25d366; 
                      }
                      .icon-linkedin {
                        color: #0077b5; 
                      }
                      .copy-div:hover {
                        text-decoration: none;
                        opacity: 0.8;
                      }

                      .copy-message {
                        position: absolute;
                        top: -30px; 
                        left: 10px;
                        background-color: black;
                        color: white;
                        padding: 5px 8px;
                        border-radius: 5px;
                        font-size: 14px;
                        white-space: nowrap; 
                        opacity: 0; 
                        transition: opacity 0.5s ease;
                      }
                      .copy-message.fade-in-out {
                        opacity: 1; 
                      }
                    `}</style>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <p className="fs-16 text-center"> {errMessage}</p>
        </div>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Notification
          </Modal.Title>
          <Button
            onClick={() => setModalShow(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-4">
          {/* Radio Buttons for Email/Push */}
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <div className="d-flex align-items-center" style={{ gap: 10 }}>
              <Form.Check
                type="radio"
                label="Email"
                name="notificationType"
                id="email"
                checked={formType === "Email"}
                onChange={() => setFormType("Email")}
              />
              <Form.Check
                type="radio"
                label="Push"
                name="notificationType"
                id="push"
                checked={formType === "Push"}
                onChange={() => setFormType("Push")}
              />
            </div>
          </Form.Group>

          {/* Dropdown to Select Course */}
          <Form.Group className="mt-3">
            <Form.Label>Select Course</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                if (e.target.value == "Select Course") {
                  setMasterCourseId(null);
                  setspecializationId(0);
                  setCourseId(null);
                  setSpecialization([]);
                  setMasterCourseId(null);
                } else {
                  setMasterCourseId(e.target.value);
                }
              }}
              id="courseSelect"
            >
              <option value={"Select Course"}>Select Course</option>
              {courses?.map((courses) => (
                <option value={courses.course_master_id}>{courses.name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Select All/Branch Option */}
          <Form.Group className="mt-3">
            <Form.Label>Select Branch</Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value == "Select All") {
                  setspecializationId(0);
                } else {
                  setspecializationId(e.target.value);
                }
              }}
              as="select"
              id="branchSelect"
            >
              <option selected value="Select All">
                Select All
              </option>
              {specialization?.map((specialize) => (
                <option value={specialize.specilization_master_id}>
                  {specialize.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Dropdown to Select Passout Year */}
          <Form.Group className="mt-3">
            <Form.Label>Select Passout Year</Form.Label>
            <Form.Control
              onChange={(e) => setPassoutYear(e.target.value)}
              as="select"
              id="passoutYearSelect"
            >
              <option>Select Year</option>
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear + 1}>{currentYear + 1}</option>
              <option value={currentYear + 2}>{currentYear + 2}</option>
            </Form.Control>
          </Form.Group>

          {/* Textarea for message */}
          <Form.Group className="mt-3">
            {formType === "Push" && (
              <>
                <Form.Label>Push Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) => setMessage(e.target.value)}
                  className=""
                  id="exampleFormControlTextarea1"
                  placeholder="Write here..."
                  style={{ height: "100px", border: "1px solid #cccccc" }}
                />
              </>
            )}
            {formType === "Email" && (
              <>
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label className="form-label">Email Template</label>
                    <div
                      className="custom-ekeditor mb-3"
                      style={{ height: 150 }}
                    >
                      <CkEditorBlog
                        data={message}
                        onChange={handleEditorChange}
                      />
                    </div>
                    <div className="form-text mb-3">
                      This design will show in recieved email and place holders
                      will replace with dynamic content.
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)} variant="danger light">
            Close
          </Button>
          <Button
            onClick={() =>
              sendNotification({
                masterCourseId,
                specializationId,
                passoutyear,
                message,
                formType,
                job,
                // college_connect_id,
              })
            }
            variant="primary"
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default JobView;
